const { contractsValidationsErrorMessages } = require('constants/contractsValidations');
const {
    EURO_SYMBOL,
    INTERVENER_LABEL_HOLDER,
    INTERVENER_LABEL_AUTHORIZED,
    INTERVENER_LABEL_EMPOWERED
} = require('constants/index');

module.exports = {
    title: 'contractsView-contractsView_depositRenewalStep2Title',
    order: 2,
    footer_text: '',
    disableNext: 'depositrenewal_nextButtonDisabled',
    position: 'middle',
    components:
        [
            {
                id_component: 'label-deposit',
            },
            {
                id_component: 'dropdown-accounts',
                label: 'contractsView-contractsView_depositRenewalStep2Account',
                options: [],
                type: 'single',
                target: 'cta_seleccionada',
                targetIntervener: 'intervinientes_list',
                intervenersSpecial: true,
                isIntervenerAvailable: true,
                renderInterveners: 'depositrenewal_shouldBuild',
                isMaxAmountLabelAvailable: false,
                intervenersHeader: 'contractsView-contractsView_depositRenewalStep2IntervenersHeader',
                maxAmountValue: 0,
                required: true,
                errorMessage: contractsValidationsErrorMessages.ERROR_MESSAGE_REQUIRED_DROPDOWN_ACCOUNTS_DEPOSIT_WNM,
                accountChangeExtraFn: 'refreshMainNoNewMoneyDepositHireInformation',
                intervenersHierarchicalTypeSelection: true,
                intervenerOptions: [
                    INTERVENER_LABEL_HOLDER,
                    INTERVENER_LABEL_AUTHORIZED,
                    INTERVENER_LABEL_EMPOWERED
                ],
                showIntervenerControls: true,
                restoreDefaultIntervenersEnabled: true,
                buildCondition: 'depositrenewal_dropdwonAccountShouldBuild',
                renewalDisclaimer: false,
                showDisclaimer: false,
            },
            {
                id_component: 'deposit-renewal-import-input',
                id: 'import-input',
                label: 'contractsView-contractsView_depositWNMStep2Amount',
                subLabel: null,
                type: 'form',
                target: 'monto_traspasar',
                onChangeFn: 'depositrenewal_onChangeAmount',
                importMin: 0,
                importMax: 0,
                errorMessageAmount: 0,
                inputType: 'float',
                amountZeroAlertVisible: false,
                extraValueValidation: true,
                source: 'depositexpired_loadAmountInputAmounts',
                subLabelBuildCondition: 'depositrenewal_shouldInputSubLabelBuild',
                defaultValue: 1,
                order: 1,
                infoMessageText: 'contractsView-contractsView_depositWNMStep2AmountInputInfoText',
                format_type: EURO_SYMBOL,
                buildCondition: 'depositrenewal_shouldBuild',
                errorMessage: 'contractsView-depositExpiredStep2ImportInputErrorMessage',
                accountErrorMessage: 'contractsView-depositWNMStep2ImportInputErrorMessage',
                tooltipText: 'contractsView-contractsView_depositExpiredStep2InputTooltipText',
                handleTooltipHover: 'depositrenewal_handleInputAmountTooltipHover',
                categoryEvent: 'depositrenewal_inputCategoryEvent',
            },
            {
                id_component: 'interest-band'
            },
            {
                id_component: 'disclaimer-personal-info',
                customTaggingCategory: 'depositrenewal_step2DisclaimerCustomTaggingCategory',
                messageKey: 'depositRenewalMsg',
                linkTextKey: 'depositRenewalLinkText',
                linkUrlKey: 'depositRenewalLinkUrl'
            }
        ]
};
