const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    agreementChecked: false,
    agreementIsDirty: false,
    valid: __CONTACT_CENTER__
});

function validateStep(state, action) {
    const { isRevolving } = action.payload;
    if (__CONTACT_CENTER__ && !isRevolving) return state

    return state.merge({
        valid: state.get('agreementChecked'),
        agreementIsDirty: true
    });
}

function hireCardViaTStep4(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_VIA_T_VALIDATE_STEP:
            return validateStep(state, action);

        case actionTypes.HIRE_CARD_VIA_T_NEW_VALIDATE_STEP:
            return validateStep(state, action);
            
        case actionTypes.HIRE_CARD_VIA_T_SET_AGREEMENT_CHECKBOX:
            return state.merge({
                agreementChecked: action.payload.checked,
                agreementIsDirty: false
            });

        default:
            return state;
    }
}

module.exports = hireCardViaTStep4;
