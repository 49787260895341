const brokerHireAlternativeFundsTags = (eventTag, eventAction, brokerType) => (
    {
        startButton: {
            categoriaEvento: `caracteristicas fondo alternativo/${eventTag}`,
            accionEvento: "clickButton", 
            etiquetaEvento: "continuar"
        },
    }
)


module.exports = { brokerHireAlternativeFundsTags }