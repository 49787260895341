//@core
const { getStore } = require('core/storeHelper');

// @vendors
const Immutable = require('immutable');

// @constants
const actionTypes = require('constants/actionTypes');
const {
    BROKER_REIMBURSE_FUND_TOTAL,
    BROKER_PARTIAL_TRANSFER_TYPE_FUNDS_AMOUNT,
    BROKER_INTERNAL_TRANSFER_EXISTING_FUND,
    BROKER_INTERNAL_TRANSFER_NEW_FUND
} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        valid: false,
        amount: 0.00,
        type: BROKER_REIMBURSE_FUND_TOTAL,
        partialType: BROKER_PARTIAL_TRANSFER_TYPE_FUNDS_AMOUNT,
        participations: 0.00000,
        percentage: 0.00,
        originFund: '',
        originFundCurrency: '',
        originFundValid: true,
        destinationFund: '',
        destinationFundValid: true,
        displayMifidDisclaimer: false,
        mifidId: '',
        errorFlags: {
            // @TODO Put amount here
            participations: false,
            percentage: false
        },
        selectedNewFund: '',
        fundType: BROKER_INTERNAL_TRANSFER_EXISTING_FUND,
        newFundSelectedValid: true
    });
}

function isReady(step, exceedLimit) {

    const partialType = step.get('partialType');


    const isValid =  (step.get('type') === BROKER_REIMBURSE_FUND_TOTAL || step.get(partialType) > 0) && !exceedLimit &&
        (step.get('originFund') != '' &&  step.get('destinationFund') != '') && (step.get('originFundValid') );

    if (step.get('fundType')===BROKER_INTERNAL_TRANSFER_NEW_FUND) {
        return step.get('selectedNewFund') !== '' && isValid;
    } else {
       return isValid;
    }
}

function validateStep(step) {
    const partialType = step.get('partialType');

    const inputValue = step.get(partialType);
    let exceedLimit;
    const immMyInvestments = getStore().getState().brokerMyInvestments;
    const originFundId  = step.get('originFund');
    const originFund = immMyInvestments.getIn(['products','funds','stocks'], Immutable.fromJS({})).find(fund => fund.get('id') === originFundId);
    const originFundDetails = getStore().getState().brokerWallets.get('fundsList').find(fund =>
        fund.getIn(['contrato','producto']) === originFund.get('product') &&
        fund.getIn(['contrato','numerodecontrato']) === originFund.get('numerodecontrato')
    );
    const maxTransferPercentage = immMyInvestments.get('maxFundsPartialReimbursementPercentage');
    if (partialType === 'percentage') {
        exceedLimit = inputValue > maxTransferPercentage;
    }
    else if (partialType === 'participations') {
        const originParticipations = originFundDetails.get('disponible');
        exceedLimit = inputValue > originParticipations * (maxTransferPercentage / 100);
    }
    else if (partialType === 'amount') {
        exceedLimit = inputValue > originFund.get('totalValue') * (maxTransferPercentage / 100);
    }

    return step.merge({
        valid: isReady(step, exceedLimit),
        originFundValid: step.get('originFund') != '',
        destinationFundValid: step.get('destinationFund') != '',
        errorFlags: {
            [partialType]: (step.get('type') !== BROKER_REIMBURSE_FUND_TOTAL && (inputValue <= 0 || exceedLimit))
        }
    });
}

function validateSelectedFunds(immState) {
    return immState.merge({
        originFundValid: (immState.get('originFundValid') && immState.get('originFund') != '' ),
        destinationFundValid: immState.get('destinationFundValid') && ( immState.get('destinationFund') != '' || immState.get('fundType')===BROKER_INTERNAL_TRANSFER_NEW_FUND ),
        newFundSelectedValid: !!immState.get('selectedNewFund') || immState.get('fundType')===BROKER_INTERNAL_TRANSFER_EXISTING_FUND
    });
}

function brokerInternalTransferFundsStep1(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_VALIDATE:
            return validateSelectedFunds(state);
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SUCCESS:
            return state.merge({
                originFund: action.payload.originFund,
                originFundCurrency: action.payload.originFundCurrency,
                originFundValid: true,
                destinationFund: action.payload.destinationFund,
                destinationFundValid: true,
                displayMifidDisclaimer: action.payload.displayMifidDisclaimer,
                mifidId: action.payload.mifidId,
                contributionMinimumAmount: action.payload.contributionMinimumAmount,
                contributionCurrency: action.payload.contributionCurrency
            });
        case actionTypes.BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_INPUT_VALUE:
            return state.merge({
                [action.payload.partialType]: action.payload.amount,
                errorFlags: {
                    [action.payload.partialType]: false
                }
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_TYPE:
            return state.merge({
                type: action.payload.type,
                errorFlags: {
                    amount: false,
                    participations: false,
                    percentage: false
                }
            });
        case actionTypes.BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_TRANSFER_TYPE:
            return state.merge({
                partialType: action.payload.type,
                errorFlags: {
                    amount: false,
                    participations: false,
                    percentage: false
                }
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_ORIGIN_FUND_ID:
            return state.merge({
                originFund: action.payload.fundId,
                originFundValid: !action.payload.linkedToRobo,
                originHasContribution: action.payload.hasContribution,
                originContributionDetails: action.payload.originContributionDetails
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_DESTINATION_FUND_ID:
            return state.merge({
                destinationFund: action.payload.fundId,
                destinationFundValid: !action.payload.linkedToRobo,
                destinationHasContribution: action.payload.hasContribution,
                destinationContributionDetails: action.payload.destinationContributionDetails
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_NEW_FUND_SELECTED:
            return state.merge({
                selectedNewFund: action.payload.product.customInstitutionSecurityID,
                selectedNewFundISIN: action.payload.product.isin,
                selectedNewFundName: action.payload.product.label
            })
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_FUND_TYPE:
            return state.merge({
                fundType: action.payload.type,
                destinationFund: '',
                selectedNewFund: '',
            });
        default:
            return state;
    }
}

module.exports = brokerInternalTransferFundsStep1;
