const preApprovedLoan = require('./preApprovedLoan/preApprovedLoan');
const eAccountLoan = require('./eAccountLoan/eAccountLoan');
const personalLoan = require('./personalLoan/personalLoan');
const juniorAccount = require('./juniorAccount/juniorAccount');
const checkingAccount = require('./checkingAccount/checkingAccount');
const checkingAccountNoES = require('./checkingAccountNoES/checkingAccountNoES');
const checkingAccountLegalWindow = require('./checkingAccountLegalWindow/checkingAccountLegalWindow');
const payrollAccount = require('./payrollAccount/payrollAccount');
const payrollAccountNoES = require('./payrollAccountNoES/payrollAccountNoES');
const payrollAccountLegalWindow = require('./payrollAccountLegalWindow/payrollAccountLegalWindow');
const depositThirteenMonths = require('./depositThirteenMonths/depositThirteenMonths');
const depositEighteenMonths = require('./depositEighteenMonths/depositEighteenMonths');
const depositThirteenMonthsExcepciones = require('./depositThirteenMonthsExcepciones/depositThirteenMonthsExcepciones');
const depositThirteenMonthsLeakRetention = require('./depositThirteenMonthsLeakRetention/depositThirteenMonthsLeakRetention');
const depositThirteenMonthsDueDates = require('./depositThirteenMonthsDueDates/depositThirteenMonthsDueDates');
const depositThirteenMonthsLinkedMaturities = require('./depositThirteenMonthsLinkedMaturities/depositThirteenMonthsLinkedMaturities');
const depositThirteenMonthsRenovacionExcepciones = require('./depositThirteenMonthsRenovacionExcepciones/depositThirteenMonthsRenovacionExcepciones');
const depositEighteenMonthsRetention = require('./depositEighteenMonthsRetention/depositEighteenMonthsRetention');
const depositTwentyFourMonthsRetention = require('./depositTwentyFourMonthsRetention/depositTwentyFourMonhtsRetention');
const duoCombinedDeposit = require('./duoCombinedDeposit/duoCombinedDeposit');
const combinedDeposit = require('./combinedDeposit/combinedDeposit');
const upgradeToPayrollAccount = require('./upgradeToPayrollAccount/upgradeToPayrollAccount');
const upgradeToPayrollAccountLegalWindow = require('./upgradeToPayrollAccountLegalWindow/upgradeToPayrollAccountLegalWindow');
const upgradeToPayrollAccountLegalWindowCC = require('./upgradeToPayrollAccountLegalWindowCC/upgradeToPayrollAccountLegalWindowCC');
const depositNM = require('./depositNM/depositNM');
const depositWNM = require('./depositWNM/depositWNM');
const depositTrasfer = require('./depositTransfer/depositTransfer');
const depositRenewal = require('./depositRenewal/depositRenewal');
import depositExpired from './depositExpired/depositExpired'

const { JOIN_HOLDERS, DEPOSITS_INTEREST_BAND_COUNTRIES } = require('constants/flagBasedFeatures');
const { COUNTRIES_CODES: { ES, NL, PT, DE } } = require('constants/countries');
const notAllowedTAECountries = [NL, PT]
const notAllowedTINCountriesRenewal = []
const notAllowedTAECountriesRenewal = [NL, PT, DE]
const notAllowedTINCountriesTransfer = []
const notAllowedTAECountriesTransfer = [NL, PT, DE]
const notAllowedTINCountriesEAccountLoan = []
const notAllowedTAECountriesEAccountLoan = [NL, PT, DE]

function loadContract(contract_id, flagBasedFeatures, isCreditCard = false,  originCountry = '') {
    switch (contract_id) {
        case 'pre-approved-loan':
            return preApprovedLoan.contract

        case 'e-account-loan':
            return eAccountLoan.getContract(!notAllowedTAECountriesEAccountLoan.includes(originCountry),!notAllowedTINCountriesEAccountLoan.includes(originCountry))

        case 'personal-loan':
            return personalLoan.contract

        case 'junior-account':
            return juniorAccount.contract

        case 'checking-account':
            if (flagBasedFeatures.get(JOIN_HOLDERS)){
                if (!__CONTACT_CENTER__){
                    return checkingAccountLegalWindow.getContract(isCreditCard)
                }else{
                    return checkingAccount.getContract(isCreditCard)
                }
            } else {
                return checkingAccountNoES.getContract();
            }

        case 'payroll-account':
            if (flagBasedFeatures.get(JOIN_HOLDERS)){
                if (!__CONTACT_CENTER__){
                    return payrollAccountLegalWindow.getContract(isCreditCard)
                }else{
                    return payrollAccount.getContract(isCreditCard)
                }
            } else {
                return payrollAccountNoES.contract;
            }

        case 'deposit-thirteen-months':
            return depositThirteenMonths.contract

        case 'deposit-eighteen-months':
            return depositEighteenMonths.contract

        case 'deposit-thirteen-months-excepciones':
            return depositThirteenMonthsExcepciones.contract
        case 'deposit-thirteen-months-leak-retention':
            return depositThirteenMonthsLeakRetention.contract

        case 'deposit-thirteen-months-due-dates':
            return depositThirteenMonthsDueDates.contract

        case 'deposit-thirteen-months-linked-maturities':
            return depositThirteenMonthsLinkedMaturities.contract

        case 'deposit-thirteen-months-renovacion-excepciones':
            return depositThirteenMonthsRenovacionExcepciones.contract

        case 'deposit-eighteen-months-retention':
            return depositEighteenMonthsRetention.contract

        case 'deposit-twentyfour-months-retention':
            return depositTwentyFourMonthsRetention.contract

        case 'duo-combined-deposit':
            return duoCombinedDeposit.contract

        case 'upgrade-to-payroll-account':
            if (!__CONTACT_CENTER__ && originCountry === ES){
                return upgradeToPayrollAccountLegalWindow.contract
            }
            else if(__CONTACT_CENTER__ && originCountry === ES) {
                return upgradeToPayrollAccountLegalWindowCC.contract
            }
            else {
                return upgradeToPayrollAccount.contract
            }

        case 'combined-deposit':
            return combinedDeposit.contract

        case 'depositnm':
            return depositNM.getContract(!notAllowedTAECountries.includes(originCountry))
            
        case 'deposit':
            return depositWNM.getContract(!notAllowedTAECountries.includes(originCountry))
        case 'deposittransfer':
            return depositTrasfer.getContract(!notAllowedTAECountriesTransfer.includes(originCountry), !notAllowedTINCountriesTransfer.includes(originCountry))
        case 'depositrenewal':
            return depositRenewal.getContract(!notAllowedTAECountriesRenewal.includes(originCountry),!notAllowedTINCountriesRenewal.includes(originCountry))
        case 'depositexpired':
            const showGrossInterest = flagBasedFeatures.get(DEPOSITS_INTEREST_BAND_COUNTRIES)
            return depositExpired.getContract(!notAllowedTAECountriesRenewal.includes(originCountry),!notAllowedTINCountriesRenewal.includes(originCountry), showGrossInterest)
    }
}

module.exports = {
    loadContract
};
