const wizardComponentsHelper = require('../../utilities/wizardComponenstHelper');
const inputs = require('../../fioc/inputs');
const inputsFioc = wizardComponentsHelper.replacePrefix(inputs.fioc);
const { CHECKING_ACCOUNT_2_GDPR_PID } = require('constants/gdprPlacementIds');
const {
    CHECKING_ACCOUNT_SUBTYPE,
    CHECKING_ACCOUNT_STANDARD,
    CHECKING_ACCOUNT_TYPE
} = require('constants/index');
const {
    CHECKING_ACCOUNT,
    messages: { CHECKING_ACCOUNT_AND_GDPR }
} = require('constants/operationTypes');
const { CHECKING_ACCOUNT_PRODUCT_ALIAS, BANKING_CHECKING_ACCOUNT_PRECONTRACTUAL, BANKING_CHECKING_ACCOUNT_CONTRACTUAL  } = require('constants/hiringProductInformation');
// @ helpers
const { formatText } = require('core/i18n').i18n;

const personalData1 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_1_');
const contactData1 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_1_');
const generalData1 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_1_');
const anotherAccount1 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_1_');
const inputsFioc1 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_1_');

const personalData2 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_2_');
const contactData2 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_2_');
const generalData2 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_2_');
const anotherAccount2 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_2_');
const inputsFioc2 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_2_');

const personalData3 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_3_');
const contactData3 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_3_');
const generalData3 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_3_');
const anotherAccount3 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_3_');
const inputsFioc3 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_3_');

const personalData4 = wizardComponentsHelper.replacePrefix(inputs.personalData, 'holder_4_');
const contactData4 = wizardComponentsHelper.replacePrefix(inputs.contactData, 'holder_4_');
const generalData4 = wizardComponentsHelper.replacePrefix(inputs.generalData, 'holder_4_');
const anotherAccount4 = wizardComponentsHelper.replacePrefix(inputs.anotherAccount, 'holder_4_');
const inputsFioc4 = wizardComponentsHelper.replacePrefix(inputs.fioc, 'holder_4_');

const step1 = require('./step1');
const step2 = require('./step2');
const step3 = require('./step3');
const step4 = require('./step4');
const successStep = require('./successStep');
const customErrors = require('./customErrors');


const getContract = ()=>({
    title: 'contractsView-checkingAccountContractTitle',
    service: '....',
    last_step: __CONTACT_CENTER__ ? 4 : 5,
    define_steps: __CONTACT_CENTER__ ? 3 : 4,
    termsAndCondsKey: CHECKING_ACCOUNT_PRODUCT_ALIAS,
    code_op: CHECKING_ACCOUNT,
    showCurrentUser: true,
    successStepType: 'dynamic',
    SignParamsFn: 'checkingPayrollAccount_SignParams',
    hireParamsFn: 'checkingPayrollAccount_HireParams',
    onCancelURL: '/myprofile/accounts',
    maxTributationCountries: 3,
    biggerLegalTexts: true,
    alternativePositionedCheckboxes: true,
    showHelpBar: true,
    hasPolling: true,
    errorTitle: 'contractsView-errorTitle',
    errorMessage: 'contractsView-errorMessage',
    errorBackButtonLabel: 'contractsView-errorBackButtonLabelAccountsAcquittance',
    errorBackButtonLink: '/myprofile/accounts',
    hasPrecontractualLink: true,
    preContractualAlias: BANKING_CHECKING_ACCOUNT_PRECONTRACTUAL,
    contractualAlias: BANKING_CHECKING_ACCOUNT_CONTRACTUAL,
    type: CHECKING_ACCOUNT_TYPE,
    subtype: CHECKING_ACCOUNT_SUBTYPE,
    operativeName:'operatives-checkingAccount',
    standard: CHECKING_ACCOUNT_STANDARD,
    product: CHECKING_ACCOUNT_PRODUCT_ALIAS,
    ...(__CONTACT_CENTER__ ? {} : 
        {
            tealiumStepsLabels: !__CONTACT_CENTER__ ? [
                'precontractual',
                'dataProtection',
                'knowledgeForm',
                'cardPin',
                'confirmation'
            ] : [],
            tealiumName: 'contractingAccounts',
            customTagging: 'deposittransfer_customTagging',
        }        
    ),
    warningContractMessageDynamic: 'checkingPayrollAccount_WarningContractMessage',
    steps: (__CONTACT_CENTER__ ? 
        [
            step1(),
            step3,
            step4,
        ] :
        [
            step1(),
            step2,
            step3,
            step4,
        ]    
    ),
    breadcrumb: [
        {
            text: 'breadcrumb-accounts',
            url: '/myprofile/accounts'
        },
        {
            text: 'contractsView-checkingAccount'
        }
    ],
    state: {
        card_details_title: 'contractsView-cardDetailsTitle',
        card_details: [
            //Forms Values
                {
                    id: 'n_steps',
                    label: '',
                    value: __CONTACT_CENTER__ ? 4 : 5,
                    type: 'array',
                    format_type: '',
                    order: 10,
                    visible: []
                },


            
            {
                id: 'intervinientes_list',
                label: 'contractsView-interveners',
                value: null,
                type: 'interveners',
                format_type: '',
                order: 1,
                confirmation_step: {
                    columns: 4,
                    order: 1,
                    row: 1
                },
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                type: 'separator',
                order: 2,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                id: 'holder-validation-error',
                order: 10,
                visible: []
            },
            {
                id: 'holders-personal-info-disclaimer',
                order: 10,
                visible: [],
                value: false,
                required: false,
                isErrorVisible: false
            },
            {
                id: 'step2-validation-error',
                order: 10,
                visible: []
            },
            {
                id: 'nationality',
                label: 'contractsView-nationality',
                value: null,
                type: 'nacionality',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                id: 'countryOfResidence',
                label: 'contractsView-countryOfResidence',
                value: null,
                type: 'countryOfResidence',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                id: 'correspondenceAddress',
                label: 'contractsView-correspondenceAddress',
                value: null,
                type: 'address',
                format_type: '',
                order: 3,
                visible: [
                    {
                        step: 1,
                        order: 1
                    },
                    {
                        step: 2,
                        order: 1
                    },
                    {
                        step: 3,
                        order: 1
                    },
                    {
                        step: 4,
                        order: 1
                    },
                    {
                        step: 5,
                        order: 1
                    },
                    {
                        step: 6,
                        order: 1
                    },
                ]
            },
            {
                id: 'resident_country',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'excludes_id',
                label: '',
                value: [],
                type: 'single',
                format_type: '',
                order: 0,
                visible: []
            },
            {
                id: 'card_image',
                value: ''
            },
            {
                id: 'card_image_no_es',
                value: {
                    DE: 'B.506.013.011',
                    NL: 'B.506.019.011',
                    PT: 'B.506.015.011',
                },
                valueStandardOff: {
                    DE: 'B.506.013.006',
                    NL: 'B.506.019.006',
                    PT: 'B.506.015.006',
                },
            },
            {
                id: 'card_pin_change',
                isEnabled: false,
                isValid: false,
                showError: false,
                showErrorInSummary: false
            },
            {
                id: 'passport_or_future_mode',
                value: false,
            },
            {
                id: 'tramitation_expedient',
                addDocumentationError: false,
                details: {},
                showAddDocumentation: false,
            },
            {
                id: 'show_fioc',
                label: '',
                value: false,
                type: 'fioc-test',
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'show_fioc_alert',
                value: false,
            },
            {
                id_component: 'holders',
                id: 'holders',
                value: [],
                visible: []
            },
            {
                id: 'resident_country',
                openModal: false,
                value: false,
                format_type: '',
                order: 10,
                visible: []
            },
            {
                id: 'fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_category',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_autocomplete_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_1_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_2_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_3_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            {
                id: 'holder_4_fioc_profession_search_option',
                value: '',
                isErrorVisible: false,
                required: true
            },
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData1, 'isCheckingAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount1),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc1),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData2, 'isCheckingAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount2),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc2),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData3, 'isCheckingAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount3),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc3),

            ...wizardComponentsHelper.returnObjectsFromArrayComponents(personalData4, 'isCheckingAccount'),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(contactData4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(generalData4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(anotherAccount4),
            ...wizardComponentsHelper.returnObjectsFromArrayComponents(inputsFioc4),
        ],
        breadcrumb: [
            {
                text: 'breadcrumb-accounts',
                url: '/myprofile/accounts'
            },
            {
                text: 'contractsView-checkingAccount'
            }
        ],
        confirmationCheckboxes: {
            areValid: false,
            list: [
                {
                    id: 'service_contract_checkbox',
                    idName: 'serviceContract',
                    value: false,
                    isRequired: true,
                    errorState: false,
                    errorMessage: 'contractsView-serviceContractErrorMessage',
                    labels: [
                        {
                            text: 'contractsView-ccontractsView_heckingAccountLegalWindowConfirmationText'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowPreviousInformationLabel',
                            id: 'btn_ContractsView_precontractual',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowPreviousInformationUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowDataProtectionLabel',
                            id: 'btn_ContractsView_product_1',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowDataProtectionUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowNaturalPersonsLabel',
                            id: 'btn_ContractsView_product_1',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowNaturalPersonsUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowPrecontractualInformationOnlyLabel',
                            id: 'btn_ContractsView_product_2',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowPrecontractualInformationUrl'

                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowCommisionInformationDocumentLabel',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowCommisionInformationDocumentUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowDebitCardLabel',
                            id: 'btn_ContractsView_product_3',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowDebitCardUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowMifidLabel',
                            id: 'btn_ContractsView_product_4',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowMifidUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowMaximumRatesLabel',
                            id: 'btn_ContractsView_product_5',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowMaximumRatesUrl'
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAnd',
                        },
                        {
                            text: 'contractsView-contractsView_checkingAccountLegalWindowAccidentInsuranceLabel',
                            id: 'btn_ContractsView_product_5',
                            href: 'contractsView-contractsView_checkingAccountLegalWindowAccidentInsuranceUrl'
                        },
                    ]
                }
            ]
        },
        preContractDocuments : {
            preContractPdfFileName: "documentosCuentaCorrienteOpen",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_checkingAccountPdfTitle')
        },
        preContractConditionsDocuments: {
            preContractPdfFileName: "documentosPreContractualesCuentaCorrienteOpen",
            preContractPdfTitle: formatText('preContractDocument-preContractDocument_checkingAccountPdfTitle'),
        },
        precontractualConfirmationLabel: [
            {
                text: 'contractsView-contractsView_checkingAccountLegalWindowText'
            },
            {
                text: 'contractsView-contractsView_checkingAccountLegalWindowContractServiceLabel',
                href: 'contractsView-contractsView_checkingAccountLegalWindowContractServiceUrl'
            },
            {
                text: 'contractsView-contractsView_checkingAccountLegalWindowMifidLabel',
                href: 'contractsView-contractsView_checkingAccountLegalWindowMifidUrl'
            },
            {
                text: 'contractsView-contractsView_checkingAccountLegalWindowRateValuesLabel',
                href: 'contractsView-contractsView_checkingAccountLegalWindowRateValuesUrl'

            }
        ]
    },
    successStep,
    customErrors
});


module.exports = {
    getContract
};
