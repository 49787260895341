const { capitalizeFirstLetter } = require('utilities/stringHelper');

export const brokerInvestementProductsTableTags = (eventTag, eventAction, brokerType) => (
    {
        brokerProductsTableLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: eventTag,
            etiquetaEvento: 'link'
        },
        optionsMenu: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'optionsMenu',
            etiquetaEvento: eventTag
        },
        searchLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'myPosition',
            etiquetaEvento: `${brokerType}Search`
        },
        seeAllLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'myPosition',
            etiquetaEvento: 'seeAll'
        },
        productLink: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `myPosition - see${eventAction && capitalizeFirstLetter(eventAction)}`,
            etiquetaEvento: `${eventTag}`
        },
        contributeButton: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `myPosition - contribute${eventAction && capitalizeFirstLetter(eventAction)}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonBuy: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `myPosition - buy${eventAction}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonSell: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `myPosition - sell${eventAction && capitalizeFirstLetter(eventAction.finalType)}${eventAction && eventAction.extraTag}`,
            etiquetaEvento: `${eventTag}`
        },
        actionButtonMenu: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: `myPosition - contributePlans - ${eventAction}`,
            etiquetaEvento: `${eventTag}`
        },
        productsTableAccordion: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'chevron',
            etiquetaEvento: eventTag
        },
        productsTableAccordionNewTag: {
            categoriaEvento: brokerType,
            accionEvento: 'clickDropdown',
            etiquetaEvento: eventAction,
        },
        productTableTooltip: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'price',
            etiquetaEvento: 'tooltip'
        },
        productStatusActive: {
            categoriaEvento: `${brokerType}HomeInteraction`,
            accionEvento: 'productStatus',
            etiquetaEvento: 'view',
            productStatus: eventTag
        }
    }
)