// @ vendor
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
// @ commons
const moment = require('moment');
const {
    getCardType,
    uniqContractFilter,
    groupByCurrencyAndCalculateTotally,
    mergeOrderAndId,
    isMetallicCard,
    isMinicard,
    isOpenDebit,
    isCharity,
    isOpenYoung,
    isDiamondCard,
    isPremiumCard,
    isRevolvingCard,
    isOpenCreditCard,
    getRevolvingPack,
    isViaT,
    isVirtualCredit,
    isLegalCard,
    isAtHomeAbroad,
    isR42,
    isR42MasterCard,
    isR42BetaalPasCard,
    isVirtualDebit,
    getTypeCardForTaggin
} = require('utilities/APIParsingHelper');
const { hash } = require('utilities/hash');
const { getTravelPlusState } = require('utilities/cardR42Helper');

// @ constants
const actionTypes = require('constants/actionTypes');
const {
    EURO_TEXT,
    TYPE_CONTRACT_MASTERCARD,
    CARD_CREDIT,
    CARD_DEBIT,
    CARD_ECARD,
    CREDIT_CARD_SUBTYPE_VIA_T,
    CREDIT_CARD_TYPE_VIA_T,
    FILTERS_VALID,
    API_ENTERPRISE_NUMBER,
    API_CENTER
} = require('constants/index');
const {
    R42_CARD_OFF,
    R42_CARD_ON,
    R42_CARD_PENDING_DEACTIVATION,
    R42_CARD_IS_NOT_ACTIVED,
    R42_CARD_HAS_NEVER_BEEN_ACTIVATED
} = require('constants/cardR42States');
const {
    HORMIGUERO_CARD_PRODUCT_ALIAS,
    NERUDA_CARD_PRODUCT_ALIAS,
    DIAMOND_PACK_PRODUCT_ALIAS,
    OPEN_CREDIT_CARD_PRODUCT_ALIAS,
    PREMIUM_PACK_PRODUCT_ALIAS,
    VANITY_CARD_PRODUCT_ALIAS,
    VIA_T_CARD_PRODUCT_ALIAS,
    VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS
} = require('constants/hiringProductInformation');

const configurableBIN = [
    '451126', '405488', '459269', '462759', '451127', '548913', '535575',
    '510107', '515452', '547472', '530130', '401014', '420852', '425555',
    '417641', '526184', '459269', '526212', '538161', '539222', '553617',
    // DA OPEN DEBIT
    '522406',
    // NL OPEN DEBIT
    '522403',
    // PT OPEN DEBIT
    '532767',
    // NL MAESTRO
    '679932',
    // DE Metallic
    '535786',
    // NL Metallic
    '535789',
    // PT Metallic
    '535787'
];

const passportBIN = [
    // DA OPEN DEBIT
    '522406',
    // NL OPEN DEBIT
    '522403',
    // PT OPEN DEBIT
    '532767',
    // NL MAESTRO
    '679932'
];

const initialState = Immutable.fromJS({
    isFetching: false,
    byId: {},
    byOrder: [],
    byTypeSubtype: {},
    hasDiamondPack: false,
    hasPremiumPack: false,
    hasR42Benefits: false,
    credit: {
        byOrder: [],
        availableBalance: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        availableBalanceForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawnForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawn: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        excludedCards: 0,
        lavanda: {
            cards: null,
            error: null,
            isFetching: false,
            success: false,
        },
    },
    debit: {
        byOrder: [],
        availableBalance: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        availableBalanceForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawnForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawn: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ]
    },
    ecard: {
        byOrder: [],
        availableBalance: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        availableBalanceForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawnForPG: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        balanceDrawn: [
            {
                amount: 0,
                currency: EURO_TEXT
            }
        ],
        excludedCards: 0
    }
});

function getLogoBrand(typeNumber, subtypeNumber) {
    /*
     * This is a business rule:
     *    for product 506 brand is Mastercard.
     *    for product 500 and 501 brand is VISA
     *
     * Note: this is not harcoded.
     **/
    if (typeNumber === TYPE_CONTRACT_MASTERCARD) {
        return 'master';
    } else if (typeNumber === CREDIT_CARD_TYPE_VIA_T && subtypeNumber === CREDIT_CARD_SUBTYPE_VIA_T) {
        return 'viat';
    } else {
        return 'visa';
    }
}

function checkBeneficiaries(list) {
    //This should return true if there are beneficiaries that are not titular.
    return !!list.filter(item => item.interventionType !== 'TITULAR').length;
}

function howManyExcludedCards(cards) {
    return cards.filter(card => !card.filters.valid && card.visibleinPG).length;
}

function createInterventionList(cards, card) {
    const contractNumber = get(card, 'contrato.numerodecontrato');
    const currentInterventionType = trim(get(card, 'descIntervenciones.nomTipInterv'));
    const currentCardNumber = trim(card.panmdp);

    const cardsInTheSameContract = [];

    // (Andres)
    // (1) if the current cars is a titular, we have to show al titulares and beneficairies
    // (2) if the current card is a beneficary, we only have to show the titulares
    // (3) We have to show the current card in the beneficiaries list
    cards.forEach(item => {
        const itemContractNumber = get(item, 'contrato.numerodecontrato');
        const itemInterventionCard = trim(get(item, 'descIntervenciones.nomTipInterv'));
        const itemNumber = trim(item.panmdp);
        if (contractNumber === itemContractNumber) {
            if (
                currentInterventionType === 'TITULAR' ||
                itemInterventionCard === 'TITULAR' ||
                currentCardNumber === itemNumber
            ) {
                cardsInTheSameContract.push({
                    name: trim(item.titunom),
                    pan: itemNumber,
                    interventionType: itemInterventionCard,
                    cardId: hash(item.panmdp)
                });
            }
        }
    });

    return cardsInTheSameContract;
}

function isContractOwnerOfAdditionalCard(cards, card) {
    const contractNumber = get(card, 'contrato.numerodecontrato');
    const currentInterventionType = trim(get(card, 'descIntervenciones.nomTipInterv')).toUpperCase();

    if (currentInterventionType !== 'MIEMBRO ADICIONAL') {
        return false;
    }

    return cards.some(item => {
        const itemContractNumber = get(item, 'contrato.numerodecontrato');
        const itemInterventionCard = trim(get(item, 'descIntervenciones.nomTipInterv')).toUpperCase();
        return (contractNumber === itemContractNumber && itemInterventionCard === 'TITULAR');
    });
}

function checkIsOperative(statusCode, active) {
    return statusCode === 'EV' && active;
}

function getContractName(card) {
    const {
        isHormigueroCard,
        isNerudaCard,
        isVanityCard,
        isVirtualCredit,
        isDiamond,
        isPremium,
        isOpenCredit,
        isViaT
    } = card;

    const contractName = isVirtualCredit
        ? "virtual-credit"
        : isDiamond
            ? "diamond-pack"
            : isPremium
                ? "premium-pack"
                : isOpenCredit || isNerudaCard || isVanityCard || isHormigueroCard
                    ? "visa-open"
                    : isViaT
                        ? "via-t"
                        : "";
    return contractName;
}

function getStatusFromCode(statusCode) {
    switch (statusCode) {
        case 'EV':
            return 'EN VIGOR';
        case 'BJ':
            return 'BAJA';
        case 'BC':
            return 'CANCELADA';
        case 'BL':
            return 'BLOQUEADO';
        default:
            return '';
    }
}

function checkIsNeruda(card) {
    const type = get(card, 'contrato.producto', '');
    const subtype = get(card, 'criterios.c1.subtipoproducto', '');

    return type === '500' && subtype === '802';
}

function checkIsVanity(card) {
    const type = get(card, 'contrato.producto', '');
    const subtype = get(card, 'criterios.c1.subtipoproducto', '');

    return type === '500' && subtype === '805';
}

function checkIsHormiguero(card) {
    const type = get(card, 'contrato.producto', '');
    const subtype = get(card, 'criterios.c1.subtipoproducto', '');

    return type === '500' && subtype === '804';
}

function checkRevolvingProcess(card) {
    const {
        isHormigueroCard,
        isNerudaCard,
        isVanityCard,
        isVirtualCredit,
        isDiamond,
        isPremium,
        isOpenCredit,
        isViaT,
        type
    } = card;

    if ((isNerudaCard ||
        isVanityCard ||
        isHormigueroCard ||
        isVirtualCredit ||
        isDiamond ||
        isPremium ||
        isOpenCredit ||
        isViaT) &&
        type === 'credit') {
        return true;
    }

    return false
}

function getAliasCard(card) {
    const {
        isHormigueroCard,
        isNerudaCard,
        isVanityCard,
        isVirtualCredit,
        isDiamond,
        isPremium,
        isOpenCredit,
        isViaT
    } = card;

    if (isNerudaCard) return NERUDA_CARD_PRODUCT_ALIAS
    else if (isVanityCard) return VANITY_CARD_PRODUCT_ALIAS
    else if (isHormigueroCard) return HORMIGUERO_CARD_PRODUCT_ALIAS
    else if (isVirtualCredit) return VIRTUAL_CREDIT_CARD_PRODUCT_ALIAS
    else if (isDiamond) return DIAMOND_PACK_PRODUCT_ALIAS
    else if (isPremium) return PREMIUM_PACK_PRODUCT_ALIAS
    else if (isOpenCredit) return OPEN_CREDIT_CARD_PRODUCT_ALIAS
    else if (isViaT) return VIA_T_CARD_PRODUCT_ALIAS
    else return ''
}

function buildAssociatedAccount(card) {
    const accountNumber = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.numeroCuenta', '');
    const controlDigit = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.digitoControl', '');
    if (!!accountNumber && !!controlDigit) {
        const isNerudaCard = checkIsNeruda(card);
        const isVanityCard = checkIsVanity(card);
        const isHormigueroCard = checkIsHormiguero(card);
        if (isNerudaCard || isVanityCard || isHormigueroCard) {
            const apiEnterpriseNumber = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.centro.empresa', '');
            const apiCenterNumber = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.centro.centro', '');
            return `${apiEnterpriseNumber} ${apiCenterNumber} ${controlDigit} ${accountNumber}`;
        } else {
            return `${API_ENTERPRISE_NUMBER} ${API_CENTER} ${controlDigit} ${accountNumber}`;
        }
    }
    return '';
}

function buildPerson(card) {
    const personCode = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.persona.codigodepersona', '');
    const personType = get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.persona.tipodepersona', '');

    return {
        personCode,
        personType
    };
}

// The returned value of this function is just for informative purpose
function buildFullContractNumber(card) {
    const contractNumber = get(card, 'contrato.numerodecontrato');
    const product = get(card, 'contrato.producto', '');
    if (!!contractNumber && !!product) {
        return `${API_ENTERPRISE_NUMBER} ${API_CENTER} ${product} ${contractNumber}`;
    }
    return '';
}

// Esta función retorna la suma de los saldos dispuestos de los intervinientes del contrato de la tarjeta (titular mas adicionales)
function buildTotalContractBalanceDrawnAmount(cards, contrato) {
    return cards.reduce((total, card) => {
        const cardContract = `${get(card, 'contrato.producto')}${get(
            card,
            'contrato.numerodecontrato'
        )}`;
        const contract = `${contrato.producto}${contrato.numerodecontrato}`;
        if (cardContract === contract) {
            total += Math.abs(get(card, 'saldoDispuesto.importe', 0) || 0);
        }
        return total;
    }, 0);
}

function getContractByContractNumber(contractList, contractNumber) {
    return contractList.find(contract => contract.numeroContrato === contractNumber);
}

function isActive(card) {
    const productSubtypeId = get(card, 'criterios.c1.subtipoproducto', '');
    const productTypeId = get(card, 'contrato.producto', '');
    let active = false;

    if (isViaT(productTypeId, productSubtypeId) || get(card, 'consultaTarjetaPosGlobal.flasTarjetaPnte') === 'N') {
        active = true;
    }

    return active;
}

function updateCardsList(state, cards) {
    const knownStatus = [
        'EV', // EN VIGOR
        'BJ', // BAJA
        'BC', // CANCELADA
        'BL' // BLOQUEADA
    ];
    let hasDiamondPack = false;
    let hasPremiumPack = false;
    let hasR42Benefits = false;
    let revolvingPack;
    let byId = {};
    let byOrder = [];
    let byTypeSubtype = {};
    let creditByOrder = [];
    let debitByOrder = [];
    let ecardByOrder = [];
    let cardNumbersTravelPlus = [];

    cards.forEach(card => {
        const cardId = hash(card.panmdp);
        const type = getCardType(card);
        const active = isActive(card);

        const product = {
            type: get(card, 'contrato.producto', ''),
            subtype: get(card, 'criterios.c1.subtipoproducto', ''),
            standard: get(card, 'criterios.c1.estand', '')
        };

        const productSubtypeId = get(card, 'criterios.c1.subtipoproducto', '');
        const productTypeId = get(card, 'contrato.producto', '');

        const isDiamond = isDiamondCard(product);
        if (isDiamond) {
            hasDiamondPack = true;
        }

        const isPremium = isPremiumCard(product);
        if (isPremium) {
            hasPremiumPack = true;
        }

        const isR42Card = isR42(productTypeId, productSubtypeId);
        const travelPlusState = getTravelPlusState(product.type, product.subtype, product.standard);
        if (isR42Card && !isMetallicCard(card) && !checkIsNeruda(card) && !checkIsVanity(card) && !checkIsHormiguero(card) && travelPlusState !== R42_CARD_OFF) {
            hasR42Benefits = true;
        }

        const isRevolving = isRevolvingCard(product);
        if (isRevolving) {
            revolvingPack = getRevolvingPack(product);
        }

        const isOpenCredit = isOpenCreditCard(productTypeId, productSubtypeId);

        const isMetallic = isMetallicCard(card);

        let statusCode = card.consultaTarjetaPosGlobal.situacionTarjeta;

        // Some openyoung cards comes with 'NR' state ('NO RENOVAR'), that is equal to 'EV' state.
        if (statusCode === 'NR') {
            statusCode = 'EV';
            card.consultaTarjetaPosGlobal.descSituacionTarj = 'EN VIGOR';
        }
        const interventionType = trim(get(card, 'descIntervenciones.nomTipInterv'));

        //TODO (DLV) problem with OoB Numbers cant assign properly get a NaN - workarounded
        if (
            typeof byTypeSubtype[productTypeId + productSubtypeId + product.standard] == undefined
        ) {
            byTypeSubtype[productTypeId + productSubtypeId + product.standard] = 1;
        } else {
            byTypeSubtype[productTypeId + productSubtypeId + product.standard]++;
        }

        let newCard = {
            cardId,
            type,
            active,
            product,
            cancelled: statusCode === 'BC',
            control: !card.bloqueada,
            visibleinPG: active && statusCode === 'EV',
            visibleinCards: knownStatus.includes(statusCode),
            number: card.panmdp,
            bin: card.panmdp.split(' ').join('').substring(0, 6),
            brand: getLogoBrand(productTypeId, productSubtypeId),
            situationDate: moment(card.fecsittarj),
            statusCode,
            status:
                trim(card.consultaTarjetaPosGlobal.descSituacionTarj) ||
                getStatusFromCode(statusCode),
            isOperative: checkIsOperative(statusCode, active),
            expirationDate: moment(card.consultaTarjetaPosGlobal.fechaCaducidad),
            noSecureCommerceAllowed: card.consultaTarjetaPosGlobal.indicadorPagoNoSeguro === 0,
            availableBalance: {
                amount: get(card, 'saldoDisponible.importe', 0) || 0,
                currency: get(card, 'saldoDisponible.divisa', EURO_TEXT) || EURO_TEXT
            },
            balanceDrawn: {
                amount: Math.abs(get(card, 'saldoDispuesto.importe', 0)) || 0,
                currency: get(card, 'saldoDispuesto.divisa', EURO_TEXT) || EURO_TEXT
            },
            limit: {
                amount: get(card, 'limite.importe') || 0,
                currency: get(card, 'limite.divisa', EURO_TEXT) || EURO_TEXT
            },
            contract: {
                number: get(card, 'contrato.numerodecontrato'),
                product: get(card, 'contrato.producto')
            },
            associatedAccount: buildAssociatedAccount(card),
            associatedAccountComplex: {
                accountNumber: get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.numeroCuenta', ''),
                center: get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.centro.centro', ''),
                controlDigit: get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.digitoControl', ''),
                enterprise: get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.centro.empresa', ''),
            },
            isNerudaCard: checkIsNeruda(card),
            isVanityCard: checkIsVanity(card),
            isHormigueroCard: checkIsHormiguero(card),
            person: buildPerson(card),
            associatedAccountShort: (
                get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.numeroCuenta')
                    ? get(card, 'consultaTarjetaPosGlobal.ctablaCnt1.numeroCuenta').substr(3)
                    : ''
            ),
            criteria: card.criterios,
            filters: {
                valid: get(card, 'filtros.valido') === FILTERS_VALID
            },
            accessIndicator: card.indicadorAcceso,
            accountType: card.tipoCuenta,
            titularName: trim(card.nombretitular),
            beneficiaryName: trim(card.titunom) || '-',
            beneficiaryNameOnCard: trim(card.consultaTarjetaPosGlobal.txtRelieve),
            alias: {
                current: trim(card.descripcion) || type,
                tentative: '',
                isUpdating: false,
                error: false
            },
            codIban: card.codIban,
            pin: {
                success: false,
                code: '',
                isFetching: false,
                error: ''
            },
            interventionType,
            extra: {},
            interventionList: createInterventionList(cards, card),
            isOwnerOfAdditional: isContractOwnerOfAdditionalCard(cards, card),
            cvv: {
                success: false,
                code: '',
                isFetching: false,
                error: ''
            },
            newProduct: productTypeId,
            productSubType: productSubtypeId,
            fullContractNumber: buildFullContractNumber(card),
            isMetallic,
            isOpenCredit,
            isDiamond,
            isPremium,
            isRevolving,
            revolvingPack,
            isConfigurable: configurableBIN.includes(card.panmdp.split(' ').join('').substring(0, 6)),
            isPassportDebit: passportBIN.includes(card.panmdp.split(' ').join('').substring(0, 6)),
            totalContractBalanceDrawn: {
                amount: buildTotalContractBalanceDrawnAmount(cards, card.contrato),
                currency: get(card, 'saldoDispuesto.divisa', EURO_TEXT) || EURO_TEXT
            },
            isViaT: isViaT(productTypeId, productSubtypeId),
            isLegalCard: isLegalCard(product),
            isAtHomeAbroad: isAtHomeAbroad(productTypeId, productSubtypeId),
            isR42: isR42(productTypeId, productSubtypeId),
            isR42MasterCard: isR42MasterCard(card),
            isR42BetaalPasCard: isR42BetaalPasCard(productTypeId, productSubtypeId),
            isVirtualDebit: isVirtualDebit(productTypeId, productSubtypeId),
            currentLimitCode: get(card, 'consultaTarjetaPosGlobal.grupoLimite1.codLimite', ''),
            r42: {
                activated: get(card, 'r42.activated', ''),
                enabledPromotionDate: get(card, 'r42.enabledPromotionDate', ''),
                finalCost: get(card, 'r42.finalCost', ''),
                promotionCost: get(card, 'r42.promotionCost', ''),
                promotionDate: get(card, 'r42.promotionDate', ''),
                travelSwitchVisible: get(card, 'r42.travelSwitchVisible'),
                vip: get(card, 'r42.vip', '')
            },
            r42Activated: get(card, 'r42.activated', ''),
            travelPlus: {
                isFetching: isR42(productTypeId, productSubtypeId) && getTravelPlusState(product.type, product.subtype, product.standard) === R42_CARD_ON,
                showToggleButton: active && isR42(productTypeId, productSubtypeId),
                status: 'R42_CARD_OFF',

            }
        };
        if (get(card, 'r42.travelSwitchVisible') === true) {
            cardNumbersTravelPlus.push({
                cardId,
                contractNumber: newCard.contract.number,
                isActivated: get(card, 'r42.activated', ''),
                isStandardOn: getTravelPlusState(product.type, product.subtype, product.standard) === R42_CARD_ON,
                productType: product.type,
            });
        }

        newCard.hasBeneficiaries = checkBeneficiaries(newCard.interventionList);
        newCard.isOpenYoung = isOpenYoung(card);
        newCard.isVirtualCredit = isVirtualCredit(productTypeId, productSubtypeId);
        newCard.tagging = getTypeCardForTaggin(card);
        newCard.isForRevolvingProcess = checkRevolvingProcess(newCard);
        newCard.standarAlias = getAliasCard(newCard);
        byId[cardId] = newCard;

        byOrder.push(cardId);

        switch (type) {
            case CARD_CREDIT:
                creditByOrder.push(cardId);
                break;
            case CARD_DEBIT:
                const limitsList = get(card, 'consultaTarjetaPosGlobal') || [];
                const limitCommerceObject = limitsList.grupoLimite1;
                newCard.limitCommerce = {
                    amount: get(limitCommerceObject, 'limite.importe', ' - '), // Don't use || here, to avoid issues with 0
                    currency: get(limitCommerceObject, 'limite.divisa') || EURO_TEXT
                };
                const limitExtractObject = limitsList.grupoLimite3;
                newCard.limitExtract = {
                    amount: get(limitExtractObject, 'limite.importe', ' - '), // Don't use || here, to avoid issues with 0
                    currency: get(limitExtractObject, 'limite.divisa') || EURO_TEXT
                };
                newCard.isMinicard = isMinicard(card);
                newCard.isOpenDebit = isOpenDebit(card);
                newCard.isCharity = isCharity(card);
                debitByOrder.push(cardId);
                break;
            case CARD_ECARD:
                // For ecards, balance should be obtained from saldoPrepago field
                const prepaidBalance =
                    get(card, 'consultaTarjetaPosGlobal.saldoPrepago.importe', 0) || 0;
                newCard.availableBalance.amount = prepaidBalance;
                newCard.balanceDrawn.amount = prepaidBalance;
                ecardByOrder.push(cardId);
                break;
        }
        newCard.contractName = getContractName(newCard);
    });

    const creditCards = mergeOrderAndId(creditByOrder, byId);
    const debitCards = mergeOrderAndId(debitByOrder, byId);
    const eCards = mergeOrderAndId(ecardByOrder, byId);

    const nonExcludedCreditCards = creditCards.filter(
        card => card.filters.valid && card.visibleinPG
    );
    const nonExcludedCreditCardsFiltered = uniqContractFilter(nonExcludedCreditCards);
    const nonExcludedECards = eCards.filter(card => card.filters.valid && card.visibleinPG);

    return state
        .mergeDeep({
            cardNumbersTravelPlus,
            hasDiamondPack,
            hasPremiumPack,
            hasR42Benefits,
            isFetching: false,
            credit: {
                byOrder: creditByOrder,
                availableBalance: groupByCurrencyAndCalculateTotally(
                    creditCards,
                    'availableBalance'
                ),
                availableBalanceForPG: groupByCurrencyAndCalculateTotally(
                    nonExcludedCreditCardsFiltered,
                    'availableBalance'
                ),
                balanceDrawn: groupByCurrencyAndCalculateTotally(creditCards, 'balanceDrawn'),
                balanceDrawnForPG: groupByCurrencyAndCalculateTotally(
                    nonExcludedCreditCards,
                    'balanceDrawn'
                ),
                excludedCards: howManyExcludedCards(creditCards)
            },
            debit: {
                byOrder: debitByOrder,
                availableBalance: groupByCurrencyAndCalculateTotally(
                    debitCards,
                    'availableBalance'
                ),
                availableBalanceForPG: groupByCurrencyAndCalculateTotally(
                    debitCards,
                    'availableBalance'
                ),
                balanceDrawn: groupByCurrencyAndCalculateTotally(debitCards, 'balanceDrawn'),
                balanceDrawnForPG: groupByCurrencyAndCalculateTotally(debitCards, 'balanceDrawn')
            },
            ecard: {
                byOrder: ecardByOrder,
                availableBalance: groupByCurrencyAndCalculateTotally(eCards, 'availableBalance'),
                availableBalanceForPG: groupByCurrencyAndCalculateTotally(
                    nonExcludedECards,
                    'availableBalance'
                ),
                balanceDrawn: groupByCurrencyAndCalculateTotally(eCards, 'balanceDrawn'),
                balanceDrawnForPG: groupByCurrencyAndCalculateTotally(
                    nonExcludedECards,
                    'balanceDrawn'
                ),
                excludedCards: howManyExcludedCards(eCards)
            }
        })
        .set('byId', Immutable.fromJS(byId))
        .set('byOrder', Immutable.fromJS(byOrder))
        .set('byTypeSubtype', Immutable.fromJS(byTypeSubtype));
}

function cardsReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.FETCH_CARDS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_CARDS_SUCCESS:
        case actionTypes.GLOBAL_POSITION_REQUEST_SUCCESS:
            return updateCardsList(state, get(action, 'payload.datosSalidaTarjetas.tarjetas', []));
        case actionTypes.FETCH_CARDS_FAILURE:
            return state.merge({
                error: action.error,
                isFetching: false
            });
        case actionTypes.SET_CARD_ALIAS_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        alias: {
                            isUpdating: true,
                            tentative: action.payload.alias,
                            error: false
                        }
                    }
                }
            });
        case actionTypes.SET_CARD_ALIAS_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        alias: {
                            isUpdating: false,
                            current: action.payload.alias,
                            tentative: ''
                        }
                    }
                }
            });
        case actionTypes.SET_CARD_ALIAS_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        alias: {
                            isUpdating: false,
                            tentative: '',
                            error: true
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARDS_PIN_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        pin: {
                            isFetching: true
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARDS_PIN_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        pin: {
                            isFetching: false,
                            code: action.payload.code || '',
                            success: !!action.payload.code
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARDS_PIN_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        pin: {
                            isFetching: false,
                            error: action.payload.error
                        }
                    }
                }
            });
        case actionTypes.SET_CARDS_PIN_EXPIRED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        pin: {
                            code: ''
                        }
                    }
                }
            });
        case actionTypes.CARD_COUNTRIES_ONLY_SET_REQUESTED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        originCountryZone: action.payload.originCountryZone,
                    },
                }
            });

        case actionTypes.CARDS_PIN_RESET:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        pin: {
                            success: false,
                            code: '',
                            isFetching: false,
                            error: ''
                        }
                    }
                }
            });
        case actionTypes.ECARD_INFORMATION_SUCCESS:
            return state.setIn(
                ['byId', action.payload.cardId, 'extra', 'eCardInfo'],
                Immutable.fromJS(action.payload.data)
            );
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_CARD_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        filters: {
                            error: false,
                            isUpdating: true
                        }
                    }
                }
            });
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_CARD_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        filters: {
                            error: true,
                            isUpdating: false
                        }
                    }
                }
            });
        case actionTypes.SET_PROFILE_EDIT_SETTINGS_CARD_SUCCESS:
            let partialMergedState = state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        filters: {
                            error: false,
                            isUpdating: false,
                            valid: action.payload.valid
                        }
                    }
                }
            });

            // (Andres): mergeOrderAndId does not handle immutable Lists
            const creditByOrder = partialMergedState.get('credit').get('byOrder').toJS();
            const ecardByOrder = partialMergedState.get('ecard').get('byOrder').toJS();
            const byId = partialMergedState.get('byId').toJS();

            const creditCards = mergeOrderAndId(creditByOrder, byId);
            const eCards = mergeOrderAndId(ecardByOrder, byId);

            const nonExcludedCreditCards = creditCards.filter(
                card => card.filters.valid && card.visibleinPG
            );
            const nonExcludedECards = eCards.filter(card => card.filters.valid && card.visibleinPG);

            return partialMergedState.mergeDeep({
                credit: {
                    excludedCards: howManyExcludedCards(creditCards),
                    availableBalance: groupByCurrencyAndCalculateTotally(
                        creditCards,
                        'availableBalance'
                    ),
                    availableBalanceForPG: groupByCurrencyAndCalculateTotally(
                        nonExcludedCreditCards,
                        'availableBalance'
                    ),
                    balanceDrawnForPG: groupByCurrencyAndCalculateTotally(
                        nonExcludedCreditCards,
                        'balanceDrawn'
                    )
                },
                ecard: {
                    excludedCards: howManyExcludedCards(eCards),
                    availableBalance: groupByCurrencyAndCalculateTotally(
                        eCards,
                        'availableBalance'
                    ),
                    availableBalanceForPG: groupByCurrencyAndCalculateTotally(
                        nonExcludedECards,
                        'availableBalance'
                    ),
                    balanceDrawnForPG: groupByCurrencyAndCalculateTotally(
                        nonExcludedECards,
                        'balanceDrawn'
                    )
                }
            });
        case actionTypes.CARD_CVV_RESET:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        cvv: {
                            success: false,
                            code: '',
                            isFetching: false,
                            error: ''
                        }
                    }
                }
            });
        case actionTypes.SET_CARD_CVV_EXPIRED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        cvv: {
                            code: ''
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARD_CVV_REQUEST:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        cvv: {
                            isFetching: true
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARD_CVV_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        cvv: {
                            isFetching: false,
                            code: action.payload.code || '',
                            success: !!action.payload.code
                        }
                    }
                }
            });
        case actionTypes.FETCH_CARD_CVV_FAILURE:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        cvv: {
                            isFetching: false,
                            error: action.payload.error
                        }
                    }
                }
            });
        case actionTypes.CARD_CONTROL_ON_SUCCESS:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        control: !action.payload.switchedOff
                    }
                }
            });
        case actionTypes.CARD_CHANNELS_GET_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        channels: action.payload.channels,
                    },
                }
            });
        case actionTypes.CARD_CHANNELS_SET_REQUESTED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        channels: action.payload.channels,
                    },
                }
            });
        case actionTypes.CARD_CHANNELS_SET_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        channels: action.payload.channels,
                    },
                }
            });
        case actionTypes.CARD_CHANNELS_SET_FAILED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        channels: action.payload.channels,
                    },
                }
            });
        case actionTypes.CARD_COUNTRIES_GET_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        locations: action.payload.locations,
                    },
                }
            });
        case actionTypes.CARD_COUNTRY_SET_FAILED: {
            const locations = state
                .toJSON()
                .byId[action.payload.cardId]
                .locations
                .reduce((acc, current) => {
                    if (!current.countries.some(country => country.name === action.payload.countryId)) return [...acc, current];
                    return [...acc, { ...current, countries: current.countries.reduce((countries, country) => (country.name === action.payload.countryId ? [...countries, { ...country, enabled: action.payload.active }] : [...countries, country]), []) }];
                }, []);
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations },
                }
            });
        }
        case actionTypes.CARD_COUNTRY_SET_REQUESTED:
            const locations = state
                .toJSON()
                .byId[action.payload.cardId]
                .locations
                .reduce((acc, current) => {
                    if (!current.countries.some(country => country.name === action.payload.countryId)) return [...acc, current];
                    return [...acc, { ...current, countries: current.countries.reduce((countries, country) => (country.name === action.payload.countryId ? [...countries, { ...country, enabled: action.payload.active }] : [...countries, country]), []) }];
                }, []);
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations },
                }
            });
        case actionTypes.CARD_COUNTRY_SET_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        locations: action.payload.locations,
                    },
                }
            });
        case actionTypes.CARD_INFO_GET_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        channels: action.payload.channels,
                        locations: action.payload.locations,
                        originCountryZone: action.payload.originCountryZone,
                    },
                }
            });

        case actionTypes.CARD_ZONE_SPAIN_SUCCEEDED:
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: {
                        originCountryZone: action.payload.originCountryZone,
                    },
                }
            });

        case actionTypes.CARD_ZONE_SET_FAILED: {
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations: action.payload.locations },
                }
            });
        }
        case actionTypes.CARD_ZONE_SET_SUCCEEDED: {
            const locations = state
                .toJSON()
                .byId[action.payload.cardId]
                .locations
                .reduce((acc, current) => {
                    if (current.code !== action.payload.zoneId) return [...acc, current];
                    return [...acc, { ...current, countries: current.countries.reduce((countries, country) => [...countries, { ...country, enabled: action.payload.active }], []) }];
                }, []);
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations },
                }
            });
        }
        case actionTypes.CARD_COUNTRIES_ONLY_SET_FAILED: {
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations: action.payload.locations },
                }
            });
        }
        case actionTypes.CARD_COUNTRIES_ONLY_SET_SUCCEEDED: {
            const locations = state
                .toJSON()
                .byId[action.payload.cardId]
                .locations
                .map(zone => ({
                    ...zone,
                    countries: zone.countries.map(country => ({
                        ...country,
                        enabled: action.payload.countriesIds.includes(country.name),
                    })),
                }));
            return state.mergeDeep({
                byId: {
                    [action.payload.cardId]: { locations },
                }
            });
        }
        case actionTypes.SET_CARD_TRAVEL_PLUS_ON_FAILURE:
            let byIdListTravelFailure = {};

            if (!!action.payload.cardId) {
                byIdListTravelFailure[action.payload.cardId] = {
                    travelPlus: {
                        error: true,
                        isFetching: false,
                    }
                };
            } else {
                state.get('cardNumbersTravelPlus').map(immCardNumberTravelPlus => {
                    byIdListTravelFailure[immCardNumberTravelPlus.get('cardId')] = {
                        travelPlus: {
                            error: true,
                            isFetching: false,
                        }
                    }
                });
            }

            return state.mergeDeep({
                byId: byIdListTravelFailure,
            });
        case actionTypes.SET_CARD_TRAVEL_PLUS_ON_REQUEST:
            let byIdListTravelRequest = {};
            const travelPlus = {
                error: false,
                isFetching: true,
            };

            if (!!action.payload.cardId) {
                byIdListTravelRequest[action.payload.cardId] = {
                    travelPlus,
                };
            } else {
                state.get('cardNumbersTravelPlus').map(immCardNumberTravelPlus => {
                    if (immCardNumberTravelPlus.get('isStandardOn')) {
                        byIdListTravelRequest[immCardNumberTravelPlus.get('cardId')] = {
                            travelPlus,
                        };
                    }
                });
            }

            return state.mergeDeep({
                byId: byIdListTravelRequest,
            });
        case actionTypes.SET_CARD_TRAVEL_PLUS_ON_SUCCESS:
            let byIdListTravelSuccess = {};
            const callback = (contractNumber, cardId, isActivated) => {
                 let travelPlus = {
                    error: get(contractNumber, 'errorDescripcion') === "Error: M4_4552" ? false : get(contractNumber, 'error'),
                    errorDescripcion: get(contractNumber, 'errorDescripcion') === "Error: M4_4552" && true,
                    isFetching: false,
                };
                const cardIsNotActived = get(contractNumber, 'contrato.fechaValidez') === R42_CARD_HAS_NEVER_BEEN_ACTIVATED;
                 if (travelPlus.error === false) {
                     travelPlus.status = isActivated && travelPlus.errorDescripcion ? R42_CARD_ON : (travelPlus.errorDescripcion || cardIsNotActived) ? R42_CARD_IS_NOT_ACTIVED : get(contractNumber, 'contrato.estado', 'A') === 'A' ? R42_CARD_PENDING_DEACTIVATION : R42_CARD_ON;
                    travelPlus.validDate = get(contractNumber, 'contrato.fechaValidez', '');
                    travelPlus.subscriptionDate = get(contractNumber, 'contrato.fechaCuota', '');
                 }
                 byIdListTravelSuccess[cardId] = {
                    travelPlus,
                };
            };
            let contractNumber;

            if (!!action.payload.cardId) {
                contractNumber = getContractByContractNumber(action.payload.contractNumbers, state.getIn(['byId', action.payload.cardId, 'contract', 'number']));
                callback(contractNumber, action.payload.cardId);
            } else {
                state.get('cardNumbersTravelPlus').map(immCardNumberTravelPlus => {
                    contractNumber = getContractByContractNumber(action.payload.contractNumbers, immCardNumberTravelPlus.get('contractNumber'));
                    if (!!contractNumber) {
                        callback(contractNumber, immCardNumberTravelPlus.get('cardId'), immCardNumberTravelPlus.get('isActivated'));
                    }
                });
            }

            return state.mergeDeep({
                byId: byIdListTravelSuccess,
            });

        case actionTypes.LAVANDA_CARDS_REQUEST:
            return state.mergeDeep({
                credit: {
                    lavanda: {
                        isFetching: true,
                    }
                }
            });
        case actionTypes.LAVANDA_CARDS_SUCCESS:
            return state.mergeDeep({
                credit: {
                    lavanda: {
                        cards: action.payload.cards,
                        isFetching: false,
                        success: true,
                    }
                }
            });
        case actionTypes.LAVANDA_CARDS_FAILURE:
            return state.mergeDeep({
                credit: {
                    lavanda: {
                        error: action.payload.error,
                        isFetching: false,
                    }
                }
            });
        case actionTypes.LAVANDA_CARDS_RESET:
            return state.mergeDeep({
                credit: {
                    lavanda: {
                        cards: '',
                        error: null,
                        isFetching: false,
                        success: false,
                    },
                }
            });
        default:
            return state;
    }
}

module.exports = cardsReducer;
