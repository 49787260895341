// @vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');
const map = require('lodash/collection/map');
const moment = require('moment');


// @helpers
const { getControlDigitFromCodBban } = require('utilities/ibanHelper');
const toFirstUppercase = require('utilities/stringHelper').toFirstUppercase;
const { formatText } = require('core/i18n').i18n;

// @ Contact Center constants
const contactCenterActionTypes = require('constants/contactCenter/actionTypes');

// @reducers
const brokerInternalTransferFundsStep1 = require('./brokerInternalTransferFunds/brokerInternalTransferFundsStep1');
const brokerInternalTransferFundsStep2 = require('./brokerInternalTransferFunds/brokerInternalTransferFundsStep2');
const brokerInternalTransferContributionFundsStep = require('./brokerInternalTransferFunds/brokerInternalTransferContributionFundsStep');
// @constants
const actionTypes = require('constants/actionTypes');
const {
    FIRST_STEP,
    SECOND_STEP,
    WIZARD_FIRST_STEP,
    WIZARD_SECOND_STEP,
    MYPROFILE_BROKER_INTERNAL_TRANSFER_FUND,
    MYPROFILE_BROKER_CONVENIENCE_MIFID_TEST_WIZARD_URL,
    BROKER_INTERNAL_TRANSFER_NEW_FUND,
    CHARGE_ACCOUNT_PREFIX
} = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            brokerInternalTransferFundsStep1(undefined, { type: null }),
            brokerInternalTransferFundsStep2(undefined, { type: null })
        ],
        skipContributionStep: false,
        investmentFunds: Immutable.fromJS({}),
        interveners: Immutable.List(),
        errorReceived: '',
        isFetching: false,
        inProgress: false,
        willLeave: false,
        visibleStep: WIZARD_FIRST_STEP,
        submitSuccessful: false,
        stepSelectFundsLoading: false,
        stepSelectFundsCanContinue: false,
        disableLeavingModal: false,
        destinationFund: {
            associatedAccount: '',
            fullContractNumber: ''
        },
        showModalSameIsin: false,
        isinFromNewFund: '',
        exAnteCostList: [],
        exAnteFetching: false,
        enableEconomicReport: true,
    });
}

function updatePreMotor(jsonData, state){
    let subtipoContratoDestino = jsonData.subtipoContratoDestino;
    let tipodeproductoContratoDestino = jsonData.tipodeproductoContratoDestino;

    if (jsonData.indAltaFondoDest === 'S') {
        const customId = state.getIn(['steps', FIRST_STEP, 'selectedNewFund']);
        const keys = customId.split('-');
        tipodeproductoContratoDestino = keys[0];
        subtipoContratoDestino = keys[1];
    }

    return {
        indAltaFondoDest: jsonData.indAltaFondoDest,
        claveFondoContrOrigen: jsonData.claveFondoContrOrigen,
        codigoFondo: jsonData.codigoFondo,
        isinFondoOrigen: jsonData.isinFondoOrigen,
        contador: jsonData.contador,
        ctaAsociada_numerodecontrato: jsonData.ctaAsociada.numerodecontrato,
        ctaAsociada_producto: jsonData.ctaAsociada.producto,
        fechaValor: jsonData.fechaValor,
        icsaldod: jsonData.icsaldod,
        descripcionFondoLarga: jsonData.descripcionFondoLarga,
        importeParticionesPendienteReparo: jsonData.importeParticionesPendienteReparo,
        saldoDia: jsonData.saldoDia,
        subtipoContratoOrigen: jsonData.subtipoContratoOrigen,
        tipodeproductoContratoOrigen: jsonData.tipodeproductoContratoOrigen,
        subtipoContratoDestino,
        tipodeproductoContratoDestino,
        subgrupoCtaAsociada: jsonData.subgrupoCtaAsociada,
        tipodeproductoCtaAsociada: jsonData.tipodeproductoCtaAsociada,
        ultimoValorLiquidativo: jsonData.ultimoValorLiquidativo,
        digitoDeControl: getControlDigitFromCodBban(jsonData.contratoOrigenIBAN.codbban),
        isFetching: false,
        participacTraspaso: jsonData.participacTraspaso,
        exAnteCostList: jsonData.exAnteCostList,
        estimatedBaseAmount: jsonData.estimatedBaseAmount,
        estimatedBaseAmountExchanged: jsonData.estimatedBaseAmountExchanged,
        isGuaranteedFund: jsonData.isGuaranteedFund,
        exAnteFetching: false,
        recurringTransferCosts: jsonData.recurringTransferCosts
    };
}

function updateStep(index, steps, action) {
    const immSteps = steps
    const originHasContribution = immSteps.getIn([FIRST_STEP, 'originHasContribution']);
    const destinationHasContribution = immSteps.getIn([FIRST_STEP, 'destinationHasContribution']);
    const transferType = immSteps.getIn([FIRST_STEP, 'type']);
    const onlyOriginContribution = originHasContribution && !destinationHasContribution;
    const legalStep = onlyOriginContribution && transferType === 'total' ?  2 : 1; 
    const contributionStep = onlyOriginContribution && transferType === 'total' ? 1 : undefined;
    return steps.update(index, step => {
        switch (index) {
            case FIRST_STEP:
                return brokerInternalTransferFundsStep1(step, action);
            case (legalStep):
                return brokerInternalTransferFundsStep2(step, action);
            case (contributionStep):
                const partial = brokerInternalTransferContributionFundsStep(step, action);
                return partial
        }
    });
}

const findPeriodicityLabel = (periodicity) => {
    switch (periodicity.toLowerCase()) {
        case 'weekly':
            return formatText('brokerContributionFundStep1-weeklyPeriodicity');
        case 'monthly':
            return formatText('brokerContributionFundStep1-monthlyPeriodicity');
        case 'quarterly':
            return formatText('brokerContributionFundStep1-quarterlyPeriodicity');
        case 'semiannual':
            return formatText('brokerContributionFundStep1-semestralPeriodicity');
        case 'annual':
            return formatText('brokerContributionFundStep1-annualPeriodicity');
        default:
            return '';
    }
};

function reset(immState) {
    return immState.merge({
        steps: setInitialState().get('steps'),
        errorReceived: '',
        isFetching: false,
        inProgress: false,
        willLeave: false,
        disableLeavingModal: false,
        visibleStep: WIZARD_FIRST_STEP,
        submitSuccessful: false,
        stepSelectFundsLoading: false,
        stepSelectFundsCanContinue: false,
        error: ''
    });
}

const parseInterveners = (interveners = []) =>
    map(interveners, intervener => ({
        name: trim(intervener.nombre),
        type: trim(intervener.desIntervencion),
        interventionType: trim(intervener.tipoIntervencion),
        interventionForm: trim(intervener.formaIntervencion),
        documentNumber: trim(intervener.numDocumento),
        documentType: trim(intervener.tipoDocumento),
        personNumber: trim(intervener.numPersona),
        personType: trim(intervener.tipPers)
}));

function getFundById(funds, fundId) {
    return find(funds, (fund) => get(fund, 'id') === fundId);
}

function getFullContractNumber({ controlDigit, product, contractNumber }) {
    return (
        CHARGE_ACCOUNT_PREFIX +
        trim(controlDigit) +
        trim(product) +
        trim(contractNumber)
    );
}

function getFundAssociatedAccountNumberByFundId({
    fundId,
    accounts,
    hiredFunds
}) {
    const fundById = getFundById(hiredFunds, fundId);
    const fundAssociatedAccountContract = trim(
        get(fundById, 'associatedAccountContractNumber')
    );

    const fundAssociatedAccount = find(accounts, (account) => {
        const accountContract = get(account, 'contractNumber');
        return accountContract === fundAssociatedAccountContract;
    });

    const fundAssociatedAccountFullContractNumber = getFullContractNumber({
        controlDigit: get(fundAssociatedAccount, 'ibanComplex.digitodecontrol'),
        product: get(fundAssociatedAccount, 'product'),
        contractNumber: get(fundAssociatedAccount, 'contractNumber')
    });

    return fundAssociatedAccountFullContractNumber;
}

function getDestinationFundFromNewFund({
    originFundId,
    transferServiceResponse,
    hiredFunds,
    accounts
}) {
    const originFundAssociatedAccountNumber = getFundAssociatedAccountNumberByFundId({
        fundId: originFundId,
        accounts,
        hiredFunds
    });

    const destinationFund = {
        controlDigit: get(transferServiceResponse, 'fondoDestino.digitoControl'),
        product: get(transferServiceResponse, 'fondoDestino.producto'),
        contractNumber: get(transferServiceResponse, 'fondoDestino.numerodecontrato')
    };
    const destinationFundContract = getFullContractNumber(destinationFund);

    return {
        // (franco.montenegro) When transfering to a new fund, the associated
        // account will be the associated account of the origin fund
        orderNumber:get(transferServiceResponse, 'listaObtDatos.obtDatos[0].referenciaTraspaso','-'),
        associatedAccount: originFundAssociatedAccountNumber,
        fullContractNumber: destinationFundContract
    };
}

function getDestinationFundFromHiredFund({
    destinationFundId,
    accounts,
    hiredFunds,
    transferServiceResponse
}) {
    const destinationFundAssociatedAccount = getFundAssociatedAccountNumberByFundId({
        fundId: destinationFundId,
        accounts,
        hiredFunds
    });

    const destinationFund = getFundById(hiredFunds, destinationFundId);
    const destinationFundContract = getFullContractNumber({
        controlDigit: get(destinationFund, 'controlDigit'),
        product: get(destinationFund, 'product'),
        contractNumber: get(destinationFund, 'contractNumber')
    });

    return {
        orderNumber:get(transferServiceResponse, 'listaObtDatos.obtDatos[1].referenciaTraspaso','-'),
        associatedAccount: destinationFundAssociatedAccount,
        fullContractNumber: destinationFundContract
    };
}

function getDestinationFund({
    immState,
    hiredFunds,
    accounts,
    transferServiceResponse
}) {
    const selectedFundTargetOption = immState.getIn(['steps', FIRST_STEP, 'fundType']);
    const toNewFundOption = BROKER_INTERNAL_TRANSFER_NEW_FUND;
    const isTransferToNewFund = selectedFundTargetOption === toNewFundOption;

    if (isTransferToNewFund) {
        const originFundId = immState.getIn(['steps', FIRST_STEP, 'originFund']);
        return getDestinationFundFromNewFund({
            originFundId,
            transferServiceResponse,
            hiredFunds,
            accounts
        });
    }

    const destinationFundId = immState.getIn(['steps', FIRST_STEP, 'destinationFund']);
    return getDestinationFundFromHiredFund({
        destinationFundId,
        transferServiceResponse,
        accounts,
        hiredFunds
    });
}

const internalTransferFund = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    let jsonData;
    let legalStep;
    let contributionStep;
    const immSteps = state.get('steps');
    const originHasContribution = immSteps.getIn([FIRST_STEP, 'originHasContribution']);
    const destinationHasContribution = immSteps.getIn([FIRST_STEP, 'destinationHasContribution']);
    const onlyOriginContribution = originHasContribution && !destinationHasContribution;
    const transferType = immSteps.getIn([FIRST_STEP, 'type']);
    legalStep = onlyOriginContribution && transferType === 'total' ?  2 : 1; 
    contributionStep = onlyOriginContribution && transferType === 'total' ? 1 : undefined;

    switch (action.type) {
        case actionTypes.LOCATION_CHANGE:
            // This logic replace adding logic inside componentWillUnmount function in
            // InternalTransferFundView component, since it can be executed unexpectedly.
            // i.e. we want to reset the state when the component is unmounted, but the
            // component's parent renders an spinner instead of this child because of an
            // asynchronous fetch, provoking an unwanted reset.
            //
            // This is why we came out with this solution:
            //
            // *We were reseting the internal transfer state on InternalTransferFundView's
            // componentWillUnmount*
            //
            // When the user goes to internalTransferFund URL:
            //
            // 1. User select the origin and destination transfer accounts and we persist them in
            // the internal transfer state, then goes to the next step.
            // 2. MiFID pops up.
            // 3. The user completes mifid or retake the test.
            // 4. OTP signature validation is prompted.
            // 5. The signature is valid and we mark all the global position products as outdated.
            // 6. We go back to the internal transfer view, it renders the transfer wizard to continue
            // with the operation before mifid pops up, but a fetch for updating global position
            // products is triggered.
            // 7. The parent of internal transfer view (MyProfileView) renders an spinner in the
            // middle of the webpage provoking an unmount of the transfer view component, resulting
            // in a reset of the origin and destination transfer account.
            // 8. When the loading finishes, the internal transfer view is rerendered but this time
            // the wizard to continue the operation is gone, because the transfer state has been
            // reseted by the componentWillUnmount function executed in the step before.
            //
            // The only case when we want to reset the state is when the user navigates to
            // other url but internalTransferFund and mifid test wizard URL and not necessarily
            // when the component is unmounted.

            const pathname = action.payload.pathname;
            const userIsInInternalTransferFund = MYPROFILE_BROKER_INTERNAL_TRANSFER_FUND === pathname;
            const userIsInMifidTest = MYPROFILE_BROKER_CONVENIENCE_MIFID_TEST_WIZARD_URL === pathname;
            if (userIsInInternalTransferFund || userIsInMifidTest) {
                return state;
            }

            return reset(state);
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SUCCESS:
            return state.merge({
                stepSelectFundsCanContinue: true,
                stepSelectFundsLoading: false,
                disableLeavingModal: false,
                steps: updateStep(FIRST_STEP, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_LOADING:
            return state.merge({
                stepSelectFundsCanContinue: false,
                stepSelectFundsLoading: true,
                disableLeavingModal: true
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_SHOW_MODAL:
            return state.merge({
                showModalSameIsin: true,
                isinFromNewFund: action.payload.selectedNewFundISIN
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_HIDE_MODAL:
            return state.merge({
                showModalSameIsin: false
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_RESET:
            return reset(state);
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_WILL_CANCEL:
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_STEP:
            const immSteps = state.get('steps');
            const originHasContribution = immSteps.getIn([FIRST_STEP, 'originHasContribution']);
            const destinationHasContribution = immSteps.getIn([FIRST_STEP, 'destinationHasContribution']);
            const onlyOriginContribution = originHasContribution && !destinationHasContribution;
            const transferType = immSteps.getIn([FIRST_STEP, 'type']);
            if (onlyOriginContribution && transferType === 'total') {
                switch (state.get('visibleStep')) {
                    case WIZARD_FIRST_STEP:
                        partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                        visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                        break;
                    case WIZARD_SECOND_STEP:
                        partialSteps = state.get('steps');
                        visibleStep = 3;
                        break;
                    case 3:
                        partialSteps = updateStep(2, state.get('steps'), action);
                        visibleStep = 3;
                        break;
                    default:
                        partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                        visibleStep = WIZARD_FIRST_STEP;
                }  

            } else {
                switch (state.get('visibleStep')) {
                case WIZARD_FIRST_STEP:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = partialSteps.getIn([FIRST_STEP, 'valid']) ? WIZARD_SECOND_STEP : WIZARD_FIRST_STEP;
                    break;
                case WIZARD_SECOND_STEP:
                    partialSteps = updateStep(SECOND_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_SECOND_STEP;
                    break;
                default:
                    partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                    visibleStep = WIZARD_FIRST_STEP;
            }
            }
            
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STEP_SELECT_FUNDS_VALIDATE:
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_DESTINATION_FUND_ID:
        case actionTypes.BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_INPUT_VALUE:
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_TYPE:
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_NEW_FUND_SELECTED:
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_FUND_TYPE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_ORIGIN_FUND_ID:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
                return state.merge({
                    steps: partialSteps,
                    isinFromNewFund: ''
                });
        case actionTypes.BROKER_INTERNAL_TRANSFER_FUNDS_SET_PARTIAL_TRANSFER_TYPE:
            partialSteps = updateStep(FIRST_STEP, state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_TOGGLE_DISCLAIMER:
            return state.merge({
                steps: updateStep(legalStep, state.get('steps'), action)
            });
        case contactCenterActionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_YES_NO_OPTION_CHANGE:
        case contactCenterActionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_LEGAL_INFORMATION_RESET:
            return state.merge({
                steps: updateStep(legalStep, state.get('steps'), action)
            });

        case contactCenterActionTypes.PRE_CONTRACTUAL_MAIL_SENT_REQUEST:
            return state.merge({
                steps: updateStep(legalStep, state.get('steps'), action)
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_SUCCESS:
            jsonData = action.payload.data;
            return state.merge(updatePreMotor(jsonData, state));
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FAILURE:
            return state.merge({ exAnteFetching: false });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_PRE_MOTOR_FETCHING:
            return state.merge({ exAnteFetching: true });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_FAILURE:
            return state.merge({
                error: action.payload.error,
                disableLeavingModal: true,
                isFetching: false
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_PROGRESS:
            return state.merge({
                isFetching: true
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_POST_SUCCESS:
            return state.merge({
                destinationFund: getDestinationFund({
                    immState: state,
                    accounts: get(action, 'payload.accounts'),
                    hiredFunds: get(action, 'payload.hiredFunds'),
                    transferServiceResponse: get(action, 'payload.data')
                }),
                submitSuccessful: true,
                disableLeavingModal: true,
                isFetching: false,
                newContributionSuccess: get(action, 'payload.data.recurringTransferResp.recurringTransferCreated')
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_REQUEST:
            return state.setIn(['steps', legalStep, 'orderStorage'], true)
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_SUCCESS:
            return state.setIn(['steps', legalStep, 'orderStorage'], false)
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_STORE_ORDER_ERROR:
            return state.setIn(['steps', legalStep, 'orderStorage'], false)
        case actionTypes.FETCH_BROKER_GET_INTERVENERS_SUCCESS: {
            const nextInterveners = parseInterveners(action.payload.data.intervinientes.dato);
            return state.merge({
                isFetching: false,
                interveners: nextInterveners
            });
        }
        case actionTypes.FETCH_BROKER_GET_INTERVENERS_FAILURE:
            return state.merge({
                isFetching: false,
                interveners: Immutable.List()
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_REQUEST:
            return state.merge({
                fetchingDocumentsValidate: true
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_SUCCESS:
            return state.merge({
                fetchingDocumentsValidate: false,
                enableEconomicReport: action.payload.enableEconomicReport
            })
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_VALIDATE_DOCUMENTS_FAILURE:
            return state.merge({
                fetchingDocumentsValidate: false
            });
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SKIP_CONTRIBUTION_STEP:
            return state.merge({
                skipContributionStep: action.payload.state,
                visibleStep: action.payload.state ? 3 : 2
            });
        
        case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_INITIAL_ORIGIN_CONTRIBUTION_STEP:
            const previousContribution = action.payload.contributionData;
            const {amount, periodicity, nextInstallmentDate, subscriptionDateId, contractCode, currencyCode} = previousContribution.toJS();
            const contributionDetails = brokerInternalTransferContributionFundsStep(undefined, { type: null })
            .merge({
                amount,
                periodicityValue: toFirstUppercase(periodicity),
                periodicityLabel: findPeriodicityLabel(toFirstUppercase(periodicity)),
                selectedDate: moment(nextInstallmentDate, 'DD-MM-YYYY'),
                subscriptionDateId,
                hasPreviousContributions: true,
                contractCode,
                currencyCode
            });
            return state.merge({
                steps: state.get('steps').insert(1, contributionDetails),
                skipContributionStep: false,
            });
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_REMOVE_ORIGIN_CONTRIBUTION_STEP:        
                return state.merge({
                    steps: state.get('steps').delete(1),
                    skipContributionStep: true,
                });
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_ACCOUNT_NUMBER:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_AMOUNT:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_CHANGE_CONTRIBUTION_VALIDATE_STEP:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATE_VALUE:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_DATEPICKER_ERROR:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_CONTRIBUTION_PERIODICITY:
            case actionTypes.BROKER_WIZARD_INTERNAL_TRANSFER_SET_PREVIOUS_CONTRIBUTION_DATA:
                const partial = updateStep(contributionStep, state.get('steps'), action)
            return state.merge({
                steps: partial
            });
        
        default:
            return state;
    }
};

module.exports = internalTransferFund;
