const brokerConvenienceTestTags = (eventTag, eventAction) => ({
  alert_widget_link: {
    categoriaEvento: 'interaccion en invertimos por ti',
    accionEvento: 'siempre informado',
    etiquetaEvento: 'sigue leyendo',
  },
  rejectTest: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: eventAction,
    etiquetaEvento: 'rechazar el test',
  },
  onNextStep: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'respuesta-test-mifid-' + eventAction,
    etiquetaEvento: 'respuesta-' + eventTag,
  },
  onPreviousStep: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: eventAction,
    etiquetaEvento: 'anterior',
  },
  onCancelTest: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: eventAction,
    etiquetaEvento: 'cancelar',
  },
  onFinishTest: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'respuesta-test-mifid-' + eventAction,
    etiquetaEvento: 'respuesta-' + eventTag,
  },
  onSuccess: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'conveniente',
    etiquetaEvento: 'continuar',
  },
  onReviewingTest: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'respuesta-test-mifid-' + eventAction,
    etiquetaEvento: 'respuesta-' + eventTag,
  },
  onOpenModalMaxAttempts: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal maximos intentos diario',
    etiquetaEvento: 'vista',
  },
  onCloseModalMaxAttempts: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal maximos intentos diario',
    etiquetaEvento: 'entendido',
  },
  onOpenModalCoherence: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: `modal verificar respuesta/${eventAction}`,
    etiquetaEvento: 'vista',
  },
  onNextQuestionModalCoherence: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: `modal verificar respuesta/${eventAction}`,
    etiquetaEvento: 'siguiente pregunta',
  },
  onCloseModalCoherence: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: `modal verificar respuesta/${eventAction}`,
    etiquetaEvento: 'cerrar',
  },
  onVerifyModalCoherence: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: `modal verificar respuesta/${eventAction}`,
    etiquetaEvento: 'verificar',
  },
  onReviewTestOTP: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'confirmacion',
    etiquetaEvento: 'revisarlas',
  },
  onConfirmOTP: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'confirmacion',
    etiquetaEvento: 'confirmar',
  },
  onAfterOpenModalProfExp: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal profesional de las finanzas',
    etiquetaEvento: 'vista',
  },
  onCloseModalProfExp: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal profesional de las finanzas',
    etiquetaEvento: 'cerrar',
  },
  onClickButtonModalProfExp: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal profesional de las finanzas',
    etiquetaEvento: 'entendido',
  },
  onFailureTestByIncoherence: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'respuestas incoherentes',
    etiquetaEvento: 'ir a mis inversiones',
  },
  onAfterOpenModalProfExpWarning: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal sin experiencia financiera/experiencia en inversiones',
    etiquetaEvento: 'vista',
  },
  onCloseModalProfExpWarning: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal sin experiencia financiera/experiencia en inversiones',
    etiquetaEvento: 'cerrar',
  },
  onClickButtonModalProfExpWarning: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal sin experiencia financiera/experiencia en inversiones"',
    etiquetaEvento: 'continuar',
  },
  onClickSecondaryButtonModalProfExpWarning: {
    categoriaEvento: 'proc-tu-inviertes-test-mifid',
    accionEvento: 'modal sin experiencia financiera/experiencia en inversiones"',
    etiquetaEvento: 'reconsiderar',
  },
  onClickFirsStepDisclaimerLink: {
    categoriaEvento:"proc-tu-inviertes-test-mifid",
    accionEvento: eventAction,
    etiquetaEvento: "derechos y proteccion de datos",
  },

  onClickAccordionToggle: {
    categoriaEvento:"proc-tu-inviertes-test-mifid",
    accionEvento: "experiencia en inversiones",
    etiquetaEvento: `mas informacion/${eventTag}`
  },

  initialModalRenderFirstTime:{
    categoriaEvento: "proc-tu-inviertes-test-mifid",
    accionEvento: "modal evaluación idoneidad", 
    etiquetaEvento: "vista"
  },

  initialModalRenderReproved:{
    categoriaEvento: "proc-tu-inviertes-test-mifid",
    accionEvento: "modal evaluación idoneidad", 
    etiquetaEvento: "vista"
  },
  
  initialModalContinueFirstTime: {
    categoriaEvento: "proc-tu-inviertes-test-mifid",
    accionEvento: "modal evaluacion idoneidad", 
    etiquetaEvento: "continuar"
  },

  initialModalContinueReproved: {
    categoriaEvento: "proc-tu-inviertes-test-mifid",
    accionEvento: "modal evaluacion idoneidad", 
    etiquetaEvento: "continuar"
  },
  
  privateEquityTestNext: {
    categoriaEvento: eventTag?.categoriaEvento,
    accionEvento: eventTag?.accionEvento,
    etiquetaEvento: eventTag?.etiquetaEvento
  },

  privateEquityTestPrevious: {
    categoriaEvento: eventTag?.categoriaEvento,
    accionEvento: eventTag?.accionEvento,
    etiquetaEvento: "anterior"
  },

  privateEquityTestCancel: {
    categoriaEvento: eventTag?.categoriaEvento,
    accionEvento: eventTag?.accionEvento,
    etiquetaEvento: "cancelar"
  },

  privateEquityModalShow: {
    categoriaEvento: eventTag?.categoriaEvento,
    accionEvento: eventTag?.accionEvento, 
    etiquetaEvento: "vista"
  },

  privateEquityModalContinue: {
    categoriaEvento: eventTag?.categoriaEvento,
    accionEvento: eventTag?.accionEvento,
    etiquetaEvento: eventTag?.etiquetaEvento,
  }
});

module.exports = { brokerConvenienceTestTags };
