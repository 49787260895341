const actionTypes = require('constants/actionTypes');
const Immutable = require('immutable');

const initialState = Immutable.fromJS({
    accountId: '',
    accountIsDirty: false,
    accountIsValid: false,
    valid: false
});

function validateStep(state, allComponentsAreValid) {
    return state.merge({
        valid: allComponentsAreValid,
    });
}

function hireCardVirtualCreditStep2(state = initialState, action = { type: null }) {
    switch (action.type) {
        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_NEW_VALIDATE_STEP:
            return validateStep(state, action.payload.valid);

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_VALIDATE_ACCOUNT:
            return state.merge({
                accountIsDirty: true,
                accountIsValid: !!state.get('accountId'),
            });

        case actionTypes.HIRE_CARD_VIRTUAL_CREDIT_SET_ACCOUNT:
            return state.merge({
                accountId: action.payload.accountId,
                accountIsDirty: true,
                accountIsValid: !!action.payload.accountId,
            });

        default:
            return state;
    }
}

module.exports = hireCardVirtualCreditStep2;

