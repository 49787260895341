// @ vendor
const Immutable = require('immutable');
// @ constants
const actionTypes = require('constants/actionTypes');
const { BROKER_HIRE_INVESTMENT_STEP_2_NO } = require('constants/index');

function setInitialState() {
    return Immutable.Map().merge({
        hasSignaturePattern: true,
        valid: null,
        readChecked: __CONTACT_CENTER__,
        checkedIsValid: true,
        auth: BROKER_HIRE_INVESTMENT_STEP_2_NO
    });
}

function validateStep(step) {
    const checkedIsValid = step.get('readChecked');
    return step.merge({
        valid: checkedIsValid,
        checkedIsValid
    });
}

function toggleDisclaimer(state, action) {
    return state.merge({
        readChecked: action.payload.checked,
        checkedIsValid: true,
        valid: action.payload.checked
    });
}

function validateStepForContribution(step) {
    return step.merge({
        valid: true,
        checkedIsValid: true
    });
}

function wizardSuscriptionStep2(state = setInitialState(), action) {
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_AUTHORIZATION:
            return state.merge({
                auth: action.payload.auth === 'true'
            });
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_READCHECKED:
            return toggleDisclaimer(state, action);
        case actionTypes.BROKER_WIZARD_SUBSCRIPTION_CHANGE_VALIDATE_STEP:
            return validateStep(state);
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            return validateStepForContribution(state);
    }

    return state;
}

module.exports = wizardSuscriptionStep2;
