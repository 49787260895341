// @constants
const {
    BROKER_ADVANCED_SEARCH_UTAG_NAME
} = require('constants/index');

const brokerAdvSearchTags = (eventTag) => (
    {
        editAdvanceSearch: {
            categoriaEvento: BROKER_ADVANCED_SEARCH_UTAG_NAME,
            accionEvento: 'editar buscar',
            etiquetaEvento: ''
        },
        buyFundButton: {
            categoriaEvento: BROKER_ADVANCED_SEARCH_UTAG_NAME,
            accionEvento: 'clickButton',
            etiquetaEvento: `hire ${eventTag}`
        },
        clickFundName: {
            categoriaEvento: BROKER_ADVANCED_SEARCH_UTAG_NAME,
            accionEvento: 'fundsResults',
            etiquetaEvento: eventTag
        },

    }
)
module.exports = { brokerAdvSearchTags }