import { i18n } from 'core/i18n'
import { formatAmount } from 'utilities/currencyHelper'
import { findElementbyId } from 'routes/contractsView/utilities/contractStateHelper'
import formValidationHelper from '../utilities/formValidationHelper'
import { isPrintOfficeMode } from 'utilities/contactCenter/printerHelper'

//@constants
import { 
    EURO_SYMBOL,
    DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
    DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
    DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
    DEPOSIT_HIRE_CONFIRMATION_MANAGER,
} from 'constants/index'

const { FormattedText } = i18n;

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const isInputAmountNotZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;

    return {
        card_details: cardDetails,
        isValidStep: isValidAccount && isInputAmountNotZero
    };
}


function validateStep3(card_details) {
    let validation;
    if (!__CONTACT_CENTER__) {
        validation = {
            card_details: card_details,
            isValidStep: true,
        };
    } else {
        validation = {
            card_details: card_details,
            isValidStep: true,
        };
    }
    return validation;
}

function validateStep4(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, immConfirmationCheckboxes);

        case 4:
            return validateStep4(immCardDetails, immConfirmationCheckboxes);
    }
}

function nextButtonDisabledStep1(contractWizard) {
    return contractWizard.get('renewalDepositAccountBlocked');
}

function depositexpired_loadAmountInputAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.importMax = contractWizard.getIn(['impositionAmount', 'amount']);
        component.importMin = depositHireData.get('importeMin');
        component.defaultValue = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
        const chosenDepositMax = contractWizard.getIn(['impositionAmount', 'amount']);
        component.infoMessage = (
            <FormattedText 
                value={component.infoMessageText} 
                injectedStrings={[`${formatAmount(chosenDepositMax)} ${EURO_SYMBOL}`]} 
            />
        );
    }
    return component;
}

function depositexpired_pollingNextButtonDisabled(contractWizard, immPrecontractualDocumentation) {
    const precontractualDocumentation = immPrecontractualDocumentation.getIn(['documentation', 'documents'], []).toJS();
    const allDocumentationAreAccepted = precontractualDocumentation.every(document => document.isAccepted);
    return !allDocumentationAreAccepted
}

function loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

const getChannel = () => {
    if (__CONTACT_CENTER__) {
        return "CC"
    }
    if (isPrintOfficeMode()) {
        return "CC"
    }
    return "WP"
}

function hireParamsFn(contractWizard, immAccount, immDeposits) {
    const depositId = contractWizard.get('renewalOrExpiredDepositId');   
    const deposit = immDeposits.getIn(['byId', depositId]);
    const cardDetails = contractWizard.get('card_details');
    const depositIdentify = contractWizard.get('fromRenewalOrTransferToken')
    const depositProduct = contractWizard.get('deposit_product');
    const depositHireData = contractWizard.get('depositHireData');
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const intervenersData = interveners.map((intervener, index) => ({
        desIntervencion: intervener.get('type'),
        formaIntervencion: intervener.get('interventionWay'),
        nombre: intervener.get('name'),
        numeroPersona: intervener.getIn(['identification', 'code']),
        ordenIntervencion: index,
        tipoIntervencion: intervener.get('typeNumber'),
        tipoPersona: intervener.getIn(['identification', 'type'])
    }));

    return {
        originChannel: getChannel(),
        originalDepositContract: deposit.getIn(['contract', 'contractNumber']),
        originalDepositImposition: deposit.getIn(['impositionList', 'byId']).first().get('id'),
        originalDepositType: deposit.get('depositType'),
        originalDepositSubtype: deposit.get('depositSubType'),
        originalDepositStandard: deposit.get('depositStandard'),
        depositIdentify,

        aa1: depositHireData.get('aa1'),
        aa2: depositHireData.get('aa2'),
        aa3: depositHireData.get('aa3'),
        adelta: depositHireData.get('adelta'),
        campana: DEPOSIT_HIRE_CONFIRMATION_CAMPAIGN,
        canalComercializacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        canalOperacion: DEPOSIT_HIRE_CONFIRMATION_CHANNEL,
        codPeridoLiq: depositHireData.get('codPeridoLiq'),
        cotPeriodo: depositHireData.get('cotPeriodo'),
        cuentaSeleccionada: {
            numerodecontrato: immAccount.get('contractNumber'),
            producto: immAccount.get('product')
        },
        datos: {
            dato: intervenersData
        },
        depositoB: 'S', // "N" if is Welcome Deposit, otherwise "S" //@hardcoded
        descPeriodicidad: depositHireData.get('descPeriodicidad'),
        descPlazo: depositHireData.get('descPlazo'),
        descProducto: depositHireData.get('descProducto'),
        fechaVencimiento: depositHireData.get('fechaVencimiento'),
        gestor: DEPOSIT_HIRE_CONFIRMATION_MANAGER,
        importe,
        importeMax: depositHireData.get('importeMax'),
        importeMin: depositHireData.get('importeMin'),
        indCapit: 'N', // interests capitalization "S" or "N" //@hardcoded
        indRevTo: findElementbyId(cardDetails, 'renewDeposit') ? 'S' : 'N', // Renovation type
        indala01: depositHireData.get('indala01'),
        indala02: depositHireData.get('indala02'),
        indala03: depositHireData.get('indala03'),
        indala04: depositHireData.get('indala04'),
        moneda: depositHireData.get('moneda'),
        numEmp: DEPOSIT_HIRE_CONFIRMATION_EMPLOYEE,
        perLiq: depositHireData.get('perLiq'),
        plazoPos: depositHireData.get('plazoPos'),
        producto: {
            codigodeestandar: depositProduct.get('standard'),
            subtipodeproducto: {
                subtipodeproducto:
                    depositProduct.get('subtype'),
                tipodeproducto: {
                    tipodeproducto: depositProduct.get('type'),
                }
            }
        },
        saldoCuentaSeleccionada: {
            divisa: immAccount.getIn(['balance', 'currency']),
            importe: immAccount.getIn(['balance', 'amount'])
        },
        tasa: depositHireData.get('tasa'),
        tipo: depositProduct.get('type')
    };
}

module.exports = {
    depositexpired_loadAmountInputAmounts,
    depositexpired_nextButtonDisabledStep1: nextButtonDisabledStep1,
    depositexpired_validateStep: validateStep,
    depositexpired_pollingNextButtonDisabled,
    loadTermsAndConditionsLegalWindow,
    depositexpired_hireParamsFn: hireParamsFn,
}
