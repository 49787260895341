const moment = require('moment');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');

const {
    EURO_TEXT,
    HYPHEN,
    SEPA_INTERNATIONAL,
    SEPA_NATIONAL,
    NONE_SEPA,
    API_ENTERPRISE_NUMBER,
    API_CENTER,
    PERIODICITY_MONTHLY,
    PERIODICITY_QUARTERLY,
    PERIODICITY_BIANNUAL,
    PERIODICITY_ANNUAL,
    PERIODICITY_WEEKLY,
    TRANSFER_COST_COMMISION_VALUE
} = require('constants/index');

const {
    TRANSFER_SEPA_NATIONAL_ID,
    TRANSFER_SEPA_NATIONAL_DEFERRED_ID,
    TRANSFER_SEPA_INTERNATIONAL_ID,
    TRANSFER_SEPA_INTERNATIONAL_DEFERRED_ID,
    TRANSFER_NONE_SEPA_ID,
    TRANSFER_NONE_SEPA_DEFERRED_ID,
    TRANSFER_SEPA_NATIONAL_PERIODICITY_ID,
    TRANSFER_IMMEDIATE,
    TRANSFER_SEPA_INTERNATIONAL_PERIODICITY_ID,
    TRANSFER_PERIODIC_EDITION_ID,
    TRANSFER_DEFERRED_EDITION_ID
} = require('constants/operationTypes');

const COUNTRIES_SEPA = require('constants/countriesSepa');

// @ contact-center
const {
    CC_PERIODICITY_DAILY,
    CC_PERIODICITY_WEEKLY,
    CC_PERIODICITY_CUSTOM,
    CC_PERIODICITY_RANGE_SPECIFICATION_DAY,
    CC_FIXED_DATES
} = require('constants/contactCenter/transfers');

const isSepa = (country, currency) => {
    return COUNTRIES_SEPA.indexOf(country) !== -1 && currency === EURO_TEXT;
};

const getSepaTypeTransfers = (country, currency, iban) => {
    if (isSepa(country, currency)) {
        if (iban.slice(0, 2).toUpperCase() === 'ES') {
            return SEPA_NATIONAL;
        } else {
            return SEPA_INTERNATIONAL;
        }
    } else {
        return NONE_SEPA;
    }
};

const getSepaType = (country, currency) => {
    if (isSepa(country, currency)) {
        if (country === 'ES') {
            return SEPA_NATIONAL;
        } else {
            return SEPA_INTERNATIONAL;
        }
    } else {
        return NONE_SEPA;
    }
};

const getPeriodicityText = periodicityCode => {
    switch (periodicityCode) {
        case '100':
            return CC_FIXED_DATES;
        case '107':
            return CC_PERIODICITY_WEEKLY;
        case '101':
            return CC_PERIODICITY_DAILY;
        case '201':
            return PERIODICITY_MONTHLY;
        case '203':
            return PERIODICITY_QUARTERLY;
        case '206':
            return PERIODICITY_BIANNUAL;
        case '301':
            return __CONTACT_CENTER__ ? CC_PERIODICITY_CUSTOM : PERIODICITY_ANNUAL;
        case '200': // fall through
        case '202': // fall through
        case '204': // fall through
        case '205': // fall through
        case '207': // fall through
        case '208': // fall through
        case '209': // fall through
        case '210': // fall through
        case '211': // fall through
        case '213': // fall through
        case '214': // fall through
        case '215': // fall through
        case '216': // fall through
        case '217': // fall through
        case '218': // fall through
        case '219': // fall through
        case '220': // fall through
        case '221': // fall through
        case '222': // fall through
        case '223': // fall through
        case '302': // fall through
        case '225':
            return CC_PERIODICITY_CUSTOM;
        default:
            return '';
    }
};

// The function below returns the appropiate week day depending on the current week day
// EJ: if the current day is tuesday and I selected monday, this function will return the next monday in the calendar
// if the selected week day is after the current week day, this function will return that day
const getInitialWeekDayDate = (transferDate, selectedWeekDay) => {
    const selectedDay = moment(transferDate).day(selectedWeekDay);
    const currentDay = moment();

    if (selectedDay.isBefore(transferDate) && selectedDay.isBefore(currentDay)) {
        return moment(selectedDay).add('1', 'weeks');
    }
    return moment(selectedDay);
};

function getPeriodicityNextValidDate(
    range,
    currentDate,
    selectedWeekDay = currentDate.day(),
    periodicityRangeQuantity = 1,
    periodicityRangeSpecified = CC_PERIODICITY_RANGE_SPECIFICATION_DAY
) {
    let nextDate = moment(currentDate);
    const initWeekDayDate = getInitialWeekDayDate(currentDate, selectedWeekDay);

    switch (range) {
        case CC_PERIODICITY_WEEKLY:
            return initWeekDayDate.add('1', 'weeks');
        case CC_PERIODICITY_DAILY:
            return nextDate.add('1', 'days');
        case PERIODICITY_WEEKLY:
            return nextDate.add('1', 'weeks');
        case PERIODICITY_MONTHLY:
            return nextDate.add('1', 'months');
        case PERIODICITY_QUARTERLY:
            return nextDate.add('3', 'months');
        case PERIODICITY_BIANNUAL:
            return nextDate.add('6', 'months');
        case CC_PERIODICITY_CUSTOM:
            if (periodicityRangeSpecified === CC_PERIODICITY_RANGE_SPECIFICATION_DAY) {
                return nextDate.add(periodicityRangeQuantity.toString(), 'months');
            }
            return nextDate;
    }
}

const getParamsOTP = (sepaType, data, isEditingScheduled) => {
    let type;
    let params = {
        currency: data.currency,
        ammount: data.amount
    };

    if (isEditingScheduled) {
        if (data.periodicityEnabled) {
            type = TRANSFER_PERIODIC_EDITION_ID;
            params.destinationAccount = data.beneficiaryIban;
            params.originAccount = data.iban;
        } else {
            type = TRANSFER_DEFERRED_EDITION_ID;
            params.destinationAccount = data.beneficiaryIban /*SEPA*/ || data.beneficiaryAccountNumber; /*NO SEPA*/
            params.originAccount = data.iban;
        }
    } else {
        switch (sepaType) {
            case SEPA_NATIONAL:
                params.destinationAccount = data.beneficiaryIban;
                if (data.doTransferImmediate) {
                    type = TRANSFER_IMMEDIATE;
                    params.originAccount = API_ENTERPRISE_NUMBER + API_CENTER + data.product + data.contract;
                } else if (data.periodicityEnabled) {
                    type = TRANSFER_SEPA_NATIONAL_PERIODICITY_ID;
                    params.originAccount = API_ENTERPRISE_NUMBER + API_CENTER + data.product + data.contract;
                } else if (data.isToday) {
                    type = TRANSFER_SEPA_NATIONAL_ID;
                    params.originAccount = API_ENTERPRISE_NUMBER + API_CENTER + data.product + data.contract;
                } else {
                    type = TRANSFER_SEPA_NATIONAL_DEFERRED_ID;
                    params.originAccount = data.iban;
                }
                break;
            case SEPA_INTERNATIONAL:
                params.destinationAccount = data.beneficiaryIban;
                if (data.doTransferImmediate) {
                    type = TRANSFER_IMMEDIATE;
                    params.originAccount = API_ENTERPRISE_NUMBER + API_CENTER + data.product + data.contract;
                } else if (data.periodicityEnabled) {
                    type = TRANSFER_SEPA_INTERNATIONAL_PERIODICITY_ID;
                    params.originAccount = API_ENTERPRISE_NUMBER + API_CENTER + data.product + data.contract;
                } else if (data.isToday) {
                    type = TRANSFER_SEPA_INTERNATIONAL_ID;
                    params.originAccount = data.iban;
                } else {
                    type = TRANSFER_SEPA_INTERNATIONAL_DEFERRED_ID;
                    params.originAccount = data.iban;
                }
                break;
            case NONE_SEPA:
                params.originAccount = data.iban;
                params.destinationAccount = data.beneficiaryAccountNumber;
                if (data.isToday) {
                    type = TRANSFER_NONE_SEPA_ID;
                } else {
                    type = TRANSFER_NONE_SEPA_DEFERRED_ID;
                }
                break;
        }
    }

    return {
        type,
        params
    };
};

const parseTransferCosts = costDetails => {
    let costs;
    if (costDetails) {
        const swiftCosts = find(
            get(costDetails, 'liquidaciones.liquidacion', []),
            item => {
                return item.conceptoLiquidacion === '546';
            },
            undefined
        );
        const ownerCosts = find(
            get(costDetails, 'liquidaciones.liquidacion', []),
            item => {
                return item.conceptoLiquidacion === '291';
            },
            undefined
        );
        const beneficiaryCosts = find(
            get(costDetails, 'liquidaciones.liquidacion', []),
            item => {
                return item.conceptoLiquidacion === '292';
            },
            undefined
        );
        const sharedCosts = find(
            get(costDetails, 'liquidaciones.liquidacion', []),
            item => {
                return item.conceptoLiquidacion === '293';
            },
            undefined
        );
        const incompleteInfoCosts = find(
            get(costDetails, 'liquidaciones.liquidacion', []),
            item => {
                return item.conceptoLiquidacion === '359';
            },
            undefined
        );
        const counterValue = get(costDetails, 'importeCargoContravalor.importe', 0);

        costs = {
            nominal: {
                amount: get(costDetails, 'importeNominalOperacion.importe', 0),
                currency: get(costDetails, 'importeNominalOperacion.divisa', EURO_TEXT) || EURO_TEXT
            },
            counterpart: {
                amount: counterValue - counterValue * TRANSFER_COST_COMMISION_VALUE,
                currency: get(costDetails, 'importeCargoContravalor.divisa', EURO_TEXT) || EURO_TEXT
            },
            owner: {
                amount: get(costDetails, 'importeLiquidacionOrdenante.importe', 0),
                currency: get(costDetails, 'importeLiquidacionOrdenante.divisa', EURO_TEXT) || EURO_TEXT
            },
            beneficiary: {
                amount: get(costDetails, 'importeLiquidacionbeneficiario.importe', 0),
                currency: get(costDetails, 'importeLiquidacionbeneficiario.divisa', EURO_TEXT) || EURO_TEXT
            },
            commisionFee: {
                amount: counterValue * TRANSFER_COST_COMMISION_VALUE,
                currency: get(costDetails, 'importeCargoContravalor.divisa', EURO_TEXT) || EURO_TEXT
            }
        };

        if (!!incompleteInfoCosts) {
            costs.incompleteInfoCommission = {
                amount: get(incompleteInfoCosts, 'importeConceptoLiquidacionCompartido.importe', 0),
                currency:
                    get(incompleteInfoCosts, 'importeConceptoLiquidacionCompartido.divisa', EURO_TEXT) || EURO_TEXT
            };
        }

        if (ownerCosts || sharedCosts) {
            const typeCosts = ownerCosts || sharedCosts;

            costs.type = {
                title: typeCosts.descripcionConceptoLiquidacion,
                balance: {
                    amount: get(typeCosts, 'importeConceptoLiquidacionCompartido.importe', 0),
                    currency: get(typeCosts, 'importeConceptoLiquidacionCompartido.divisa', EURO_TEXT) || EURO_TEXT
                }
            };

            costs.swift = {
                amount: get(swiftCosts, 'importeConceptoLiquidacionCompartido.importe', 0),
                currency: get(swiftCosts, 'importeConceptoLiquidacionCompartido.divisa', EURO_TEXT) || EURO_TEXT
            };

            costs.total = {
                amount: get(costDetails, 'importeTotalComisionCompartida.importe', 0),
                currency: get(costDetails, 'importeTotalComisionCompartida.divisa', EURO_TEXT) || EURO_TEXT
            };
        } else if (beneficiaryCosts) {
            costs.type = {
                title: beneficiaryCosts.descripcionConceptoLiquidacion,
                balance: {
                    amount: get(beneficiaryCosts, 'importeConceptoLiquidacionBenefActu.importe', 0),
                    currency:
                        get(beneficiaryCosts, 'importeConceptoLiquidacionBenefActu.divisa', EURO_TEXT) || EURO_TEXT
                }
            };

            costs.swift = {
                amount: get(swiftCosts, 'importeConceptoLiquidacionBenefActu.importe', 0),
                currency: get(swiftCosts, 'importeConceptoLiquidacionBenefActu.divisa', EURO_TEXT) || EURO_TEXT
            };

            costs.total = {
                amount: get(costDetails, 'importeTotalComisionBenefActu.importe', 0),
                currency: get(costDetails, 'importeTotalComisionBenefActu.divisa', EURO_TEXT) || EURO_TEXT
            };
        } else {
            costs.type = {
                title: '',
                balance: {
                    amount: 0,
                    currency: EURO_TEXT
                }
            };

            costs.swift = {
                amount: 0,
                currency: EURO_TEXT
            };

            costs.total = {
                amount: 0,
                currency: EURO_TEXT
            };
        }
    }

    return costs;
};

const getPeriodicityCodeFromMonths = months => {
    switch (months) {
        case 1:
            return '201'; // MENSUAL
        case 2:
            return '202'; // BIMENSUAL
        case 3:
            return '203'; // TRIMESTRAL
        case 4:
            return '204'; // CUATRIMESTRAL
        case 5:
            return '205'; // CADA CINCO MESES
        case 6:
            return '206'; // SEMESTRAL
        case 7:
            return '207'; // CADA SIETE MESES
        case 8:
            return '208'; // CADA OCHO MESES
        case 9:
            return '209'; // CADA NUEVE MESES
        case 10:
            return '210'; // CADA DIEZ MESES
        case 11:
            return '211'; // CADA ONCE MESES
        case 12:
            return '301'; // ANUAL
        case 13:
            return '213'; // CADA TRECE MESES
        case 14:
            return '214'; // CADA CATORCE MESES
        case 15:
            return '215'; // CADA QUINCE MESES
        case 16:
            return '216'; // CADA DIECISEIS MESES
        case 17:
            return '217'; // CADA DIECISIETE MESES
        case 18:
            return '218'; // CADA DIECIOCHO MESES
        case 19:
            return '219'; // CADA DIECINUEVE MESES
        case 20:
            return '220'; // CADA VEINTE MESES
        case 21:
            return '221'; // CADA 21 MESES
        case 22:
            return '222'; // CADA 22 MESES
        case 23:
            return '223'; // CADA 23 MESES
        case 24:
            return '302'; // BIANUAL
        case 25:
            return '225'; // CADA 25 MESES
    }
};

const getMonthsFromPeriodicityCode = periodicityCode => {
    switch (periodicityCode) {
        case '200':
            return 1; // MENSUAL MULTIPLES FECHAS
        case '201':
            return 1; // MENSUAL
        case '202':
            return 2; // BIMENSUAL
        case '203':
            return 3; // TRIMESTRAL
        case '204':
            return 4; // CUATRIMESTRAL
        case '205':
            return 5; // CADA CINCO MESES
        case '206':
            return 6; // SEMESTRAL
        case '207':
            return 7; // CADA SIETE MESES
        case '208':
            return 8; // CADA OCHO MESES
        case '209':
            return 9; // CADA NUEVE MESES
        case '210':
            return 10; // CADA DIEZ MESES
        case '211':
            return 11; // CADA ONCE MESES
        case '301':
            return 12; // ANUAL
        case '213':
            return 13; // CADA TRECE MESES
        case '214':
            return 14; // CADA CATORCE MESES
        case '215':
            return 15; // CADA QUINCE MESES
        case '216':
            return 16; // CADA DIECISEIS MESES
        case '217':
            return 17; // CADA DIECISIETE MESES
        case '218':
            return 18; // CADA DIECIOCHO MESES
        case '219':
            return 19; // CADA DIECINUEVE MESES
        case '220':
            return 20; // CADA VEINTE MESES
        case '221':
            return 21; // CADA 21 MESES
        case '222':
            return 22; // CADA 22 MESES
        case '223':
            return 23; // CADA 23 MESES
        case '302':
            return 24; // BIANUAL
        case '225':
            return 25; // CADA 25 MESES
    }
};

const getCustomPeriodicityArrayFromSelectedMonthDays = immTransfer => {
    return immTransfer.get('customSelectedMonthDays').map(selectedMonthDay => ({
        dia: selectedMonthDay,
        mes: 0,
        tiempo: 1
    }));
};

const getCustomPeriodicityArrayFromDate = immTransfer => {
    return [
        {
            dia: immTransfer.get('date').date(),
            mes: 0,
            tiempo: parseInt(immTransfer.get('periodicityRangeQuantity'), 10)
        }
    ];
};

const getIntervenersNames = (immInterveners, immPayer, immEmitter) => {
    const emitter = `${immEmitter.get('type')}${HYPHEN}${immEmitter.get('code')}`;
    const payer = `${immPayer.get('type')}${HYPHEN}${immPayer.get('code')}`;
    let emitterName = '';
    let payerName = '';

    if (immInterveners.size > 0) {
        immInterveners.forEach(immIntervener => {
            const immIntervenerId = immIntervener.get('identification');
            const intervener = `${immIntervenerId.get('type')}${HYPHEN}${immIntervenerId.get('code')}`;

            if (intervener === emitter) {
                emitterName = immIntervener.get('name');
            }
            if (intervener === payer) {
                payerName = immIntervener.get('name');
            }
            // Once we already have both names we don't have to keep running the foreach
            if (!!payerName && !!emitterName) {
                return;
            }
        });
    }

    return {
        emitterName,
        payerName
    };
};

module.exports = {
    isSepa,
    getIntervenersNames,
    getSepaType,
    getSepaTypeTransfers,
    getParamsOTP,
    getPeriodicityText,
    getInitialWeekDayDate,
    parseTransferCosts,
    getPeriodicityNextValidDate,
    getPeriodicityCodeFromMonths,
    getMonthsFromPeriodicityCode,
    getCustomPeriodicityArrayFromSelectedMonthDays,
    getCustomPeriodicityArrayFromDate
};
