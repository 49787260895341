
// @utilities
const { isAllowedCountry, isAllowedCountryTink } = require('utilities/countryProducts');

// @constants
const {
    ACCIDENTS_INSURANCE,
    ACCOUNT_DEBITS,
    ACCOUNT_INTERVINENTS,
    ACCOUNTS_PORTABILITY_ENABLED,
    ADOBE,
    ADVANCED_ORDER_SPANISH_STOCKS,
    AGENDA,
    AGGREGATOR_NEW_FEATURES,
    AGGREGATOR_PSD2,
    AGGREGATOR,
    AGGREGATOR_CONSENTS,
    ALERTS,
    AMAZON_GIFT_CARD,
    ASNEF,
    ATM_OFFICES,
    BIZUM,
    BLOCK_DEVICE_SECURITY,
    BLOCKED_ACCOUNTS,
    BRING_DIRECT_DEBITS,
    BRING_MY_FUNDS_INFO_VIEW,
    BRING_MY_FUNDS,
    BRING_VALUES_TO_OK,
    BROKER_CAMBIOS_COMERCIALIZADORA,
    BROKERAGE,
    BUILD_YOUR_PORTFOLIO_US_PERSON,
    BUY_ETFS_HELP_LINK,
    BUY_ETFS_SHOW_COST,
    BUY_STOCKS_CRF_INFO,
    BUY_STOCKS_W8_INFO,
    BUY_WARRANTS_SHOW_COST,
    CALLMEBACK_GENESYS,
    CALLMEBACK,
    CCV_HIRING_CHECK_DOCUMENT,
    CHAT,
    CHECK_LEGALES_ENABLED,
    CHECKING_ACCOUNT,
    CHEQUES,
    COMMERCIAL_MOBILE_INSURANCE,
    CONVENIENCE_TEST_LESS_SCORE_WARNING,
    CONVENIENCE_TEST_US_PERSON,
    CREDIT_CARD_REVOLVING_PROCESS,
    CARD_ACTIVATION_GREATER_THAN_ONE_YEAR,
    CREDIT,
    DENIAL_ACCOUNT_HIRING_BLOCKED_ACCOUNT,
    DEPOSITS_INTEREST_BAND_COUNTRIES,
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS_SECOND_RENEWAL,
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS,
    HIRING_MAIN_DEPOSITS_WITH_NEWBALANCE,
    HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE,
    DEPOSIT_INFORMATIVE_WITHOUT_NEW_BALANCE,
    DEPOSIT_RETENTION_EXCLUSIVE_OFFER,
    DEPOSITS_TOPBAR,
    DIRECT_DEBITS,
    DISCLAIMER_MOBILE_INSURANCE,
    DISCOUNTED_MORTGAGE,
    DISPLAY_CONVENIENCE_TEST_QUESTION_ZERO,
    DIVERSIFIED_INVESTMENT_FUNDTRANSFER,
    DIVERSIFIED_INVESTMENT,
    E_CARD,
    EASY_LOAN_WAFFER,
    EVIDENCE_EASY_LOAN,
    ECOMMERCE_INTERNET_PAYMENTS_ADHERED,
    ECOMMERCE_KEY,
    ECOMMERCE_PGBANNER,
    ENABLED_CREDIT_CARD_FILTER,
    ENABLED_R42_CARDS,
    ENROL_DEVICES,
    ES_COUNTRY,
    ESG_PRODUCT,
    ETFS_BUY_US_PERSON,
    ETFS_DETAILS,
    ETFS_LEGAL_DOCUMENTS,
    ETFS_SELL_SHOW_COST,
    EURIBOR,
    EVIDENCE_WEALTH_ROBO_HIRING,
    EVIDENCE_WEALTH_SUITABILITY_TEST,
    FAQ,
    FIOC_HIDING_ACCOUNTS_ENABLED,
    FISCAL_MESSAGE,
    FUNDS_BUY_SHOW_COST,
    FUNDS_BUY_US_PERSON,
    FUNDS_PERIODIC_CONTRIBUTION,
    FUNDS_SHOW_PURCHASE_ANNUAL_REPORT_DOCUMENT,
    FUNDS_SHOW_PURCHASE_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_TRANSFER_ANNUAL_DOCUMENT,
    FUNDS_SHOW_TRANSFER_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_ANNUAL_DOCUMENT,
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_PRESENTATION_DOCUMENT,
    FUNDS_SHOW_RECOMENDATION_REPORT,
    FUNDS_SHOW_RISK_ASSUMPTION_DOCUMENT,
    FUND_GLOSSARY,
    FUNDS_RISK_INDICATOR,
    FUNDS_TRANSFER,
    FUNDS_TRANSFER_SHOW_COSTS,
    FUNDS_TRANSFER_US_PERSON,
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_NATIONAL_FUNDS,
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_INTERNATIONAL_FUNDS,
    FUNDS_SHOW_PRECONTRACTUAL_DOCUMENT_NATIONAL_FUNDS,
    GC,
    HIDE_NEW_DEPOSIT,
    HIDE_VIRTUAL_ADDITIONAL_CARD_CC,
    HIRE_ACCOUNTS_ADD_DOCUMENTATION,
    HIRING_COMBINED_DEPOSITS,
    HIRING_THIRTEEN_DEPOSITS,
    HORMIGUERO_CARD,
    INFO_POPUP_YOU_INVEST,
    INFO_POPUP_ROBOADVISOR,
    INSURANCE_IDD_REGULATION,
    INSURANCE_LANDING_DISCRIMINATION,
    INSURANCE_MODAL_ICON,
    INSURANCE_NO_RELATED_LEGAL_CHECK,
    INSURANCE_USABILITY_IMPROVEMENTS,
    INSURANCES_OBLIGATIONS_DOCUMENT,
    INSURANCES_PERFORMANCE_LIMITS,
    INSURANCES_RECOMMENDATION_FACTORS,
    INSURANCE_RELATED_LEGAL_CHECK,
    INSURANCE_RELATED_OTP_OPTION,
    INSURANCE_RELATED_PRE_APPRAISAL_NOTIFICATION,
    INSURANCE_ADS_CIRCULAR,
    INSURANCE_EXTRA_SERVICE,
    INVESTMENT_PRODUCTS_LIST,
    INVESTMENTS,
    JOIN_HOLDERS,
    LANDING_RISK_INDICATOR,
    LAST_DAYS_CMS_TAG,
    LAVANDA,
    LIMIT_CREDIT_CARD,
    LOAN_CHECK_CIRBE_PERSONAL,
    LOANS_MORTGAGES,
    LOANS_ARES,
    MAESTRO_CARD,
    MANAGEMENT_FEES,
    MARKETING_REPORTS,
    MARKETS_AND_NEWS_FINANACES,
    MARKETS_AND_NEWS,
    MB_CUSTOMER_MANAGEMENT_COUNTRIES,
    METALLIC_CARD,
    METAL_CARD_VALIDATIONS,
    MGM,
    MIFID,
    MIFID_TEST_CONTRACTED_PRODUCTS_COHERENCE_CONTROL,
    MEDYSIF_WARRANT_PROVIDER,
    MODIFY_SUITABILITY_TEST,
    MORTGAGE_CANCEL_OPTION,
    MORTGAGE_CONTRACTED_INSURANCE,
    MORTGAGE_GUIDE,
    MORNINGSTAR_MOST_POPULAR,
    MORTGAGE_PAYMENT_TABLE_V2,
    MORTGAGE_PAYMENT_TABLE,
    MORTGAGE_PRICING_WITHOUT_LTV,
    MORTGAGE_BONUSES,
    MORTGAGE_BONUSES_PAYROLL,
    MORTGAGE_BONUSES_LIFE_INSURANCE,
    MORTGAGE_BONUSES_REPSOL,
    MORTGAGE_BONUSES_HOME_INSURANCE,
    MORTGAGE_BONUSES_WEALTH,
    MORTGAGE_BONUSES_CARDS,
    NERUDA_CARD,
    NEWS,
    HIRING_EIGHTEEN_DEPOSITS,
    HIRING_EIGHTEEN_DEPOSITS_RETENTION,
    HIRING_TWENTYFOUR_DEPOSITS_RETENTION,
    NOT_ALLOWED_OY_DASHBOARD_COUNTRIES,
    NOT_ALLOWED_LOANS_CONTRACTING_ARES_COUNTRIES,
    NOT_ALLOWED_PRODUCT_CONTRACT_TNC_EMAIL_COUNTRIES,
    NOT_ALLOWED_WEALTH_FUNDS_SELL_SHOW_COST_COUNTRIES,
    NOT_ALLOWED_NEW_OVERDRAFT_PROTECTION_FLOW,
    NOT_SELECTED_PRODUCTS_TAB_BROKER,
    NOTIFICATIONS_MANAGEMENT,
    OPEN_BROKERAGE_ACCOUNT_US_PERSON,
    OPEN_CREDIT_CARD_AQUAMARINE_CARDART,
    OPEN_DEBIT_CARD,
    OPEN_YOUNG_PACK,
    OPEN_YOUNG,
    OPENTRADER,
    OPENTRADER_US_PERSON,
    OTP_VITUAL_OFFICE_CHECK,
    OVERDRAFT_LOAN_WAFFER,
    PAY_LATER,
    PAYROLL_ACCOUNT,
    PENSION_PLANS_BRING_TO_OK_US_PERSON,
    PENSION_PLANS_CONTRIBUTION_US_PERSON,
    PENSION_PLANS_HIRE_US_PERSON,
    PENSION_PLANS_PERIODIC_CONTRIBUTION_US_PERSON,
    PENSION_PLANS_TRANSFER_US_PERSON,
    PERSONAL_LOAN_DRAGO,
    PERSONAL_LOAN_WAFFER,
    PERSONETICS_VIRTUAL_OFFICE_CHECK,
    PERSONETICS,
    PFM_NEW_FEATURES,
    PFM,
    PLANS_OPERATIONS_WARNING_MODAL,
    PRE_CONTRACTUAL_EASY_LOAN_MAIL,
    PRE_CONTRACTUAL_NO_RELATED_INSURANCE_MAIL,
    PRE_CONTRACTUAL_OVERDRAFT_PROTECTION_MAIL,
    PRE_CONTRACTUAL_PAYROLL_LOAN_MAIL,
    PRE_CONTRACTUAL_RELATED_INSURANCE_MAIL,
    PRE_CONTRACTUAL_RETAIL_LOAN_MAIL,
    PROVINCE,
    MESSAGE_HIRE_24H,
    REGENERATE_SIGNATURE_KEY_WITH_BIOCATCH,
    R42_BENEFITS_AND_INSURANCE,
    R42_BENEFITS_AND_INSURANCE_ACCESS,
    R42_BENEFITS_AND_INSURANCE_SECTION,
    TRANSFER_ON_SAVINGS_ACCOUNT_CTA,
    TRANSFERS_POPUP_BETWEEN_3K_25K,
    TRANSFERS_POPUP_25K_MORE,
    TRANSFERS_SECURITY_WARNING,
    INTERESTITIAL_LEGAL_MODAL,
    LIFE_INSURANCE_WAFER,
    R42_TRAVEL_PLUS_OFF_HIRING,
    REFERRALS,
    RENEWAL_DEPOSIT_EXTENDED_COUNTRIES,
    RENEWAL_DEPOSIT_ORDER_COUNTRIES,
    RETAIL_LOAN_WAFFER,
    REVOLVING_AFI_MODIFY_LIMIT,
    REVOLVING_INE,
    RIGHTS_BUY_US_PERSON,
    RIGHTS_LINK,
    RIGHTS_SELL_SHOW_COST,
    RIGHTS_SHOW_COST,
    ROBO_CONTRIBUTION_US_PERSON,
    ROBO_GBI_US_PERSON,
    ROBO_HIRE_US_PERSON,
    ROBO_HIRE_WITH_TRANSFER,
    ROBO_PERIODIC_CONTRIBUTION_US_PERSON,
    ROBO_PRECONTRACT,
    ROBO_SHOW_IMPLICIT_COSTS,
    ROBO_ZERO_COMMISSION,
    ROBO,
    SAVINGS,
    SCA_BANNER,
    SECURITIES,
    SELL_ETFS_HELP_LINK,
    SELL_STOCKS_SHOW_COST,
    SENDING_PN_OTP,
    SENDING_SIGNATURE_KEY,
    SETBACKS,
    SIGN_SUITABILITY_TEST_NOT_SUITABLE,
    SHIPPING_ADDRESS_USER,
    SHOW_GLOBAL_POSITION_VENTURE_CAPITAL_FUNDS,
    SHOW_SUITABILITY_TEST_EXPIRATION_NOTICE,
    SOLIDARITY_CARD_CONTRACT,
    SOLIDARITY,
    STOCKS_BUY_US_PERSON,
    STOCK_DETAILS,
    STOCK_SHOW_COST,
    SUIABILITY_TEST_US_PERON,
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST,
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST_COUNTRIES,
    STOCKS_BUY_SHOW_PROFITABILITY,
    TAXATION,
    TOPUP_MOBILE,
    TPPS_ENABLED,
    TRANSFER_SECURITIES_TO_OK_US_PERSON,
    TRAVEL_PLUS_R42,
    TREND_INVESTORS,
    TREND,
    TUTORIAL,
    TYPE_ETFS,
    TYPE_FUNDS,
    TYPE_ALTERNATIVE_FUNDS,
    TYPE_OF_ROAD,
    TYPE_PLANS,
    TYPE_STOCKS,
    TYPE_WARRANTS,
    URL_DISCOUNT,
    VANITY_CARD,
    VIRTUAL_CREDIT_CARD,
    VIRTUAL_DEBIT_CANCER_CARDART,
    VIRTUAL_DEBIT_PRIDE_CARDART,
    WARRANTS_BUY_US_PERSON,
    WARRANTS_DETAILS,
    WARRANTS_SELL_SHOW_COST,
    WEALTH_OTP_PHONE,
    WEALTH_BUILD_PORTFOLIO_SHOW_COSTS,
    WELCOME_ACCOUNT,
    WEALTH_FUNDS_PERIODIC_CONTRIBUTIONS,
    WELCOME_PAYROLL_ACCOUNT,
    YOUR_INVESTMENTS,
    PAYROLL_LOAN_LEGAL_CHECK,
    EASY_LOAN_LEGAL_CHECK,
    OVERDRAFT_PROTECTION_LEGAL_CHECK,
    RETAIL_LOAN_LEGAL_CHECK,
    OPEN_MARKETS_PERSONAL_DATA_CESSION,
    OPEN_MARKETS_PERSONAL_DATA_INTERSTICIAL,
    HIDE_INDIVIDUAL_UPLOAD_DOCUMENTS_HOLDER,
    STANDALONE_RELATED_INSURANCE,
    NOT_ALLOWED_CARDS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_CARDS_SEARCH_BY_TEXT_FILTERS_COUNTRIES,
    NOT_ALLOWED_CARDS_SEARCH_BY_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_ACCOUNTS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_NEW_PG_WEALTH_COUNTRIES,
    NOT_ALLOWED_TINK_AGGREGATOR,
    DALI_OPTION_GAP_ACCESS,
    MB_TRANSFERS_COUNTRIES,
    MB_ACCOUNTS_COUNTRIES,
    NEW_PLASTIC_MAESTRO,
    NOT_ALLOWED_NEW_PREAPPROVAL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_PREAPROVED_CARDS_WAFFER_COUNTRIES,
    NOT_ALLOWED_NEW_EASY_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_EVIDENCE_EASY_LOAN_MODELC,
    NOT_ALLOWED_EVIDENCE_CIRBE_MORTGAGE_COUNTRIES,
    NOT_ALLOWED_NEW_RETAIL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_NEW_CREDIT_CARDS_FLOW_COUNTRIES,
    NOT_ALLOWED_EVIDENCE_PARTIAL_LOAN_REPAYMENT_COUNTRIES
} = require('constants/flagBasedFeatures');

const { COUNTRIES_CODES: { ES } } = require('constants/countries');

const {
    TYPE_STOCKS: STOCKS,
    TYPE_PLANS: PLANS,
    TYPE_WARRANTS: WARRANTS,
    TYPE_FUNDS: FUNDS,
    TYPE_ETFS: ETFS,
    TYPE_ALTERNATIVE_FUNDS_PG
} = require('constants/index');

const SIMPLE_COUNTRY_FLAGS = [
    ACCIDENTS_INSURANCE,
    ACCOUNT_DEBITS,
    ACCOUNT_INTERVINENTS,
    ACCOUNTS_PORTABILITY_ENABLED,
    ADOBE,
    ADVANCED_ORDER_SPANISH_STOCKS,
    ADVANCED_ORDER_SPANISH_STOCKS,
    AGENDA,
    AGGREGATOR_NEW_FEATURES,
    AGGREGATOR_PSD2,
    AGGREGATOR_CONSENTS,
    ALERTS,
    AMAZON_GIFT_CARD,
    ASNEF,
    ATM_OFFICES,
    BIZUM,
    BLOCK_DEVICE_SECURITY,
    BLOCKED_ACCOUNTS,
    BRING_DIRECT_DEBITS,
    BRING_MY_FUNDS_INFO_VIEW,
    BRING_MY_FUNDS,
    BRING_VALUES_TO_OK,
    BROKER_CAMBIOS_COMERCIALIZADORA,
    BROKERAGE,
    BUILD_YOUR_PORTFOLIO_US_PERSON,
    BUY_ETFS_HELP_LINK,
    BUY_ETFS_SHOW_COST,
    BUY_STOCKS_CRF_INFO,
    BUY_STOCKS_W8_INFO,
    BUY_WARRANTS_SHOW_COST,
    CALLMEBACK_GENESYS,
    CALLMEBACK,
    CCV_HIRING_CHECK_DOCUMENT,
    CHAT,
    CHECKING_ACCOUNT,
    CHEQUES,
    COMMERCIAL_MOBILE_INSURANCE,
    CONVENIENCE_TEST_LESS_SCORE_WARNING,
    CONVENIENCE_TEST_US_PERSON,
    CREDIT_CARD_REVOLVING_PROCESS,
    CARD_ACTIVATION_GREATER_THAN_ONE_YEAR,
    CREDIT,
    DENIAL_ACCOUNT_HIRING_BLOCKED_ACCOUNT,
    DEPOSITS_INTEREST_BAND_COUNTRIES,
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS_SECOND_RENEWAL,
    DEPOSIT_THIRTEEN_MONTHS_EXCEPTIONS,
    DEPOSITS_TOPBAR,
    HIRING_MAIN_DEPOSITS_WITH_NEWBALANCE,
    HIRING_MAIN_DEPOSITS_WITHOUT_NEWBALANCE,
    DEPOSIT_RETENTION_EXCLUSIVE_OFFER,
    DEPOSIT_INFORMATIVE_WITHOUT_NEW_BALANCE,
    DIRECT_DEBITS,
    DISCLAIMER_MOBILE_INSURANCE,
    DISCOUNTED_MORTGAGE,
    DIVERSIFIED_INVESTMENT,
    DISPLAY_CONVENIENCE_TEST_QUESTION_ZERO,
    E_CARD,
    E_CARD,
    EASY_LOAN_WAFFER,
    EVIDENCE_EASY_LOAN,
    ECOMMERCE_INTERNET_PAYMENTS_ADHERED,
    ECOMMERCE_KEY,
    ECOMMERCE_PGBANNER,
    ENABLED_CREDIT_CARD_FILTER,
    ENABLED_R42_CARDS,
    ENROL_DEVICES,
    ESG_PRODUCT,
    ETFS_BUY_US_PERSON,
    ETFS_DETAILS,
    ETFS_LEGAL_DOCUMENTS,
    ETFS_SELL_SHOW_COST,
    EURIBOR,
    EVIDENCE_WEALTH_ROBO_HIRING,
    EVIDENCE_WEALTH_SUITABILITY_TEST,
    FAQ,
    FIOC_HIDING_ACCOUNTS_ENABLED,
    FISCAL_MESSAGE,
    FUNDS_BUY_SHOW_COST,
    FUNDS_BUY_US_PERSON,
    FUNDS_PERIODIC_CONTRIBUTION,
    FUNDS_SHOW_PURCHASE_ANNUAL_REPORT_DOCUMENT,
    FUNDS_SHOW_PURCHASE_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_TRANSFER_ANNUAL_DOCUMENT,
    FUNDS_SHOW_TRANSFER_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_ANNUAL_DOCUMENT,
    FUNDS_SHOW_PORTFOLIO_MODEL_REPORT_PROSPECTUS_DOCUMENT,
    FUNDS_SHOW_PRESENTATION_DOCUMENT,
    FUNDS_SHOW_RECOMENDATION_REPORT,
    FUNDS_SHOW_RISK_ASSUMPTION_DOCUMENT,
    FUND_GLOSSARY,
    FUNDS_RISK_INDICATOR,
    FUNDS_TRANSFER,
    FUNDS_TRANSFER_SHOW_COSTS,
    FUNDS_TRANSFER_US_PERSON,
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_NATIONAL_FUNDS,
    FUNDS_SHOW_PROSPECTUS_DOCUMENT_INTERNATIONAL_FUNDS,
    FUNDS_SHOW_PRECONTRACTUAL_DOCUMENT_NATIONAL_FUNDS,
    GC,
    HIDE_NEW_DEPOSIT,
    HIDE_VIRTUAL_ADDITIONAL_CARD_CC,
    HIRE_ACCOUNTS_ADD_DOCUMENTATION,
    HIRING_COMBINED_DEPOSITS,
    HIRING_THIRTEEN_DEPOSITS,
    HORMIGUERO_CARD,
    INFO_POPUP_YOU_INVEST,
    INFO_POPUP_ROBOADVISOR,
    INSURANCE_IDD_REGULATION,
    INSURANCE_LANDING_DISCRIMINATION,
    INSURANCE_MODAL_ICON,
    INSURANCE_NO_RELATED_LEGAL_CHECK,
    INSURANCE_USABILITY_IMPROVEMENTS,
    INSURANCES_OBLIGATIONS_DOCUMENT,
    INSURANCES_PERFORMANCE_LIMITS,
    INSURANCES_RECOMMENDATION_FACTORS,
    INSURANCE_RELATED_LEGAL_CHECK,
    INSURANCE_RELATED_OTP_OPTION,
    INSURANCE_RELATED_PRE_APPRAISAL_NOTIFICATION,
    INSURANCE_ADS_CIRCULAR,
    INSURANCE_EXTRA_SERVICE,
    INVESTMENTS,
    JOIN_HOLDERS,
    LANDING_RISK_INDICATOR,
    LAST_DAYS_CMS_TAG,
    LAVANDA,
    LIMIT_CREDIT_CARD,
    LOAN_CHECK_CIRBE_PERSONAL,
    LOAN_CHECK_CIRBE_PERSONAL,
    LOANS_MORTGAGES,
    LOANS_ARES,
    MAESTRO_CARD,
    MANAGEMENT_FEES,
    MARKETING_REPORTS,
    MARKETS_AND_NEWS_FINANACES,
    MARKETS_AND_NEWS,
    MB_CUSTOMER_MANAGEMENT_COUNTRIES,
    METALLIC_CARD,
    METAL_CARD_VALIDATIONS,
    MGM,
    MIFID,
    MIFID_TEST_CONTRACTED_PRODUCTS_COHERENCE_CONTROL,
    MEDYSIF_WARRANT_PROVIDER,
    MODIFY_SUITABILITY_TEST,
    MORTGAGE_CANCEL_OPTION,
    MORTGAGE_CONTRACTED_INSURANCE,
    MORTGAGE_GUIDE,
    MORNINGSTAR_MOST_POPULAR,
    MORTGAGE_PAYMENT_TABLE_V2,
    MORTGAGE_PAYMENT_TABLE,
    MORTGAGE_PRICING_WITHOUT_LTV,
    MORTGAGE_BONUSES,
    MORTGAGE_BONUSES_PAYROLL,
    MORTGAGE_BONUSES_LIFE_INSURANCE,
    MORTGAGE_BONUSES_REPSOL,
    MORTGAGE_BONUSES_HOME_INSURANCE,
    MORTGAGE_BONUSES_WEALTH,
    MORTGAGE_BONUSES_CARDS,
    NERUDA_CARD,
    NEWS,
    HIRING_EIGHTEEN_DEPOSITS,
    HIRING_EIGHTEEN_DEPOSITS_RETENTION,
    HIRING_TWENTYFOUR_DEPOSITS_RETENTION,
    NOT_ALLOWED_LOANS_CONTRACTING_ARES_COUNTRIES,
    NOT_ALLOWED_OY_DASHBOARD_COUNTRIES,
    NOT_ALLOWED_PRODUCT_CONTRACT_TNC_EMAIL_COUNTRIES,
    NOT_ALLOWED_WEALTH_FUNDS_SELL_SHOW_COST_COUNTRIES,
    NOT_ALLOWED_NEW_OVERDRAFT_PROTECTION_FLOW,
    NOTIFICATIONS_MANAGEMENT,
    OPEN_BROKERAGE_ACCOUNT_US_PERSON,
    OPEN_CREDIT_CARD_AQUAMARINE_CARDART,
    OPEN_DEBIT_CARD,
    OPEN_YOUNG_PACK,
    OPEN_YOUNG,
    OPENTRADER,
    OPENTRADER_US_PERSON,
    OTP_VITUAL_OFFICE_CHECK,
    OVERDRAFT_LOAN_WAFFER,
    PAY_LATER,
    PAYROLL_ACCOUNT,
    PENSION_PLANS_BRING_TO_OK_US_PERSON,
    PENSION_PLANS_CONTRIBUTION_US_PERSON,
    PENSION_PLANS_HIRE_US_PERSON,
    PENSION_PLANS_PERIODIC_CONTRIBUTION_US_PERSON,
    PENSION_PLANS_TRANSFER_US_PERSON,
    PERSONAL_LOAN_DRAGO,
    PERSONAL_LOAN_WAFFER,
    PERSONETICS_VIRTUAL_OFFICE_CHECK,
    PERSONETICS,
    PFM_NEW_FEATURES,
    PFM,
    PLANS_OPERATIONS_WARNING_MODAL,
    PRE_CONTRACTUAL_EASY_LOAN_MAIL,
    PRE_CONTRACTUAL_EASY_LOAN_MAIL,
    PRE_CONTRACTUAL_NO_RELATED_INSURANCE_MAIL,
    PRE_CONTRACTUAL_OVERDRAFT_PROTECTION_MAIL,
    PRE_CONTRACTUAL_PAYROLL_LOAN_MAIL,
    PRE_CONTRACTUAL_PAYROLL_LOAN_MAIL,
    PRE_CONTRACTUAL_RELATED_INSURANCE_MAIL,
    PRE_CONTRACTUAL_RETAIL_LOAN_MAIL,
    MESSAGE_HIRE_24H,
    REGENERATE_SIGNATURE_KEY_WITH_BIOCATCH,
    R42_BENEFITS_AND_INSURANCE,
    R42_BENEFITS_AND_INSURANCE_ACCESS,
    R42_BENEFITS_AND_INSURANCE_SECTION,
    TRANSFER_ON_SAVINGS_ACCOUNT_CTA,
    TRANSFERS_POPUP_BETWEEN_3K_25K,
    TRANSFERS_SECURITY_WARNING,
    TRANSFERS_POPUP_25K_MORE,
    INTERESTITIAL_LEGAL_MODAL,
    LIFE_INSURANCE_WAFER,
    R42_TRAVEL_PLUS_OFF_HIRING,
    REFERRALS,
    RENEWAL_DEPOSIT_EXTENDED_COUNTRIES,
    RENEWAL_DEPOSIT_ORDER_COUNTRIES,
    RETAIL_LOAN_WAFFER,
    REVOLVING_AFI_MODIFY_LIMIT,
    RIGHTS_BUY_US_PERSON,
    RIGHTS_LINK,
    REVOLVING_INE,
    RIGHTS_LINK,
    RIGHTS_SELL_SHOW_COST,
    RIGHTS_SHOW_COST,
    ROBO_CONTRIBUTION_US_PERSON,
    ROBO_GBI_US_PERSON,
    ROBO_HIRE_US_PERSON,
    ROBO_HIRE_WITH_TRANSFER,
    ROBO_PERIODIC_CONTRIBUTION_US_PERSON,
    ROBO_PRECONTRACT,
    ROBO_SHOW_IMPLICIT_COSTS,
    ROBO_ZERO_COMMISSION,
    ROBO,
    SAVINGS,
    SCA_BANNER,
    SECURITIES,
    SELL_ETFS_HELP_LINK,
    SELL_STOCKS_SHOW_COST,
    SENDING_PN_OTP,
    SENDING_SIGNATURE_KEY,
    SETBACKS,
    SHIPPING_ADDRESS_USER,
    SHOW_GLOBAL_POSITION_VENTURE_CAPITAL_FUNDS,
    SHOW_SUITABILITY_TEST_EXPIRATION_NOTICE,
    SIGN_SUITABILITY_TEST_NOT_SUITABLE,
    SOLIDARITY_CARD_CONTRACT,
    SOLIDARITY,
    STOCKS_BUY_US_PERSON,
    STOCK_DETAILS,
    STOCK_SHOW_COST,
    SUIABILITY_TEST_US_PERON,
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST,
    SUSTAINABILITY_QUESTIONS_SUITABILITY_TEST_COUNTRIES,
    STOCKS_BUY_SHOW_PROFITABILITY,
    TAXATION,
    TOPUP_MOBILE,
    TRANSFER_SECURITIES_TO_OK_US_PERSON,
    TRAVEL_PLUS_R42,
    TREND_INVESTORS,
    TREND,
    TUTORIAL,
    TYPE_ETFS,
    TYPE_FUNDS,
    TYPE_PLANS,
    TYPE_ALTERNATIVE_FUNDS,
    TYPE_STOCKS,
    TYPE_WARRANTS,
    URL_DISCOUNT,
    VANITY_CARD,
    VIRTUAL_CREDIT_CARD,
    VIRTUAL_DEBIT_CANCER_CARDART,
    VIRTUAL_DEBIT_PRIDE_CARDART,
    WARRANTS_BUY_US_PERSON,
    WARRANTS_DETAILS,
    WARRANTS_SELL_SHOW_COST,
    WEALTH_OTP_PHONE,
    WEALTH_BUILD_PORTFOLIO_SHOW_COSTS,
    WEALTH_FUNDS_PERIODIC_CONTRIBUTIONS,
    WELCOME_ACCOUNT,
    WELCOME_PAYROLL_ACCOUNT,
    YOUR_INVESTMENTS,
    PAYROLL_LOAN_LEGAL_CHECK,
    EASY_LOAN_LEGAL_CHECK,
    OVERDRAFT_PROTECTION_LEGAL_CHECK,
    RETAIL_LOAN_LEGAL_CHECK,
    OPEN_MARKETS_PERSONAL_DATA_CESSION,
    OPEN_MARKETS_PERSONAL_DATA_INTERSTICIAL,
    HIDE_INDIVIDUAL_UPLOAD_DOCUMENTS_HOLDER,
    STANDALONE_RELATED_INSURANCE,
    NOT_ALLOWED_CARDS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_CARDS_SEARCH_BY_TEXT_FILTERS_COUNTRIES,
    NOT_ALLOWED_CARDS_SEARCH_BY_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_ACCOUNTS_SEARCH_AND_AMOUNT_FILTERS_COUNTRIES,
    NOT_ALLOWED_NEW_PG_WEALTH_COUNTRIES,
    DALI_OPTION_GAP_ACCESS,
    MB_TRANSFERS_COUNTRIES,
    MB_ACCOUNTS_COUNTRIES,
    NEW_PLASTIC_MAESTRO,
    NOT_ALLOWED_NEW_PREAPPROVAL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_NEW_EASY_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_EVIDENCE_EASY_LOAN_MODELC,
    NOT_ALLOWED_PREAPROVED_CARDS_WAFFER_COUNTRIES,
    NOT_ALLOWED_EVIDENCE_CIRBE_MORTGAGE_COUNTRIES,
    NOT_ALLOWED_NEW_RETAIL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_NEW_PERSONAL_LOAN_FLOW_COUNTRIES,
    NOT_ALLOWED_NEW_CREDIT_CARDS_FLOW_COUNTRIES,
    NOT_ALLOWED_EVIDENCE_PARTIAL_LOAN_REPAYMENT_COUNTRIES
];


const createCountriesSimpleExtraConfig = (flags, originCountry) => {
    return SIMPLE_COUNTRY_FLAGS.reduce((acc, curr) => {
        return {
            ...acc,
            [curr]: isAllowedCountry(flags[`not_allowed_${curr}_countries`], originCountry)
        };
    }, {});
}

const aggregatorFeatureToggling = (flags, originCountry) => {
    return Object.keys(flags).reduce((acc, current) => {
        return acc = {
            ...acc,
            ...(/^agregador-/.test(current)) ? {
                [current]: isAllowedCountry(flags.not_allowed_aggregator_countries, originCountry)
            } : {}
        }
    }, {})
}

// NOTE: Do not include elements here if they have been included in the SIMPLE_COUNTRY_FLAGS and the structure is: 'not_allowed_[name]_countries,'
const createCountriesExtraConfig = (flags, originCountry) => {
    const aggregatorFlags = aggregatorFeatureToggling(flags, originCountry);
    const isEsCountry = originCountry.toUpperCase() == ES;

    return {
        ...flags,
        ...aggregatorFlags,
        ...createCountriesSimpleExtraConfig(flags, originCountry),
        [AGGREGATOR]: Object.values(aggregatorFlags).some(flag => flag),
        [DIVERSIFIED_INVESTMENT_FUNDTRANSFER]: isAllowedCountry(flags.not_allowed_diversified_investment_fundtransfer, originCountry),
        [ENABLED_R42_CARDS]: isAllowedCountry(flags.not_enabled_r42_cards_countries, originCountry),
        [ES_COUNTRY]: isEsCountry,
        [INVESTMENT_PRODUCTS_LIST]: [
            ...isAllowedCountry(flags.not_allowed_type_funds_countries, originCountry) ? FUNDS : [],
            ...isAllowedCountry(flags.not_allowed_type_stocks_countries, originCountry) ? STOCKS : [],
            ...isAllowedCountry(flags.TYPE_ALTERNATIVE_FUNDS, originCountry) ? TYPE_ALTERNATIVE_FUNDS_PG : [],
            ...isAllowedCountry(flags.not_allowed_type_etfs_countries, originCountry) ? ETFS : [],
            ...isAllowedCountry(flags.not_allowed_type_plans_countries, originCountry) ? PLANS : [],
            ...isAllowedCountry(flags.not_allowed_type_warrants_countries, originCountry) ? WARRANTS : []
        ],
        [CHECK_LEGALES_ENABLED]: isAllowedCountry(flags.not_allowed_check_legales_countries, originCountry),
        [NOT_SELECTED_PRODUCTS_TAB_BROKER]: isAllowedCountry(flags.not_selected_products_tab_countries, originCountry),
        [PROVINCE]: isEsCountry,
        [TPPS_ENABLED]: isAllowedCountry(flags.not_allowed_tpps, originCountry),
        [TYPE_OF_ROAD]: isEsCountry,
        [NOT_ALLOWED_TINK_AGGREGATOR]: isAllowedCountryTink(flags.not_allowed_tink_aggregator_countries, originCountry)
    }
}

const createConfig = (flagsObject, originCountry) => {
    for (let flag in flagsObject) {
        if (typeof flagsObject[flag] === 'string') {
            if (flagsObject[flag] === 'true' || flagsObject[flag] === 'false') {
                flagsObject[flag] = flagsObject[flag] === 'true';
            } else if (/^\[(.+)?\]$/.test(flagsObject[flag])) {
                flagsObject[flag] = flagsObject[flag].replace(/\[|\]/g, "").split(',');
            }
        }
    }
    if (originCountry) {
        return createCountriesExtraConfig(flagsObject, originCountry);
    }
    return flagsObject;
}

export { createConfig };
