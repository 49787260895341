// @ vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const moment = require('moment');
const trim = require('lodash/string/trim');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
// @ reducers
const reimburseInvestmentStep1 = require('./reimburseInvestment/reimburseInvestmentStep1');
const reimburseInvestmentStep2 = require('./reimburseInvestment/reimburseInvestmentStep2');


function setInitialState() {
    return Immutable.Map().merge({
        steps: [
            reimburseInvestmentStep1(undefined, { type: null }),
            reimburseInvestmentStep2(undefined, { type: null })
        ],
        reimburseFunds: Immutable.fromJS({}),
        errorReceived: '',
        isFetching: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        notFound: false,
        interveners: [],
        newOrderError: false,
        medysifError: false,
        exAnteCostList: [],
        exAnteCostListFetching: false,
        storeDocuments: false,
    });
}

function updateStep1(steps, action) {
    return steps.update(FIRST_STEP, step => {
        return reimburseInvestmentStep1(step, action);
    });
}

function updateStep2(steps, action) {
    return steps.update(SECOND_STEP, step => {
        return reimburseInvestmentStep2(step, action);
    });
}

function pad(num, size) {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
}

function parseInterveners(interveners) {
    return interveners.map(intervener => {
        return {
            name: trim(intervener.nombre),
            type: trim(intervener.desIntervencion),
            interventionType: trim(intervener.tipoIntervencion),
            interventionForm: trim(intervener.formaIntervencion),
            documentNumber: trim(intervener.numDocumento),
            documentType: trim(intervener.tipoDocumento),
            personNumber: trim(intervener.numPersona),
            personType: trim(intervener.tipPers)
        };
    });
}

const reimburseInvestment = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    switch (action.type) {
        case actionTypes.FETCH_BROKER_MORNINGSTAR_SCREENER_REQUEST:
            return state.merge({
                isFetching: true
            });

        case actionTypes.FETCH_BROKER_REIMBURSE_NEW_ORDER_SUCCESS:
            const reimburseOrder = action.payload.reimburseOrder;
            const productNumber = action.payload.productNumber;
            const contractNumber = action.payload.contract;
            const account = action.payload.account[Object.keys(action.payload.account)[0]];
            const fundControlDigit = action.payload.fundControlDigit;
            const totalAmount = action.payload.totalAmount;
            const hasActiveContribution = action.payload.hasActiveContribution;

            return state.mergeDeepIn(['steps', 0], {
                amount: totalAmount,
                reimburseFund: {
                    codbban: reimburseOrder.cuentaDomi.codbban.trim(),
                    controlDigit: reimburseOrder.cuentaDomi.codbban.trim().substring(8, 10),
                    fundControlDigit: fundControlDigit,
                    accountNumber: reimburseOrder.cuentaDomi.codbban.trim().substring(10, 20),
                    currencyFund: reimburseOrder.codMomCuenta,
                    ProductSubTypeCode: reimburseOrder.codSubtipoProduc,
                    netAssetValueDate: reimburseOrder.fechValLiquidativo,
                    fundBalance: reimburseOrder.saldoParticipaciones,
                    fundTotalBalance: totalAmount,
                    lastNetAssetValue: {
                        amount: reimburseOrder.importeValorLiquidativo.valorliquidativo,
                        currency: reimburseOrder.importeValorLiquidativo.divisa
                    },
                    country: reimburseOrder.cuentaDomi.pais,
                    contractNumber: contractNumber,
                    productNumber: productNumber,
                    holderName: reimburseOrder.nomTitular,
                    fundParticipations: reimburseOrder.saldoDispParticipa,
                    account,
                    hasActiveContribution
                }
            });

        case actionTypes.FETCH_BROKER_MORNINGSTAR_SCREENER_SUCCESS:
            const reimburseInvestment = action.payload.reimburseInvestment.rows[0];
            const securityDetails = action.payload.securityDetails[0];
            const type = reimburseInvestment.CustomInstitutionSecurityId.substring(0, 3);
            const subtype = reimburseInvestment.CustomInstitutionSecurityId.substring(4, 7);
            const reference = pad(
                reimburseInvestment.CustomInstitutionSecurityId.substring(8, 9),
                7
            );
            const trailingPerformanceLength = securityDetails.TrailingPerformance.length;
            const variationPoints =
                parseFloat(
                    securityDetails.TrailingPerformance[trailingPerformanceLength - 1].Return[0]
                        .Value
                ) *
                parseFloat(
                    securityDetails.TrailingPerformance[trailingPerformanceLength - 1].Return[1]
                        .Value
                );
            const variationPercent =
                securityDetails.TrailingPerformance[trailingPerformanceLength - 1].Return[1].Value;
            partialSteps = updateStep1(state.get('steps'), action);

            return state.merge({
                steps: partialSteps,
                error: '',
                notFound: false,
                isFetching: false
            }).mergeDeepIn(['steps', FIRST_STEP], {
                reimburseInvestment: {
                    name:reimburseInvestment.Name,
                    fundId: reimburseInvestment.SecId,
                    isin: reimburseInvestment.ISIN,
                    customMinimumPurchaseAmount: {
                        amount: reimburseInvestment.CustomMinimumPurchaseAmount,
                        currency: securityDetails.Currency.Id
                    },
                    netAssetValue: {
                        dayEndDate: securityDetails.TrailingPerformance[0].Date,
                        currency: securityDetails.Currency.Id,
                        amount: securityDetails.TrailingPerformance[0].Return[0].Value
                    },
                    rentability: reimburseInvestment.ReturnM0 || 0,
                    holding: {
                        numberOfHoldings: 150,
                        currencySymbol: 'EUR',
                        amount: 1250 // @hardcoded value
                    },
                    variationPoints: variationPoints,
                    variationPercent: variationPercent,
                    type,
                    subtype,
                    reference
                }
            })
        case actionTypes.FETCH_BROKER_REIMBURSE_INVESTMENT_FAILURE:
            return state.merge({
                error: action.payload.error,
                medysifError: true,
            });
        case actionTypes.FETCH_BROKER_REIMBURSE_NEW_ORDER_FAILURE:
            return state.merge({
                error: action.payload.error,
                newOrderError: true,
            });
        case actionTypes.FETCH_BROKER_MORNINGSTAR_SCREENER_FAILURE:
            return state.merge({
                error: action.payload.error,
                notFound: false,
                isFetching: false
            });

        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_WILL_CANCEL:
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_WILL_LEAVE:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_VALIDATE_STEP:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = 2;
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_SET_AMOUNT:
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_SET_TYPE:
            partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.FETCH_BROKER_REIMBURSE_INVESTMENT_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_BROKER_REIMBURSE_INVESTMENT_NOT_FOUND:
            return state.merge({
                error: action.payload.error,
                notFound: true,
                isFetching: false
            });
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_POST_SUCCESS:
            return state.merge({
                submitSuccessful: true,
                isFetching: false,
                emissionDate: moment(parseInt(get(action, 'payload.data.timestamp', '')))
            })
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_RESET:
            return setInitialState();
        case actionTypes.FETCH_BROKER_GET_INTERVENERS_SUCCESS: {
            const nextInterveners = parseInterveners(action.payload.data.intervinientes.dato);
            return state.merge({
                interveners: Immutable.fromJS(nextInterveners)
            });
        }
        case actionTypes.FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_REQUEST:
            return state.merge({
                exAnteCostListFetching: true,
            })
        case actionTypes.FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_FAILURE:
            return state.merge({
                exAnteCostList: [],
                exAnteCostListFetching: false,
            })
        case actionTypes.FETCH_BROKER_REIMBURSE_EX_ANTE_DETAILS_SUCCESS:
            return state.merge({
                exAnteCostList: action.payload.exAntDetails,
                exAnteCostListFetching: false,
            })
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_REQUEST:
            return state.merge({
                storeDocuments: false
            })
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_SUCCESS:
            return state.merge({
                storeDocuments: true
            })
        case actionTypes.BROKER_WIZARD_REIMBURSE_FUND_DOCUMENT_STORAGE_ERROR:
            return state.merge({
                storeDocuments: false
            })
        default:
            return state;
    }
};

module.exports = reimburseInvestment;
