const brokerAlternativeFundsTags = (eventTag, eventAction, brokerType) => ({
  searchFundButton: {
    categoriaEvento: '/alternativeFunds/landing',
    accionEvento: 'clickButton',
    etiquetaEvento: 'searchProduct',
  },
  buyFundButton: {
    categoriaEvento: '/alternativeFunds/landing',
    accionEvento: 'clickButton',
    etiquetaEvento: 'comprar',
  },
  productsTableAccordion: {
    categoriaEvento: '/alternativeFunds/landing',
    accionEvento: 'clickDropdown',
    etiquetaEvento: eventTag,
  },
  productsTableAccordionNewTag: {
    categoriaEvento: '/alternativeFunds/landing',
    accionEvento: 'clickDropdown',
    etiquetaEvento: eventAction,
},
  informationFooterLink: {
    categoriaEvento: '/alternativeFunds/landing',
    accionEvento: 'clickLink',
    etiquetaEvento: 'discoverAlternativeFunds',
  },
  successPage:{
    event_name: "event_success",
    action: "success",
    format: "steps",
    component: "funnel",
    element: "termsConditions"
  }
});

module.exports = { brokerAlternativeFundsTags };
