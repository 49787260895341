// @vendors
const Immutable = require('immutable');
const get = require('lodash/object/get');
const trim = require('lodash/string/trim');
const firstIndex = require('lodash/array/findIndex');
const findLastIndex = require('lodash/array/findLastIndex');

//@utilities
const { hash } = require('utilities/hash');
const { formatText } = require('core/i18n').i18n;

// @constants
const { BROKER_PRODUCT_TYPE_PLANS,
    BROKER_PRODUCT_TYPE_FUNDS,
    BROKER_PRODUCT_TYPE_ACCIONES,
    BROKER_PRODUCT_TYPE_DERECHOS,
    BROKER_PRODUCT_TYPE_STOCKS_WITH_RIGHTS,
    BROKER_PRODUCT_TYPE_ASSOCIATED,
    BROKER_PRODUCT_TYPE_STANDALONE
} = require('constants/index');

/**
 *
 * @param { any } value
 * @returns { number | null }
 */
function numberOrNull(value) {
    const valueNumber = Number(value);

    if (value == null || isNaN(valueNumber)) {
        return null;
    }

    return valueNumber;
}

function getStockContractWithControlDigit(stock) {
    return trim(get(stock, 'fullContratoDigitoControl'));
}

/**
 *
 * @param { Object } stock
 * @returns { String }
 */
function getStockId(stock) {
    // (franco.montenegro) If changed, please update brokerCustomWalletInvestments
    // as well until better implementation
    if (!stock) {
        return '';
    }

    const product = get(stock, 'contrato.producto');
    const productSubType = get(stock, 'subtipoProducto');
    const contractNumber = trim(get(stock, 'contrato.numeroContrato', ''));

    return hash([
        `${product}`,
        `${productSubType}`,
        `${contractNumber}`
    ]);
}

/**
 *
 * @param { Object } stock
 * @returns { String }
 */
function getStockCustomInstitutionSecurityId(stock) {
    const product = get(stock, 'contrato.producto');
    const productSubType = get(stock, 'subtipoProducto');

    return `${product}-${productSubType}-1`; // (franco.montenegro) Should come from API
}

/**
 * Get unconsolidated operations data (also known as "intradia").
 * "Intradia" operations are those operations that are executed but not
 * consolidated in the invested capital / profitability (there is a
 * synchronization gap between the core banking and the provider of this data)
 *
 * @param { Object } stock
 * @returns Object
 */
function getUnconsolidatedOperationsData(stock) {
    const intradia = get(stock, 'importes.intradia');
    const totalValue = numberOrNull(get(intradia, 'valor.importeDivisaValor.importe'));
    const totalValueCurrency = trim(get(intradia, 'valor.importeDivisaValor.moneda')) || null;
    const totalValueEur = numberOrNull(get(intradia, 'valor.importeEur.importe'));
    const totalValueEurCurrency = trim(get(intradia, 'valor.importeEur.moneda')) || null;
    const shares = ['PLANES', 'FONDOS', 'FUTUROS'].indexOf(stock.tipoValor) !== -1 ? numberOrNull(get(intradia, 'participaciones')) : numberOrNull(get(intradia, 'titulos'));

    // This condition is just for non production environments, since others
    // environments can contain malformed data.
    // We'll consider "valid" data if any of these has a truthy value.
    // (`undefined`, `null` and `0` are considered falsy).
    const hasValidIntradiaValues = !!totalValue || !!totalValueEur || !!shares;

    return {
        hasValidIntradiaValues: hasValidIntradiaValues,
        hasUnconsolidatedOperations: intradia != null,
        shares: shares,
        totalValue: totalValue == null ? totalValueEur : totalValue,
        totalValueCurrency: totalValueCurrency == null ? totalValueEurCurrency : totalValueCurrency,
        totalValueEur: {
            amount: totalValueEur,
            currency: totalValueEurCurrency
        }
    };
}

/**
 * Get the total from consolidated operations + unconsolidated ("intradia") operations.
 *
 * @param { Object } stock
 * @returns Object
 */
function getTotalOperationsData(stock) {
    const total = get(stock, 'importes.total');
    const totalValue = numberOrNull(get(total, 'valor.importeDivisaValor.importe'));
    const totalValueCurrency = trim(get(total, 'valor.importeDivisaValor.moneda')) || null;
    const totalValueEur = numberOrNull(get(total, 'valor.importeEur.importe'));
    const totalValueEurCurrency = trim(get(total, 'valor.importeEur.moneda')) || null;
    const shares = ['PLANES', 'FONDOS', 'FUTUROS'].indexOf(stock.tipoValor) !== -1 ? numberOrNull(get(total, 'participaciones')) : numberOrNull(get(total, 'titulos'));

    return {
        shares: shares,
        totalValue: totalValue == null ? totalValueEur : totalValue,
        totalValueCurrency: totalValueCurrency == null ? totalValueEurCurrency : totalValueCurrency,
        totalValueEur: {
            amount: totalValueEur,
            currency: totalValueEurCurrency
        }
    };
}

function useQuotationOrLiquidativeValue(consolidado, type) {
    if (type===BROKER_PRODUCT_TYPE_PLANS || type===BROKER_PRODUCT_TYPE_FUNDS) {
        const quote = numberOrNull(get(consolidado, 'valorLiquidativo.importeDivisaValor.importe'));
        const quoteCurrency = trim(get(consolidado, 'valorLiquidativo.importeDivisaValor.moneda')) || null;
        const quoteEur = numberOrNull(get(consolidado, 'valorLiquidativo.importeEur.importe'));
        const quoteEurCurrency = trim(get(consolidado, 'valorLiquidativo.importeEur.moneda')) || null;
        const quoteDate = trim(get(consolidado, 'fechaValorLiquidativo')) || null;
        const quoteTime = trim(get(consolidado, 'horaValorLiquidativo')) || null;

        return {quote, quoteCurrency, quoteEur, quoteEurCurrency, quoteDate, quoteTime};

    } else {
        const quote = numberOrNull(get(consolidado, 'cotizacion.importeDivisaValor.importe'));
        const quoteCurrency = trim(get(consolidado, 'cotizacion.importeDivisaValor.moneda')) || null;
        const quoteEur = numberOrNull(get(consolidado, 'cotizacion.importeEur.importe'));
        const quoteEurCurrency = trim(get(consolidado, 'cotizacion.importeEur.moneda')) || null;
        const quoteDate = trim(get(consolidado, 'fechaCotizacion')) || null;
        const quoteTime = trim(get(consolidado, 'horaCotizacion')) || null;

        return {quote, quoteCurrency, quoteEur, quoteEurCurrency, quoteDate, quoteTime};
    }
}

function parseConsolidatedValues(consolidado, valueType) {
    const averagePrice = numberOrNull(get(consolidado, 'precioMedio.importeDivisaValor.importe'));
    const averagePriceCurrency = trim(get(consolidado, 'precioMedio.importeDivisaValor.moneda')) || null;
    const averagePriceEur = numberOrNull(get(consolidado, 'precioMedio.importeEur.importe'));
    const averagePriceEurCurrency = trim(get(consolidado, 'precioMedio.importeEur.moneda')) || null;

    const earnings = numberOrNull(get(consolidado, 'rentabilidad.importeDivisaValor.importe'));
    const earningsCurrency = trim(get(consolidado, 'rentabilidad.importeDivisaValor.moneda')) || null;
    const earningsEur = numberOrNull(get(consolidado, 'rentabilidad.importeEur.importe'));
    const earningsEurCurrency = trim(get(consolidado, 'rentabilidad.importeEur.moneda')) || null;

    const investment = numberOrNull(get(consolidado, 'capitalInvertido.importeDivisaValor.importe'));
    const investmentCurrency = trim(get(consolidado, 'capitalInvertido.importeDivisaValor.moneda')) || null;
    const investmentEur = numberOrNull(get(consolidado, 'capitalInvertido.importeEur.importe'));
    const investmentEurCurrency = trim(get(consolidado, 'capitalInvertido.importeEur.moneda')) || null;

    const totalValue = numberOrNull(get(consolidado, 'valor.importeDivisaValor.importe'));
    const totalValueCurrency = trim(get(consolidado, 'valor.importeDivisaValor.moneda')) || null;
    const totalValueEur = numberOrNull(get(consolidado, 'valor.importeEur.importe'));
    const totalValueEurCurrency = trim(get(consolidado, 'valor.importeEur.moneda')) || null;

    const quotationData = useQuotationOrLiquidativeValue(consolidado, valueType)
    const quote = quotationData.quote;
    const quoteCurrency = quotationData.quoteCurrency;
    const quoteEur = quotationData.quoteEur;
    const quoteEurCurrency = quotationData.quoteEurCurrency;
    const quoteDate = quotationData.quoteDate;
    const quoteTime = quotationData.quoteTime;

    const participaciones = numberOrNull(get(consolidado, 'participaciones'));
    const part= participaciones*quoteEur;

    const porcentajeEnCartera = get(consolidado, 'porcentajeEnCartera');
    const profitablePercentage = numberOrNull(get(consolidado, 'porcentajeRentabilidad.importeDivisaValor.importe'));
    const profitablePercentageEuros = numberOrNull(get(consolidado, 'porcentajeRentabilidad.importeEur.importe'));

    const shares = ['PLANES', 'FONDOS', 'FUTUROS'].indexOf(valueType) !== -1 ?
            numberOrNull(get(consolidado,'participaciones')) :
            numberOrNull(get(consolidado,'titulos'));

    return {
        averagePrice: averagePrice == null ? averagePriceEur : averagePrice,
        averagePriceCurrency: averagePriceCurrency == null ? averagePriceEurCurrency : averagePriceCurrency,
        averagePriceEur: {
            amount: averagePriceEur,
            currency: averagePriceEurCurrency
        },
        earnings: earnings == null ? earningsEur : earnings,
        earningsCurrency: earningsCurrency == null ? earningsEurCurrency : earningsCurrency,
        earningsEur: {
            amount: earningsEur,
            currency: earningsEurCurrency
        },
        investment: investment == null ? investmentEur : investment,
        investmentCurrency: investmentCurrency == null ? investmentEurCurrency : investmentCurrency,
        investmentEur: {
            amount: investmentEur,
            currency: investmentEurCurrency
        },
        totalValue: totalValue == null ? totalValueEur : totalValue,
        totalValueCurrency: totalValueCurrency == null ? totalValueEurCurrency : totalValueCurrency,
        totalValueEur: {
            amount: totalValueEur,
            currency: totalValueEurCurrency
        },
        quote: quote == null ? quoteEur : quote,
        quoteCurrency: quoteCurrency == null ? quoteEurCurrency : quoteCurrency,
        quoteEur: {
            amount: quoteEur,
            currency: quoteEurCurrency
        },
        quoteDate,
        quoteTime,
        porcentajeEnCartera,
        profitablePercentage: profitablePercentage == null ? profitablePercentageEuros : profitablePercentage,
        profitablePercentageEuros,
        shares,
        participaciones: {
            amount: part,
            currency: quoteEurCurrency
        },
    }
}

function parseStockData(stock) {
    const valueType = stock.tipoValor;
    return {
        alias: trim(stock.nombre),
        name: trim(stock.nombre),
        subTipoRenta: trim(stock.subTipoRenta),
        contractNumber: trim(get(stock, 'contrato.numeroContrato')) || null,
        numerodecontrato: trim(get(stock, 'contrato.numeroContrato')) || null,
        contractForTooltip: getStockContractWithControlDigit(stock),
        personalized: stock.personalized || false,
        producto: trim(get(stock, 'contrato.producto')) || null,
        marketable: get(stock, 'marketable') || false,
        marketableTooltipText: formatText('broker-notMarketable' + valueType) || false,
        proportionRightsRatio: Number(stock.proporcionTantos),
        proportionSharesRatio: Number(stock.proporcionPorTantos),
        associatedAccount: get(stock, 'cuentaAsociada')|| null,
        nameHolder: trim(get(stock, 'nombreTitular')) || null,
        fullContrato: stock.fullContrato || null,
    }
}

function parseStock(stock) {
    const valueType = stock.tipoValor;
    const consolidado = get(stock, 'importes.consolidado');
    const consolidadoParsed = parseConsolidatedValues(consolidado, valueType);
    const stockData = parseStockData(stock);
    const cuentaAsociadaPlanes = get(stock, 'cuentaAsociada.numerodecuenta');

    let result = {
        ...consolidadoParsed,
        ...stockData,
        percentageInWallet: numberOrNull(get(consolidado, 'porcentajeEnCartera')),
        controlDigit: trim(get(stock, 'digitoControl')),
        product: trim(get(stock, 'contrato.producto')) || null,
        productType: trim(get(stock, 'tipoValor')),
        productCategory: trim(get(stock, 'assetType')),
        isin: trim(get(stock, 'instrumentInfo.instrumentId')) || null,
        unconsolidatedOperations: getUnconsolidatedOperationsData(stock),
        totalOperations: getTotalOperationsData(stock),
        derechosAssociado: stock.associatedRights || false,
        esg: get(stock, 'esg')
    };

    if (['FONDOS'].indexOf(valueType) !== -1) {
        result.isBlockedByRobo = get(stock, 'isBlockedByRobo');
    }

    if (['PLANES', 'FONDOS', 'FUTUROS'].indexOf(valueType) !== -1) {
        result.id = getStockId(stock);
        result.subtype = trim(get(stock, 'subtipoProducto'));
        result.interventionType = trim(get(stock, 'tipoIntervencion'));
        result.customInstitutionSecurityId = getStockCustomInstitutionSecurityId(stock);
        result.associatedAccountContractNumber = get(stock, 'cuentaAsociada.numeroContrato', cuentaAsociadaPlanes);
        result.customAdditionalMinimumAmount = trim(get(stock, 'instrumentInfo.customAdditionalMinimumAmount')) || null;
        result.customAdditionalMinimumAmountUnit = trim(get(stock, 'instrumentInfo.customAdditionalMinimumAmountUnit')) || null;
        result.customMinimumPurchaseAmount = trim(get(stock, 'instrumentInfo.customMinimumPurchaseAmount')) || null;
        result.customMinimumPurchaseAmountUnit = trim(get(stock, 'instrumentInfo.customMinimumPurchaseAmountUnit')) || null;
    } else {
        result.quotationValue = result.quote;
        result.quotationCurrency = result.quoteCurrency;
        result.tickerCode = trim(get(stock, 'instrumentInfo.localCode')) || null;
        result.countryCode = trim(get(stock, 'instrumentInfo.countryId')) || null;
        result.marketName = trim(get(stock, 'instrumentInfo.instrument')) || null;
        result.shortMarketName = trim(get(stock, 'instrumentInfo.micCode')) || null;
        result.emisionCode = trim(get(stock, 'codigoEmision')) || null;
        result.valueCode = trim(get(stock, 'codigoValor')) || null;
        result.iso = trim(get(stock, 'instrumentInfo.micCode')) || null;
        result.contract = trim(get(stock, 'contrato.numeroContrato')) || null;
        result.tipoValorEmision = trim(get(stock, 'tipoValorEmision')) || null;
    }

    return result;
}

function parseStockInContract(stock) {
    const consolidado = stock.getIn(['importes','consolidado']).toJS();
    const valueType =  stock.get('tipoValor');
    const consolidadoParsed = parseConsolidatedValues(consolidado, valueType);
    const stockData = parseStockData(stock.toJS());

    return Immutable.fromJS({
        ...stockData,
        ...consolidadoParsed,
        tickerCode: trim(stock.getIn(['instrumentInfo', 'localCode'])) || null,
        countryCode: trim(stock.getIn(['instrumentInfo', 'countryId'])),
        marketName: trim(stock.getIn(['instrumentInfo', 'instrument'])),
        shortMarketName: trim(stock.getIn(['instrumentInfo', 'micCode'])) || null,
        percentageInWallet: Number(get(consolidadoParsed, 'porcentajeEnCartera')) || 0,
        emisionCode: trim(stock.get('codigoEmision')),
        valueCode: trim(stock.get('codigoValor')),
        productType: trim(stock.get('tipoValor')),
        isin: trim(stock.getIn(['instrumentInfo', 'instrumentId'])) || null,
        iso: trim(stock.getIn(['instrumentInfo', 'micCode'])),
        interventionType: trim(stock.get('tipoIntervencion')),
        customInstitutionSecurityId: `${stock.getIn(['contrato', 'producto'])}-${stock.get('subtipoProducto')}-1`,
        unconsolidatedOperations: getUnconsolidatedOperationsData(stock.toJS()),
        totalOperations: getTotalOperationsData(stock.toJS()),
    });
}

function parseContract(immContract) {
    const immStocks = immContract.get('valores', []).map(stock => {
        return parseStockInContract(stock);
    });

    const summarizedProfitability = immContract.getIn(['sumarizacion', 'consolidado', 'rentabilidad', 'importeEur', 'importe']);
    const summarizedValor = immContract.getIn(['sumarizacion', 'consolidado', 'valor', 'importeEur', 'importe']);
    const profitablePercentage = numberOrNull(immContract.getIn(['sumarizacion', 'consolidado', 'porcentajeRentabilidad','importeDivisaValor','importe']));
    const profitablePercentageEuros = numberOrNull(immContract.getIn(['sumarizacion', 'consolidado', 'porcentajeRentabilidad','importeEur','importe']));
    const personalized = immContract.get('personalized', false);

    return Immutable.fromJS({
        personalized,
        contract: trim(immContract.get('contrato')),
        ...(__CONTACT_CENTER__ ? {associatedAccount: immContract.get('cuentaAsociada')} : {}),
        tipoContrato: trim(immContract.get('tipoContrato')),
        descripcion: immContract.get('descripcion') ? trim(immContract.get('descripcion')) : '',
        profitablePercentage: profitablePercentage == null ? profitablePercentageEuros : profitablePercentage,
        elements: Immutable.fromJS(immStocks),
        summarizedProfitability: summarizedProfitability == null ? null : (Number(summarizedProfitability) || 0).toFixed(2),
        summarizedValor: (Number(summarizedValor) || 0).toFixed(2),
        investmentsCapital: immContract.getIn(['sumarizacion', 'consolidado', 'capitalInvertido', 'importeEur'])
    });
}

function getAssociatedDerechosDetail(stock) {
    return Immutable.fromJS({
        nombre: trim(get(stock, 'nombre')) || null,
        instrument: trim(get(stock, 'instrumentInfo.instrument')) || null,
        isin: trim(get(stock, 'instrumentInfo.instrumentId')) || null,
        endDate: trim(get(get(stock, 'importes.consolidado'), 'fechaCotizacion')),
        importesTotal: numberOrNull(get(get(stock, 'importes.total'), 'titulos')),
        proportionRightsRatio: Number(stock.proporcionTantos),
        proportionSharesRatio: Number(stock.proporcionPorTantos),
    });
}

function parseStocksByCategory(list = []) {
    let stockProducts = [...list];
    const len = stockProducts.length;
    const rightExists = stockProducts.some((elem) => elem.tipoValor == BROKER_PRODUCT_TYPE_DERECHOS);

    if (rightExists) {
        const indexOfStockFirstInstance = firstIndex(stockProducts, elem => elem.tipoValor == BROKER_PRODUCT_TYPE_ACCIONES);
        const indexOfDerechosLastInstance = findLastIndex(stockProducts, elem => elem.tipoValor == BROKER_PRODUCT_TYPE_DERECHOS);

        const standAloneDerechos = stockProducts.slice(0, indexOfStockFirstInstance).map(elem => {
            elem.assetType = `${BROKER_PRODUCT_TYPE_STANDALONE}-${elem.tipoValor.toLowerCase()}`;
            return elem;
        });

        const stocksWithDerechos = stockProducts.slice(indexOfStockFirstInstance, (indexOfDerechosLastInstance + 1))
                                    .map((elem,index, array) => {
                                        const elemTipoValor = elem.tipoValor;
                                        elemTipoValor == BROKER_PRODUCT_TYPE_ACCIONES ?
                                                            (elem.assetType = BROKER_PRODUCT_TYPE_STOCKS_WITH_RIGHTS,
                                                                elem.associatedRights = getAssociatedDerechosDetail(array[index + 1])) :
                                                            elem.assetType = BROKER_PRODUCT_TYPE_ASSOCIATED;
                                        return elem;
                                    });

        const standAloneStocks = stockProducts.slice((indexOfDerechosLastInstance + 1 ), len).map(elem => {
            elem.assetType = `${BROKER_PRODUCT_TYPE_STANDALONE}-${elem.tipoValor.toLowerCase()}`;
            return elem;
        });

        stockProducts = [...standAloneDerechos, ...stocksWithDerechos, ...standAloneStocks];
    }

    return stockProducts;
}

module.exports = {
    parseContract,
    parseStock,
    parseStocksByCategory
};
