module.exports = {
    hideTitle: true,
    order: 1,
    footer_text: '',
    position: 'first',
    components: [
        ...(
            __CONTACT_CENTER__ ?
            [{
                id_component: 'step1-deposit-transfer',
                id: 'text-info',
                cms_key: 'deposit_cc',
                showBlockedAccountMessage: true,
            }] : [{
                id_component: 'step1-deposit-transfer',
                id: 'text-info',
                cms_key: 'deposit',
                showBlockedAccountMessage: true,
            }]
        )
    ]
};