const { PRODUCTION_ENVIRONMENT, OCULTO_ENVIRONMENT } = require('constants/index');
const {
  PAGE_GENERIC,
  PROC_COMMERCIAL_SPACE,
  PAGE_LOGIN,
  PAGE_ERROR,
  PROC_START,
  PROC_RESTART,
  PROC_STEP,
  PROC_END,
  PROC_FAIL,
  PROC_CANCEL,
  PROC_SELECT_ENTITY,
  PROC_MANAGE_ENTITIES_AGGREGATOR,
  PROC_MANAGE_ACCOUNTS_AGGREGATOR,
  PROC_REVIEW,
  PROC_GO_TO,
  PROC_BUY_GDI,
  PROC_ACTIVATE_CARD,
  PROC_ADD_DOCUMENTATION,
  PROC_CHANGE_ACCESS_CODE,
  PROC_CHANGE_PERSONAL_DATA,
  PROC_CHANGE_PICTURE,
  PROC_CONTRACTS,
  PROC_FILTER_ACCOUNT_DEBITS,
  PROC_FILTER_ACCOUNT_TRANSACTIONS,
  PROC_FILTER_DOCUMENTS,
  PROC_FILTER_EMITTED_TRANSFERS,
  PROC_FILTER_RECEIVED_TRANSFERS,
  PROC_FILTER_SCHED_TRANSFERS,
  PROC_FILTER_CARDS_TRANSACTIONS,
  PROC_GET_DEBIT_PDF,
  PROC_CROSS_SELLING_COMMERCIAL_AD,
  PROC_PROMOTION_BANNER_COMMERCIAL_AD,
  PROC_SIGNATURE_PATTERN,
  PROC_OK_DISCOUNTS_REDIRECT,
  PROC_OK_DISCOUNTS_REGISTER,
  PROC_HIRE_PROMOTIONS,
  PROC_HIRE_PRE_GRANTED_CREDIT,
  PROC_INTERSTITIAL,
  PROC_LEGAL_INTERSTITIAL_FIOC,
  PROC_REFER_A_FRIEND,
  PROC_COMMERCIAL_INTERSTITIAL,
  PROC_GDPR_INTERSTITIAL,
  PROC_GDPR_CONSENTS,
  PROC_GDPR_CONSENTS_CONTRACTS,
  PROC_DISCOUNTS,
  PROC_AUTHORIZATION_RECEIPTS_RETURN,
  PROC_AUTHORIZATION_RECEIPTS_ANNULATION,
  PROC_AUTHORIZATION_RECEIPTS_ACTIVATION,
  PROC_ADD_ENTITY,
  PROC_ADD_ENTITY_ERROR,
  PROC_AGGREGATOR,
  PROC_CARD_REVOLVING,
  PROC_CARD_VIRTUAL_CREDIT,
  PROC_CHECKING_ACCOUNT,
  PROC_PACK_OPEN_YOUNG,
  PROC_UPGRADE_TO_PAYROLL_ACCOUNT,
  PROC_PAYROLL_ACCOUNT,
  PROC_CONTRACT_PAYROLL_LOAN,
  PROC_OPEN_YOUNG_CONTACT_DATA,
  PROC_OPEN_YOUNG_FISCAL_DATA,
  PROC_MODIFY_DEBIT_LIMITS_COMMERCE,
  PROC_MODIFY_DEBIT_LIMITS_EXTRACT,
  PROC_RA_CANCEL_CONTRIBUTIONS,
  PROC_RA_CANCEL_SERVICE,
  PROC_RA_CONTRACTS,
  PROC_RA_MODIFY_STRATEGY,
  PROC_RA_NEW_CONTRIBUTION,
  PROC_RA_REFUND,
  PROC_RA_SUITABILITY_TEST,
  PROC_SELECT_CARD_DESIGN,
  PROC_SELECT_PIN,
  PROC_ONG_SEARCHER,
  PROC_ONG_RESULT_CLICK,
  PROC_INSERT_OTP,
  PROC_NOT_RECEIVED_OTP,
  PROC_EXPIRED_OTP_REQUEST_OTP,
  PROC_EXPIRED_OTP_REMOVE_ENTITY,
  PROC_ERROR_CONNECTION_REQUEST_OTP,
  PROC_ERROR_CONNECTION_REMOVE_ENTITY,
  PROC_OTP_MODAL_REQUEST_OTP,
  PROC_OTP_MODAL_CLOSE,
  PROC_INSERT_OTP_FROM_ADVICE,
  PROC_INSERT_OTP_CLOSE_ADVICE,
  PROC_EXPIRED_OTP_FROM_ADVICE,
  PROC_EXPIRED_OTP_CLOSE_ADVICE,
  PROC_INSERT_OTP_PROCESS,
  PROC_INSERT_OTP_PAGE,
  PROC_INSERT_OTP_SUCCESS_PAGE,
  PROC_INSERT_OTP_SUCCESS_STEP,
  PROC_INSERT_OTP_ERROR_PAGE,
  PROC_INSERT_OTP_ERROR_STEP,
  FISCAL_DATA_FATCA,
  EVENT_ADD_ENTITY_WITHOUT_ENTITIES,
  EVENT_ADD_MORE_ENTITY,
  EVENT_ERROR_ENTITY,
  EVENT_ACTIVATE,
  EVENT_CLICK,
  EVENT_DESACTIVATE,
  EVENT_REMOVE,
  EVENT_TOGGLE_ON,
  EVENT_TOGGLE_OFF,
  EVENT_ESP_ONLY,
  EVENT_CARD_CONTROL,
  EVENT_ATMS,
  EVENT_IN_PERSONAL_PURCHASES,
  EVENT_ONLINE_PURCHASES,
  PROC_INTERACTION_NGO,
  PROC_SHOW_DETAILS_NGO,
  PROC_TRANSFER,
  PRODUCT_NAME,
  PAGE_MORTGAGE_REQUEST,
  PROC_MORTGAGE_REQUEST,
  EVENT_INTERACTION_MORTGAGE_REQUEST,
  EVENT_ACTION_SELECT_DATE,
  EVENT_ACTION_SELECT_ANOTHER_DATE,
  EVENT_ACTION_DOWNLOAD_LETTER,
  PROC_SUCCESS_CREATE_CONSENT,
  PROC_AGGREGATOR_ENTITY_SUCCESS,
  PROC_AGGREGATOR_ENTITY_SECOND_STEP,
  PROC_AGGREGATOR_ENTITY_ERROR,
  PROC_AGGREGATOR_RENEW_ENTITY_ERROR_BLOCKED,
  PROC_AGGREGATOR_RENEW_ENTITY_RETRY,
  PROC_AGGREGATOR_RENEW_SESSION_SUCCESS,
  PROC_AGGREGATOR_RENEW_SESSION,
  PROC_AGGREGATOR_RENEW_FIRST_SESSION,
  PROC_AGGREGATOR_RENEW_FIRST_SESSION_GO_TO,
  PROC_AGGREGATOR_RENEW_ENTITY_GO_TO_BLOQUED_USER,
  PROC_AGGREGATOR_CONSENT_FIRST_STEP,
  PROC_AGGREGATOR_CONSENT,
  PROC_AGGREGATOR_CONSENT_FIRST_STEP_GO_TO,
  PROC_AGGREGATOR_CONSENT_SUCCESS,
  PROC_AGGREGATOR_CONSENT_SUCCESS_ENTER_CREDENTIALS,
  PROC_AGGREGATOR_CONSENT_RETRY,
  PROC_AGGREGATOR_CONSENT_ERROR,
  PROC_AGGREGATOR_RENEW_CONSENT,
  PROC_AGGREGATOR_RENEW_CONSENT_1,
  PROC_AGGREGATOR_RENEW_CONSENT_GO_TO,
  PROC_AGGREGATOR_RENEW_CONSENT_SUCCESS,
  PROC_AGGREGATOR_RENEW_CONSENT_ERROR,
  PROC_AGGREGATOR_RENEW_CONSENT_RETRY,
  PROC_GO_SECOND_STEP,
  PROC_FIRST_STEP,
  PROC_GO_ERROR_STEP,
  PROC_BROKER_CONVENIENCE_TEST,
} = require('commonsConstants/tagging');

// TINK TAGGING CONSTANTS
const TINK_PROCESS_STEP_INITIAL = 'inicio';
const TINK_PROCESS_STEP_SUCCESS = 'exito';
const TINK_PROCESS_STEP_ERROR = 'error';
// -> PG
const TINK_PG_PAGE_NAME = 'posicionGlobal';
const TINK_PG_ADD_BANK_TAG = 'añadir banco';
const TINK_PG_ADD_MY_FIRST_BANK_BUTTON = 'añadir mi primer banco';
const TINK_PG_SETTINGS_TAG = 'ajustes';
const TINK_PG_CATEGORIZER_ACTION = 'ver categorizador de gastos';
const TINK_PG_CONNECTION_ERROR_PRIMARY_ACTION = 'agregador financiero/error de conexion';
const TINK_PG_NEW_KEY_TAG = 'solicitar nueva clave';
const TINK_PG_DELETE_BANK_TAG = 'eliminar banco';
const TINK_PG_NEW_KEY_INPUT_ACTION = 'agregador financiero/introduce de nuevo la clave';
const TINK_PG_NEW_KEY_INPUT_TAG = 'introducir clave';
// -> AddBank
const TINK_ADD_BANK_PAGE_NAME = 'agregar entidad';
const TINK_ADD_BANK_PROCESS_TYPE = 'proc-agregar-entidad';
const TINK_ADD_BANK_SELECT_BANK_IMG = 'seleccionar banco';
const TINK_ADD_BANK_ADD_CREDENTIALS_BUTTON = 'ingresar credenciales';
const TINK_ADD_BANK_ADD_BANK_BUTTON = 'añadir otro banco';
const TINK_ADD_BANK_SEARCHER_DROPDOWN = 'dropdown buscador';
const TINK_ADD_BANK_SEARCHER_DROPDOWN_TAG = 'abrir';
const TINK_ADD_BANK_CANCEL_LINK = 'cancelar';
const TINK_ADD_BANK_NEXT_BUTTON = 'siguiente';
const TINK_ADD_BANK_RETRY_BUTTON = 'reintentar';
const TINK_ADD_BANK_GO_BACK_GP_LINK = 'volver a posicion global';

const PROC_BROKER_PG_INTERACTION = 'interaccion en tu inviertes';
const PROC_CARD_CHANGE_NAME = 'tarjeta-cambio-texto-relieve';
const PROC_EDIT_SHIPPING_ADDRESS = 'proc-modifica-domicilio-correspondencia';
const PROC_DELETE_SHIPPING_ADDRESS = 'proc-eliminar-domicilio-correspondencia';
const EVENT_CATEGORY_MORE_OPTIONS = 'tarjetas-mas opciones';
const PAGE_LOANS_MORTGAGES = 'prestamos-hipotecas';
const PAGE_CONTRACT_ARES_LOAN = '/contratacion-prestamo-ares/inicio/';
const PAGE_CONTRACT_ARES_LOAN_STEP2 = '/contratacion-prestamo-ares/paso_2/';
const PAGE_CONTRACT_ARES_LOAN_ERROR = '/contratacion-prestamo-ares/error/';
const PAGE_ARES_LOANS_MORTGAGES_CONTRACT = '/contratacion-prestamo-ares/resumen/';
const PAGE_ARES_LOANS_CONFIRMATION = '/contratacion-prestamo-ares/consentimientos/exito/';
const PROC_CONTRACT_RETAIL_LOAN = 'proc-contratacion-prestamo-compra';
const PROC_RA_SUITABILITY_TEST_CURRENT_ANSWER = 'respuesta ';
const PROC_RA_SUITABILITY_TEST_FINISH_FORM = 'resultado';
const EVENT_UNSUITABLE_PROFILE = 'no apto';
const PROC_RA_SUITABILITY_TEST_STEP_1 = 'paso_1';
const PROC_RA_SUITABILITY_TEST_STEP_2 = 'paso_2';
const PROC_RA_SUITABILITY_TEST_STEP_3 = 'paso_3';
const PROC_RA_SUITABILITY_TEST_STEP_4 = 'paso_4';
const PROC_RA_SUITABILITY_TEST_STEP_5 = 'paso_5';
const PROC_RA_SUITABILITY_TEST_STEP_6 = 'paso_6';
const PROC_RA_SUITABILITY_TEST_STEP_7 = 'paso_7';
const PROC_RA_SUITABILITY_TEST_STEP_8 = 'paso_8';
const PROC_RA_SUITABILITY_TEST_STEP_9 = 'paso_9';
const PROC_RA_SUITABILITY_TEST_STEP_10 = 'paso_10';
const PROC_RA_SUITABILITY_TEST_STEP_11 = 'paso_11';
const PROC_RA_SUITABILITY_TEST_STEP_12 = 'paso_12';
const PROC_RA_GLOBAL_POSITION_INTERACTION = 'interaccion en invertimos por ti';
const PROC_RA_GLOBAL_POSITION_HEADER_HELP = 'ayuda';
const PROC_RA_GLOBAL_POSITION_PATRIMONY_DETAILS = 'información importe';
const PROC_RA_GLOBAL_POSITION_PAGE_NAME = 'robo-advisor';
const PROC_INVESTMENT_MENU_INTERACCION = 'interaccion en menu inversiones';
const PROC_INVESTMENT_BROKER_PG = 'tu inviertes';
const PROC_INVESTMENT_BROKER_PG_NAME = 'broker/myPortfolio';
const PROC_INVESTMENT_BROKER_ROBO = 'invertimos por ti';
const PROC_BROKER_STOCKS_PG = 'Acciones';
const PROC_BROKER_STOCKS_PG_NAME = 'broker/stocks';
const PROC_BROKER_FUNDS_PG = 'Fondos de Inversion';
const PROC_BROKER_FUNDS_PG_NAME = 'broker/funds';
const PROC_BROKER_ALTERNATIVE_FUNDS_PG_NAME = 'broker/alternativeFunds';
const PROC_BROKER_HIRE_ALTERNATIVE_FUNDS_PG_NAME = 'broker/hireAlternativeFunds';
const PROC_BROKER_PLANS_PG = 'Planes de Pensiones';
const PROC_BROKER_PLANS_PG_NAME = 'broker/plans';
const PROC_BROKER_ETFS_PG = 'ETFs';
const PROC_BROKER_ETFS_PG_NAME = 'broker/etfs';
const PROC_BROKER_WARRANTS_PG = 'Warrants';
const PROC_BROKER_WARRANTS_PG_NAME = 'broker/warrants';
const PROC_BROKER__PRODUCT_FILE = 'productFile';
const PROC_BROKER__MARKETS_PG_NAME = 'broker/markets';
const SUSTAINTABILITY_TEST = 'sustaintabilityTest';
const SUSTAINABILITY_QUESTION_400 = 'sustainabilityQuestion400';
const SUSTAINABILITY_QUESTION_401 = 'sustainabilityQuestion401';
const RA_GLOBAL_POSITION = 'raGlobalPosition';
const RA_REIMBURSEMENT = 'raReimbursement';
const RA_MANAGEMENT_PLAN = 'raManagementPlan';
const BROKER_EXTRAORDINARY_PENSION_PLAN = 'brokerExtraordinaryPensionPlan';
const PROPC_RA_HIRE_MANAGEMENT_PLAN = 'procRAHireManagmentPlan';
const CARD_TOGGLE = 'card interaction';

const {
  recordLinkCommon,
  recordLinkCommonV2,
  recordPathCommon,
  recordCommercialSpaceCommon,
  recordCommercialSpaceCommonV2,
  recordProcessCommon,
  recordErrorCommon,
  recordCancellationCommon,
  forceSendCommon,
  recordEvent,
} = require('commonsUtilities/tagging');
const { TOKEN_CREDENTIAL } = require('constants/session');
const isProduction = window.__ENV__ !== PRODUCTION_ENVIRONMENT;
const isContactCenter = __CONTACT_CENTER__;
const isOculto = window.__ENV__ !== OCULTO_ENVIRONMENT;
const tokenCredential = TOKEN_CREDENTIAL;

const recordLink = (eventCategory = '', eventName = '', eventTag = '') => {
  recordLinkCommon(
    eventCategory,
    eventName,
    eventTag,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential
  );
};

const recordLinkV2 = (customData = {}) => {
  recordLinkCommonV2(customData, isProduction);
};

const recordPath = (
  pathForTag,
  pageType = PAGE_GENERIC,
  procType = '',
  procStep = '',
  nameProduct = null,
  preapprovedId = '',
  processDetails = '',
  profileInvestor = '',
  descriptionError = '',
  categoryError = '',
) => {
  recordPathCommon(
    pathForTag,
    pageType,
    procType,
    procStep,
    nameProduct,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential,
    preapprovedId,
    processDetails,
    descriptionError,
    categoryError,
    profileInvestor
  );
};

export const recordPathV2 = params => {
  const pathForTag = params.pathForTag || '',
    pageType = params.pageType || PAGE_GENERIC,
    procType = params.procType || '',
    procStep = params.procStep || '',
    nameProduct = params.nameProduct || null,
    preapprovedId = params.preapprovedId || '',
    processDetails = params.processDetails || '',
    descriptionError = params.descriptionError || '',
    categoryError = params.categoryError || '';

  recordPathCommon(
    pathForTag,
    pageType,
    procType,
    procStep,
    nameProduct,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential,
    preapprovedId,
    processDetails,
    descriptionError,
    categoryError
  );
};

const recordCommercialSpace = (params, category = PROC_COMMERCIAL_SPACE) => {
  recordCommercialSpaceCommon(
    params,
    category,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential
  );
};

const recordCommercialSpaceV2 = (params, customData = {}) => {
  recordCommercialSpaceCommonV2(
    params,
    customData,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential
  );
};

const recordProcess = (proc, step, customData = {}, isSecondVersion) => {
  recordProcessCommon(
    proc,
    step,
    customData,
    isProduction,
    isContactCenter,
    isOculto,
    tokenCredential,
    isSecondVersion
  );
};

const recordCancellation = () => {
  recordCancellationCommon(tokenCredential);
};

const recordError = errCode => {
  recordErrorCommon(errCode, isContactCenter, tokenCredential);
};

const forceSend = () => {
  forceSendCommon(tokenCredential);
};

const recordEventCardMoreOptionsFromCommons = (eventAction, tagEvent) => {
  recordEvent(EVENT_CATEGORY_MORE_OPTIONS, eventAction, tagEvent);
};

const recordPage = pageData => {
  window && window.utag && window.utag.view && window.utag.view(pageData);
};

module.exports = {
  recordPage,
  recordLink,
  recordLinkV2,
  recordPath,
  recordPathV2,
  recordProcess,
  recordError,
  recordCommercialSpace,
  recordCommercialSpaceV2,
  recordCancellation,
  forceSend,
  recordEventCardMoreOptionsFromCommons,
  TINK_PROCESS_STEP_INITIAL,
  TINK_PROCESS_STEP_SUCCESS,
  TINK_PROCESS_STEP_ERROR,
  TINK_PG_PAGE_NAME,
  TINK_PG_CATEGORIZER_ACTION,
  TINK_PG_ADD_BANK_TAG,
  TINK_PG_ADD_MY_FIRST_BANK_BUTTON,
  TINK_PG_SETTINGS_TAG,
  TINK_PG_CONNECTION_ERROR_PRIMARY_ACTION,
  TINK_PG_DELETE_BANK_TAG,
  TINK_PG_NEW_KEY_TAG,
  TINK_PG_NEW_KEY_INPUT_ACTION,
  TINK_PG_NEW_KEY_INPUT_TAG,
  TINK_ADD_BANK_PAGE_NAME,
  TINK_ADD_BANK_PROCESS_TYPE,
  TINK_ADD_BANK_SELECT_BANK_IMG,
  TINK_ADD_BANK_ADD_CREDENTIALS_BUTTON,
  TINK_ADD_BANK_ADD_BANK_BUTTON,
  TINK_ADD_BANK_SEARCHER_DROPDOWN,
  TINK_ADD_BANK_SEARCHER_DROPDOWN_TAG,
  TINK_ADD_BANK_CANCEL_LINK,
  TINK_ADD_BANK_NEXT_BUTTON,
  TINK_ADD_BANK_RETRY_BUTTON,
  TINK_ADD_BANK_GO_BACK_GP_LINK,
  PAGE_GENERIC,
  PAGE_CONTRACT_ARES_LOAN,
  PAGE_CONTRACT_ARES_LOAN_STEP2,
  PAGE_CONTRACT_ARES_LOAN_ERROR,
  PROC_COMMERCIAL_SPACE,
  PAGE_LOGIN,
  PAGE_ERROR,
  PROC_START,
  PROC_RESTART,
  PROC_STEP,
  PROC_END,
  PROC_FAIL,
  PROC_CANCEL,
  PROC_SELECT_ENTITY,
  PROC_MANAGE_ENTITIES_AGGREGATOR,
  PROC_MANAGE_ACCOUNTS_AGGREGATOR,
  PROC_REVIEW,
  PROC_GO_TO,
  PROC_BUY_GDI,
  PROC_ACTIVATE_CARD,
  PROC_ADD_DOCUMENTATION,
  PROC_CHANGE_ACCESS_CODE,
  PROC_CHANGE_PERSONAL_DATA,
  PROC_CHANGE_PICTURE,
  PROC_CONTRACTS,
  PROC_RA_SUITABILITY_TEST_CURRENT_ANSWER,
  PROC_RA_SUITABILITY_TEST_FINISH_FORM,
  PROC_FILTER_ACCOUNT_DEBITS,
  PROC_FILTER_ACCOUNT_TRANSACTIONS,
  PROC_FILTER_DOCUMENTS,
  PROC_FILTER_EMITTED_TRANSFERS,
  PROC_FILTER_RECEIVED_TRANSFERS,
  PROC_FILTER_SCHED_TRANSFERS,
  PROC_FILTER_CARDS_TRANSACTIONS,
  PROC_GET_DEBIT_PDF,
  PROC_CROSS_SELLING_COMMERCIAL_AD,
  PROC_PROMOTION_BANNER_COMMERCIAL_AD,
  PROC_SIGNATURE_PATTERN,
  PROC_OK_DISCOUNTS_REDIRECT,
  PROC_OK_DISCOUNTS_REGISTER,
  PROC_HIRE_PROMOTIONS,
  PROC_HIRE_PRE_GRANTED_CREDIT,
  PROC_INTERSTITIAL,
  PROC_LEGAL_INTERSTITIAL_FIOC,
  PROC_REFER_A_FRIEND,
  PROC_COMMERCIAL_INTERSTITIAL,
  PROC_GDPR_INTERSTITIAL,
  PROC_GDPR_CONSENTS,
  PROC_GDPR_CONSENTS_CONTRACTS,
  PROC_DISCOUNTS,
  PROC_AUTHORIZATION_RECEIPTS_RETURN,
  PROC_AUTHORIZATION_RECEIPTS_ANNULATION,
  PROC_AUTHORIZATION_RECEIPTS_ACTIVATION,
  PROC_ADD_ENTITY,
  PROC_ADD_ENTITY_ERROR,
  PROC_AGGREGATOR,
  PROC_CARD_CHANGE_NAME,
  PROC_CARD_REVOLVING,
  PROC_CARD_VIRTUAL_CREDIT,
  PROC_CHECKING_ACCOUNT,
  PROC_DELETE_SHIPPING_ADDRESS,
  PROC_EDIT_SHIPPING_ADDRESS,
  PROC_PACK_OPEN_YOUNG,
  PROC_UPGRADE_TO_PAYROLL_ACCOUNT,
  PROC_PAYROLL_ACCOUNT,
  PROC_CONTRACT_PAYROLL_LOAN,
  PROC_OPEN_YOUNG_CONTACT_DATA,
  PROC_OPEN_YOUNG_FISCAL_DATA,
  PROC_MODIFY_DEBIT_LIMITS_COMMERCE,
  PROC_MODIFY_DEBIT_LIMITS_EXTRACT,
  PROC_RA_CANCEL_CONTRIBUTIONS,
  PROC_RA_CANCEL_SERVICE,
  PROC_RA_CONTRACTS,
  PROC_RA_MODIFY_STRATEGY,
  PROC_RA_NEW_CONTRIBUTION,
  PROC_RA_REFUND,
  PROC_RA_SUITABILITY_TEST,
  PROC_SELECT_CARD_DESIGN,
  PROC_SELECT_PIN,
  PROC_ONG_SEARCHER,
  PROC_ONG_RESULT_CLICK,
  PROC_RA_SUITABILITY_TEST_STEP_1,
  PROC_RA_SUITABILITY_TEST_STEP_2,
  PROC_RA_SUITABILITY_TEST_STEP_3,
  PROC_RA_SUITABILITY_TEST_STEP_4,
  PROC_RA_SUITABILITY_TEST_STEP_5,
  PROC_RA_SUITABILITY_TEST_STEP_6,
  PROC_RA_SUITABILITY_TEST_STEP_7,
  PROC_RA_SUITABILITY_TEST_STEP_8,
  PROC_RA_SUITABILITY_TEST_STEP_9,
  PROC_RA_SUITABILITY_TEST_STEP_10,
  PROC_RA_SUITABILITY_TEST_STEP_11,
  PROC_RA_SUITABILITY_TEST_STEP_12,
  PROC_BROKER_PG_INTERACTION,
  PROC_BROKER_STOCKS_PG,
  PROC_BROKER_STOCKS_PG_NAME,
  PROC_BROKER_FUNDS_PG,
  PROC_BROKER_FUNDS_PG_NAME,
  PROC_BROKER_PLANS_PG,
  PROC_BROKER_PLANS_PG_NAME,
  PROC_BROKER_ETFS_PG,
  PROC_BROKER_ETFS_PG_NAME,
  PROC_BROKER_WARRANTS_PG,
  PROC_BROKER_WARRANTS_PG_NAME,
  PROC_RA_GLOBAL_POSITION_INTERACTION,
  PROC_RA_GLOBAL_POSITION_HEADER_HELP,
  PROC_RA_GLOBAL_POSITION_PATRIMONY_DETAILS,
  PROC_RA_GLOBAL_POSITION_PAGE_NAME,
  PROC_INVESTMENT_MENU_INTERACCION,
  PROC_INVESTMENT_BROKER_PG,
  PROC_INVESTMENT_BROKER_PG_NAME,
  PROC_INVESTMENT_BROKER_ROBO,
  PROC_INSERT_OTP,
  PROC_NOT_RECEIVED_OTP,
  PROC_EXPIRED_OTP_REQUEST_OTP,
  PROC_EXPIRED_OTP_REMOVE_ENTITY,
  PROC_ERROR_CONNECTION_REQUEST_OTP,
  PROC_ERROR_CONNECTION_REMOVE_ENTITY,
  PROC_OTP_MODAL_REQUEST_OTP,
  PROC_OTP_MODAL_CLOSE,
  PROC_INSERT_OTP_FROM_ADVICE,
  PROC_INSERT_OTP_CLOSE_ADVICE,
  PROC_EXPIRED_OTP_FROM_ADVICE,
  PROC_EXPIRED_OTP_CLOSE_ADVICE,
  PROC_INSERT_OTP_PROCESS,
  PROC_INSERT_OTP_PAGE,
  PROC_INSERT_OTP_SUCCESS_PAGE,
  PROC_INSERT_OTP_SUCCESS_STEP,
  PROC_INSERT_OTP_ERROR_PAGE,
  PROC_INSERT_OTP_ERROR_STEP,
  FISCAL_DATA_FATCA,
  EVENT_UNSUITABLE_PROFILE,
  EVENT_ADD_ENTITY_WITHOUT_ENTITIES,
  EVENT_ADD_MORE_ENTITY,
  EVENT_ERROR_ENTITY,
  EVENT_ACTIVATE,
  EVENT_CLICK,
  EVENT_DESACTIVATE,
  EVENT_REMOVE,
  EVENT_TOGGLE_ON,
  EVENT_TOGGLE_OFF,
  EVENT_ESP_ONLY,
  EVENT_CARD_CONTROL,
  EVENT_ATMS,
  EVENT_IN_PERSONAL_PURCHASES,
  EVENT_ONLINE_PURCHASES,
  PROC_INTERACTION_NGO,
  PROC_SHOW_DETAILS_NGO,
  PROC_TRANSFER,
  PRODUCT_NAME,
  PAGE_LOANS_MORTGAGES,
  PAGE_ARES_LOANS_MORTGAGES_CONTRACT,
  PAGE_ARES_LOANS_CONFIRMATION,
  PAGE_MORTGAGE_REQUEST,
  PROC_MORTGAGE_REQUEST,
  EVENT_INTERACTION_MORTGAGE_REQUEST,
  EVENT_ACTION_SELECT_DATE,
  EVENT_ACTION_SELECT_ANOTHER_DATE,
  EVENT_ACTION_DOWNLOAD_LETTER,
  PROC_CONTRACT_RETAIL_LOAN,
  PROC_SUCCESS_CREATE_CONSENT,
  PROC_AGGREGATOR_ENTITY_SUCCESS,
  PROC_AGGREGATOR_ENTITY_SECOND_STEP,
  PROC_AGGREGATOR_ENTITY_ERROR,
  PROC_AGGREGATOR_RENEW_ENTITY_ERROR_BLOCKED,
  PROC_AGGREGATOR_RENEW_ENTITY_RETRY,
  PROC_AGGREGATOR_RENEW_SESSION_SUCCESS,
  PROC_AGGREGATOR_RENEW_SESSION,
  PROC_AGGREGATOR_RENEW_FIRST_SESSION,
  PROC_AGGREGATOR_RENEW_FIRST_SESSION_GO_TO,
  PROC_AGGREGATOR_RENEW_ENTITY_GO_TO_BLOQUED_USER,
  PROC_AGGREGATOR_CONSENT_FIRST_STEP,
  PROC_AGGREGATOR_CONSENT,
  PROC_AGGREGATOR_CONSENT_FIRST_STEP_GO_TO,
  PROC_AGGREGATOR_CONSENT_SUCCESS,
  PROC_AGGREGATOR_CONSENT_SUCCESS_ENTER_CREDENTIALS,
  PROC_AGGREGATOR_CONSENT_RETRY,
  PROC_AGGREGATOR_CONSENT_ERROR,
  PROC_AGGREGATOR_RENEW_CONSENT,
  PROC_AGGREGATOR_RENEW_CONSENT_1,
  PROC_AGGREGATOR_RENEW_CONSENT_GO_TO,
  PROC_AGGREGATOR_RENEW_CONSENT_SUCCESS,
  PROC_AGGREGATOR_RENEW_CONSENT_ERROR,
  PROC_AGGREGATOR_RENEW_CONSENT_RETRY,
  PROC_GO_SECOND_STEP,
  PROC_FIRST_STEP,
  PROC_GO_ERROR_STEP,
  PROC_BROKER__PRODUCT_FILE,
  PROC_BROKER__MARKETS_PG_NAME,
  SUSTAINABILITY_QUESTION_400,
  SUSTAINABILITY_QUESTION_401,
  RA_GLOBAL_POSITION,
  RA_MANAGEMENT_PLAN,
  SUSTAINTABILITY_TEST,
  RA_REIMBURSEMENT,
  PROPC_RA_HIRE_MANAGEMENT_PLAN,
  PROC_BROKER_CONVENIENCE_TEST,
  CARD_TOGGLE,
  BROKER_EXTRAORDINARY_PENSION_PLAN,
  PROC_BROKER_ALTERNATIVE_FUNDS_PG_NAME,
  PROC_BROKER_HIRE_ALTERNATIVE_FUNDS_PG_NAME,
};