// @vendor
const { combineReducers } = require('redux');

// @commons
const actionTypes = require('constants/actionTypes');

// @reducers
const accessKeyChange = require('./accessKeyChange');
const accountCancelCheques = require('./accountCancelCheques');
const accountCancelChequebook = require('./accountCancelChequebook');
const accountDebits = require('./accountDebits');
const accountDebitsEdit = require('./accountDebitsEdit');
const accountDirectDebits = require('./accountDirectDebits');
const accountNewChequebook = require('./accountNewChequebook');
const accountChequebookAdditionalInformation = require('./accountChequebookAdditionalInformation');
const accountIntervenerRemoval = require('./accountIntervenerRemoval');
const accounts = require('./accounts');
const accountDebitAnnulation = require('./accountDebitAnnulation');
const accountWarningMessage = require('./contactCenter/accountWarningMessage/accountWarningMessage');
const accountsCheques = require('./accountsCheques');
const accountsRetentions = require('./accountsRetentions');
const accountsSettlements = require('./accountsSettlements');
const accountsTransactions = require('./accountsTransactions');
const accountTransactionsDownload = require('./accountTransactionsDownload');
const accountAddDocumentation = require('./accountAddDocumentation');
const addImpositionDeposit = require('./contactCenter/addImpositionDeposit/addImpositionDeposit');
const advancedInterstitialPromotions = require('./advancedInterstitialPromotions');
const amazonGiftCards = require('./amazonGiftCards');
const accountsBuyAmazonGiftCard = require('./accountsBuyAmazonGiftCard/accountsBuyAmazonGiftCard');
const authorizations = require('./accountDebits/authorizations');
const authorizationActivation = require('./accountDebits/authorizationActivation');
const authorizationModifyAccount = require('./accountDebits/authorizationModifyAccount');
const aggregatorAddEntity = require('./aggregator/aggregatorAddEntity');
const aggregatorAddEntityPSD2 = require('./aggregator/aggregatorAddEntityPSD2');
const aggregatorConsentsPSD2 = require('./aggregator/aggregatorConsentsPSD2')
const aggregatorChangeConsents = require('./aggregator/aggregatorConsents');
const aggregatorData = require('./aggregator/aggregatorData');
const landings = require('./landings');
const applyFor = require('./applyFor');
const bankEntity = require('./bankEntity');
const bannerUpdate = require('./bannerUpdate');
const benefits123 = require('./contactCenter/benefits123/benefits123.js');
const biocatch = require('./biocatch');
const bizumConfirmation = require('./bizumConfirmation');
const brokerAdvancedSearch = require('./broker/brokerAdvancedSearch'); // remove on "new search feature"
const brokerSimpleSearch = require('./broker/brokerSimpleSearch'); // remove on "new search feature"
const brokerSearchWidget = require('./broker/brokerSearchWidget');
const brokerProductAdvancedSearch = require('./broker/brokerProductAdvancedSearch');
const brokerProductFiltersSearch = require('./broker/brokerProductFiltersSearch');
const brokerSearchTagging = require('./broker/brokerSearchTagging');
const brokerSpinOffModal = require('./broker/brokerSpinOffModal');
const brokerAlertsConfig = require('./broker/brokerAlertsConfig');
const brokerAlertsConditions = require('./broker/brokerAlertsConditions');
const brokerClientWarningModal = require('./broker/brokerClientWarningModal');
const brokerContractState = require('./contactCenter/brokerContractState');
const brokerAttentionPlanStartingModal = require('./broker/brokerAttentionPlanStartingModal');
const brokerCorporatesOperations = require('./broker/brokerCorporatesOperations');
const brokerError = require('./broker/brokerError');
const brokerETFsProduct = require('./broker/brokerETFsProduct');
const brokerFundamentalAnalysisSearch = require('./broker/brokerFundamentalAnalysisSearch');
const brokerHirePensionPlan = require('./broker/brokerHirePensionPlan');
const brokerIndexes = require('./broker/brokerIndexes');
const brokerInformativePopUp = require('./broker/brokerInformativePopUp');
const brokerInternalTransferFunds = require('./broker/brokerInternalTransferFunds');
const brokerExchangeIndexes = require('./broker/brokerExchangeIndexes');
const brokerTickerExchangeIndexes = require('./broker/brokerExchangeIndexes');
const brokerInvestmentSpecialists = require('./broker/brokerSpecialists');
const brokerLandingLoader = require('./broker/brokerLandingLoader/brokerLandingLoader');
const brokerMarketConsensus = require('./broker/brokerMarketConsensus');
const brokerMarketsCalendar = require('./broker/brokerMarketsCalendar');
const brokerMarketsPulses = require('./broker/brokerMarketsPulses');
const brokerMarketsTrend = require('./broker/brokerMarketsTrend');
const brokerMyInvestments = require('./broker/brokerMyInvestments');
const brokerMyInvestmentWidget = require('./broker/brokerMyInvestmentWidget');
const brokerMarkets = require('./broker/brokerMarkets');
const brokerNews = require('./broker/brokerNews');
const brokerOpenTrader = require('./broker/brokerOpenTrader');
const brokerPensionPlanCancelPendingOrder = require('./contactCenter/brokerPensionPlanCancelPendingOrder/brokerPensionPlanCancelPendingOrder');
const brokerPensionPlans = require('./broker/brokerPensionPlans');
const brokerPeriodicalFundsContributions = require('./broker/brokerPeriodicalFundsContributions');
const brokerProductFile = require('./broker/brokerProductFile');
const brokerSearch = require('./broker/brokerSearch');
const brokerStocksBuy = require('./broker/brokerStocksBuy.js');
const brokerStocksAdvancedBuy = require('./broker/brokerStocksAdvancedBuy.js');
const brokerWarrantsAdvancedBuy = require('./broker/brokerWarrantsAdvancedBuy.js');
const brokerStocksAdvancedSell = require('./broker/brokerStocksAdvancedSell.js');
const brokerWarrantsAdvancedSell = require('./broker/brokerWarrantsAdvancedSell.js');
const brokerWarrantsFile = require('./broker/brokerWarrantsFile.js');
const brokerStocksSell = require('./broker/brokerStocksSell.js');
const brokerStocksContract = require('./broker/brokerStocksContract.js');
const brokerStocksContractForm = require('./broker/brokerStocksContractForm.js');
const brokerTodayNews = require('./broker/brokerTodayNews');
const brokerTransferPensionPlansInternal = require('./broker/brokerTransferPensionPlansInternal');
const brokerTransferPensionPlansExternal = require('./broker/brokerTransferPensionPlansExternal');
const brokerWallets = require('./broker/brokerWallets');
const brokerWatchlist = require('./broker/brokerWatchlist');
const brokerMostPopularOfMonth = require('./broker/brokerMostPopularOfMonth');
const brokerTodaysMainStocks = require('./broker/brokerTodaysMainStocks');
const brokerKnowTheseStocks = require('./broker/brokerKnowTheseStocks');
const brokerIAPredictionDetail = require('./broker/brokerIA/brokerIAPredictionDetail');
const brokerIAPredictionMarkets = require('./broker/brokerIA/brokerIAPredictionMarkets');
const brokerIAPredictionSearch = require('./broker/brokerIA/brokerIAPredictionSearch');
const brokerInterveners = require('./broker/brokerInterveners');
const brokerInvestmentFunds = require('ok-funds-card/reducers/brokerInvestmentFunds')
const brokerInvestmentFundView = require('ok-funds-card/reducers/brokerInvestmentFundView');
const brokerIsUsPerson = require('./broker/brokerUsPersonBlock');
//const brokerPensionPlan = require('plans-card/reducers/brokerPensionPlans')
//const brokerPensionPlanView = require('plans-card/reducers/brokerPensionPlanView');
const brokerETFBuy = require('./broker/brokerETFBuy');
const brokerETFsSell = require('./broker/brokerETFsSell');
const brokerWarrantsBuy = require('./broker/brokerWarrantsBuy');
const brokerWarrantsSell = require('./broker/brokerWarrantsSell');
const brokerTransferValues = require('./broker/brokerTransferValues');
const brokerTransferExternalValues = require('./broker/brokerTransferExternalValues');
const brokerContractValues = require('./broker/brokerContractValues');
const brokerValueMovements = require('./broker/brokerValueMovements');
const brokerContractActivities = require('./broker/brokerContractActivities');
const brokerContractRejectedActivities = require('./broker/brokerContractRejectedActivities');
const brokerOrderCancel = require('./broker/brokerOrderCancel');
const brokerOrdersAndMovements = require('./broker/brokerOrdersAndMovements');
const brokerFundOrderCancel = require('./broker/brokerFundOrderCancel');
const brokerJointContracts = require('./broker/brokerJointContracts');
const brokerCheckingAccounts = require('./broker/brokerCheckingAccounts');
const brokerAlerts = require('./broker/brokerAlerts');
const brokerRightsBuy = require('./broker/brokerRightsBuy');
const brokerRightsSell = require('./broker/brokerRightsSell');
const brokerRightsCashExchange = require('./broker/brokerRightsCashExchange');
const cardActivation = require('./cardActivation.js');
const cardBlock = require('./cardBlock');
const cardPayLater = require('./cardPayLater');
const cardRewards = require('./cardRewards');
const cancelCardPayLater = require('./cancelCardPayLater');
const cardsCanceled = require('./cardsCanceled');
const cardsCancelPayLater = require('./cardsCancelPayLater/cardsCancelPayLater');
const cardChangePaymentMethod = require('./cardChangePaymentMethod');
const cardCharge = require('./cardCharge');
const cardCancelDeferPurchase = require('./cardCancelDeferPurchase/cardCancelDeferPurchase');
const cardDepositMoney = require('./cardDepositMoney');
const cardTransferMoney = require('./cardTransferMoney');
const filters = require('./filters');
const openYoungCardCreateChore = require('./openYoungCardCreateChore/openYoungCardCreateChore');
const openYoungCardModifyChore = require('./openYoungCardModifyChore/openYoungCardModifyChore');
const cardDownloadPdf = require('./cardDownloadPdf');
const cardMobileRecharge = require('./cardMobileRecharge');
const cardModifyLimit = require('./cardModifyLimit');
const cardModifyDebitLimits = require('./cardModifyDebitLimits');
const cardPaymentPurchaseDeferral = require('./cardPaymentPurchaseDeferral');
const cardPaymentPurchaseDeferralLavanda = require('./cardPaymentPurchaseDeferralLavanda');
const cardReplace = require('./cardReplace');
const cm = require('wealth-cm').cm;
const cmLegacy = require('wealth-cm').cmLegacy;
const cmLegalDocuments = require('wealth-cm').cmLegalDocuments;
const cardTransferMoneyToCheckingAccount = require('./contactCenter/cardTransferMoneyToCheckingAccount');
const changeReceiptAccount = require('./contactCenter/changeReceiptAccount/changeReceiptAccount');
const agentAuthentication = require('./contactCenter/agentAuthentication/agentAuthentication');
const chat = require('./chat');
const loginAsClient = require('./contactCenter/loginAsClient');
const cancelCreditCardRenewal = require('./contactCenter/cancelCreditCardRenewal/cancelCreditCardRenewal');
const glossary = require('./glossary');
const cardActivationR42 = require('./cardActivationR42');
const cards = require('./cards');
const cardBenefits = require('./cardBenefits');
const cardsPaymentCycle = require('./cardsPaymentCycle');
const cardSecurePayment = require('./cardSecurePayment');
const cardsStatements = require('./cardsStatements');
const cardsStatementDetails = require('./cardsStatementDetails');
const cardsStatementMovements = require('./cardStatementMovements');
const cardsTransactions = require('./cardsTransactions');
const cardsTransactionsDownload = require('./cardsTransactionsDownload');
const cardPinChange = require('./cardPinChange');
const cardControlOn = require('./cardControlOn');
const cardTravelOn = require('./cardTravelOn');
const cardMovementInstallmentChart = require('./cardMovementInstallmentChart');
const cardControl = require('./cardControl');
const cardsControlHistory = require('./contactCenter/cardsControlHistory/cardsControlHistory');
const commercialAds = require('./commercialAds');
const collectivesCardContent = require('./collectivesCardContent')
const collectivesCommissions = require('./collectivesCommissions');
const collectivesNotification = require('./collectiveNotification');
const collectivesProductList = require('./collectivesProductList');
const collectiveLanding = require('./collectiveLanding');
const contractAddress = require('./contractAddress');
const contractualPolling = require('./contractualPolling');
const onboardDocument = require('./onboardDocument');
const consentsProfile = require('./consentsProfile');
const rightsProfile = require('./rightsProfile');
const creditsCancelPregranted = require('./creditsCancelPregranted');
const creditsPregranted = require('./creditsPregranted');
const creditPregrantedLimitVariation = require('./creditPregrantedLimitVariation');
const creditsInvestment = require('./creditsInvestment');
const deposits = require('./deposits');
const depositsDetails = require('./contactCenter/depositsData');
const impositionEarlyCancellation = require('./contactCenter/impositionEarlyCancellation/impositionEarlyCancellation');
const impositionInterestRenewal = require('./contactCenter/impositionInterestRenewal.js');
const cardData = require('./contactCenter/cardData.js');
const debitReturn = require('./debitReturn');
const documents = require('./documents');
const ecommerceKeyModify = require('./ecommerceKeyModify')
const ecommerceKeyRequest = require('./ecommerceKeyRequest');
const emitedTransfers = require('./emitedTransfers');
const expensesHistogram = require('./expensesHistogram');
const expensesByCategoriesListCategories = require('./expensesByCategories/expensesByCategoriesListCategories');
const expensesByCategoriesMonthly = require('./expensesByCategories/expensesByCategoriesMonthly');
const expensesByCategoriesMovements = require('./expensesByCategories/expensesByCategoriesMovements');
const expensesByCategoriesMovementsCategory = require('./expensesByCategories/expensesByCategoriesMovementsCategory');
const expensesByCategoriesSummaryBox = require('./expensesByCategories/expensesByCategoriesSummaryBox');
const expensesByCategoriesTabSelector = require('./expensesByCategories/expensesByCategoriesTabSelector');
const fatcaDocumentation = require('./fatcaDocumentation');
const fiocDocumentation = require('./fiocDocumentation');
const fiocFormStatus = require('./fiocFormStatus');
const flagBasedFeatures = require('./flagBasedFeatures');
const globalPosition = require('./globalPosition');
const globalVariables = require('./globalVariables');
const hireCardAtHomeAbroad = require('./hireCardAtHomeAbroad');
const hireCardECard = require('./hireCardECard');
const hireCardMinicard = require('./hireCardMinicard');
const hireCardOpenCredit = require('./hireCardOpenCredit');
const hireCardNeruda = require('./hireCardNeruda');
const hireCreditCard = require('./hireCreditCardReducer');
const hireCardDiamondPack = require('./hireCardDiamondPack');
const hireCardPremiumPack = require('./hireCardPremiumPack');
const hireCardOpenDebit = require('./hireCardOpenDebit');
const hireCardMetallic = require('./hireCardMetallic');
const hireCardMaestro = require('./hireCardMaestro');
const hireCardRevolving = require('./hireCardRevolving');
const hireCardViaT = require('./hireCardViaT');
const hireCardVirtualCredit = require('./hireCardVirtualCredit');
const hireCardImageSelector = require('./hireCardImageSelector');
const hireCardNameSelector = require('./hireCardNameSelector');
const hireCardPinSet = require('./hireCardPinSet');
const hireCreditLimit = require('./hireCreditLimit');
const hireInformation = require('./hireInformation');
const hireOpenYoungInterveners = require('./hireOpenYoungInterveners');
const hireCardOpenYoung = require('./hireCardOpenYoung');
const hirePackOpenYoung = require('./hirePackOpenYoung');
const hirePaymentMethod = require('./hirePaymentMethod');
const hireTravelPlus = require('./hireTravelPlus');
const hireCardVirtualOnlyDebit = require('./hireCardVirtualOnlyDebit');
const hireWelcomeAccount = require('./hireWelcomeAccount/hireWelcomeAccount');
const hiringProductBanners = require('./hiringProductBanners');
const additionalDocumentation = require('./additionalDocumentation');
const interstitialGeneric = require('./interstitialGeneric');
const interstitialLegalAccept = require('./interstitialLegalAccept');
const liquidations = require('./liquidations');
const login = require('./login');
const logout = require('./logout');
const brokerNoStockAccountModal = require('./broker/brokerNoStockAccountModal');
const monthlyExpensesIndicator = require('./monthlyExpensesIndicator');
const sharedAccounts = require('./accounts/sharedAccounts');
const latestCardHistory = require('./contactCenter/latestCardHistory/latestCardHistory');
const creditModifyAccount = require('./contactCenter/creditCardAssociatedModifyAccount/creditCardModifyAccount');
const sendExtractsViaMail = require('./contactCenter/sendExtractsViaMail/sendExtractsViaMail');
const sendSignatureKey = require('./sendSignatureKey');
import officePrintedDocument from './officePrintedDocument';
const personeticsInboxInsights= require('./personeticsInboxInsights');
const personeticsInsightDetails = require('./personeticsInsightDetails');
const preContractualInfo = require('./preContractualInfo');
const preContractualPolling = require('./preContractualPolling');
const tutorial = require('./tutorial');
const personeticsTeaser = require('./personeticsTeaser');
const personeticsSubscription = require('./personeticsSubscription');

// @ Insurances
const { okInsuranceReducers } = require('ok-insurance');
// @ Loans
const { okLoansReducers } = require('ok-loans');

const mortgageRequestState = require('./mortgageRequest/mortgageRequestState');
const mortgageRequestManagerInfo = require('./mortgageRequest/mortgageRequestManagerInfo');
const mortgageRequestPreApproval = require('./mortgageRequest/mortgageRequestPreApproval');
const mortgageRequestAddEmail = require('./mortgageRequest/mortgageRequestAddEmail');
const mortgageRequestHolder = require('./mortgageRequest/mortgageRequestHolder');
const mortgageRequestHolderEdit = require('./mortgageRequest/mortgageRequestHolderEdit');
const mortgageRequestCoHolder = require('./mortgageRequest/mortgageRequestCoHolder');
const mortgageRequestCoHolderEdit = require('./mortgageRequest/mortgageRequestCoHolderEdit');
const mortgageRequestSummaryDocumentation = require('./mortgageRequest/mortgageRequestSummaryDocumentation');
const mortgageRequestDocumentationCenter = require('./mortgageRequest/mortgageRequestDocumentationCenter');
const mortgageRequestDataParticipants = require('./mortgageRequest/mortgageRequestDataOperativeParticipants');
const mortgageRequestDataProprietor = require('./mortgageRequest/mortgageRequestDataProprietor');
const mortgageRequestDataProprietorDelete = require('./mortgageRequest/mortgageRequestDataProprietorDelete');
const mortgageRequestTargetDate = require('./mortgageRequest/mortgageRequestTargetDate');
const mortgageRequestProgressBar = require('./mortgageRequest/mortgageRequestProgressBar');
const mortgageRequestInmuebles = require('./mortgageRequest/mortgageRequestInmuebles');
const mortgageRequestWorkData = require('./mortgageRequest/mortgageRequestWorkData');
const mortgageRequestHolderEconomic = require('./mortgageRequest/mortgageRequestHolderEconomic');
const mortgageRequestHolderEconomicEdit = require('./mortgageRequest/mortgageRequestHolderEconomicEdit');
const mortgageRequestMortgageData = require('./mortgageRequest/mortgageRequestMortgageData');
const mortgageRequestMortgageExtraData = require('./mortgageRequest/mortgageRequestMortgageExtraData');
const mortgageRequestHolderConfirmDocuments = require('./mortgageRequest/mortgageRequestHolderConfirmDocuments');
const mortgageRequestHolderConfirmEdit = require('./mortgageRequest/mortgageRequestHolderConfirmEdit');
const mortgageRequestSimpleNote = require('./mortgageRequest/mortgageRequestSimpleNote');
const mortgageRequestPropertyValue = require('./mortgageRequest/mortgageRequestPropertyValue');
const mortgageRequestExpensesProvision = require('./mortgageRequest/mortgageRequestExpensesProvision');
const mortgageRequestPanelTitle = require('./mortgageRequest/mortgageRequestPanelTitle');
const mortgageRequestEstates = require('./mortgageRequest/mortgageRequestEstates');
const mortgageRequestEstatesData = require('./mortgageRequest/mortgageRequestEstatesData');
const mortgageRequestSmsCode = require('./mortgageRequest/mortgageRequestSmsCode');
const mortgageRequestMortgageDenial = require('./mortgageRequest/mortgageRequestMortgageDenial');
const mortgageRequestConditions = require('./mortgageRequest/mortgageRequestConditions');
const mortgageRequestConditionsAccept = require('./mortgageRequest/mortgageRequestConditionsAccept');
const mortgageRequestConditionsType = require('./mortgageRequest/mortgageRequestConditionsType');
const mortgageRequestSimulationLinkClient = require('./mortgageRequest/mortgageRequestSimulationLinkClient');
const mortgageRequestPropertyValueDefined = require('./mortgageRequest/mortgageRequestPropertyValueDefined');
const mortgageRequestOperativeGeneralData = require('./mortgageRequest/mortgageRequestOperativeGeneralData');
const mortgageRequestDataParticipantsState = require('./mortgageRequest/mortgageRequestDataOperativeParticipants');
const mortgageRequestOperativeGeneralDataAmortization = require('./mortgageRequest/mortgageRequestOperativeGeneralDataAmortization');
const mortgageRequestActivities = require('./mortgageRequest/mortgageRequestActivities');
const mortgageHoldersDataValidation = require('./mortgageRequest/mortgageHoldersDataValidation');
const mortgageRequestViewProcess = require('./mortgageRequest/mortgageRequestViewProcess');
const mortgageRequestLocationData = require('./mortgageRequest/mortgageRequestLocationData');
const mortgageRequestSimpleNoteRequest = require('./mortgageRequest/mortgageRequestSimpleNoteRequest');
const mortgageRequestPayment = require('./mortgageRequest/mortgageRequestPayment');
const mortgageRequestUnpaidFees = require('./mortgageRequest/mortgageRequestUnpaidFees');
const mortgageRequestAssets = require('./mortgageRequest/mortgageRequestAssets');
const mortgageRequestAppraisalRequest = require('./mortgageRequest/mortgageRequestAppraisalRequest');
const mortgageRequestMovingExpenses = require('./mortgageRequest/mortgageRequestMovingExpenses');
const mortgageRequestManagerInsuranceRelated = require('./mortgageRequest/mortgageRequestManagerInsurancesRelated');
const mortgageRequestTaxesInformation = require('./mortgageRequest/mortgageRequestTaxesInformation');
const mortgageRequestCollectiveTag = require('./mortgageRequest/mortgageRequestCollectiveTag');
const movements = require('./movements');
const morningStarInvestmentData = require('./morningStarInvestmentData');
const myProfileShippingAddress = require('./myProfileShippingAddress');
const myProfileSecurityKitChange = require('./myProfileSecurityKitChange');
const myProfileKeysSignature = require('./myProfileKeysSignature');
const myProfileKeysTpps = require('./myProfileKeysTpps/myProfileKeysTpps');
const myProfileUploadDocumentation = require('./myProfileUploadDocumentation');
const myWalletCharts = require('./myWallet');
const notifications = require('./notifications');
const ngoRetrieveList = require('./ngoRetrieveList');
import offers from './offersReducers';
const openYoungCardRejectChore = require('./openYoungCardRejectChore');
const openYoungBudget = require('./openYoungBudget');
const openYoungCardChoreStatusUpdateInfo = require('./openYoungCardChoreStatusUpdate');
const openYoungCardPaymentChore = require('./openYoungCardPaymentChore');
const openYoungCardChores = require('./openYoungCardChores');
const openYoungCardExpenses = require('./openYoungCardExpenses');
const openYoungDashboard = require('./openYoungDashboard');
const openYoungDashboardChildDetails = require('./openYoungDashboardChildDetails');
const cardPeriodicLoads = require('./cardPeriodicLoads');
const openYoungCardMoneyRequest = require('./openYoungCardMoneyRequest');
const openYoungCardSavings = require('./openYoungCardSavings');
const hireContactData = require('./hireContactData');
const openYoungDocumentation = require('./openYoungDocumentation');
const openYoungHistory = require('./openYoungHistory');
const openYoungPersonalData = require('./openYoungPersonalData');
const openYoungProfileDetails = require('./openYoungProfileDetails');
const openYoungProfiles = require('./openYoungProfiles');
const openYoungTaxData = require('./openYoungTaxData');
const openYoungTutorType = require('./openYoungTutorType');
const uploadDocumentsOpenYoung = require('./uploadDocumentsOpenYoung');
const pass = require('./pass');
const personeticsDeepLinks= require('./personeticsDeepLinks');
const personeticsVirtualOffices = require('./personeticsVirtualOffices');
const productAds = require('./productAds');
const profile = require('./profile/profile');
const profileEditor = require('./profileEditor');
const creditCardPayLaterInfo = require('./creditCardPayLaterInfo');
const receipts = require('./accountDebits/receipts');
const accountReceiptDownload = require('./accountReceiptDownload');
const receivedTransfers = require('./receivedTransfers');
const referrals = require('./referrals');
const sendReferrals = require('./sendReferrals');
const sadde = require('./sadde.js');
const okApiDocuments = require('./okApiDocuments');
const scheduledTransfers = require('./scheduledTransfers');
const screen = require('./screen');
const signaturePattern = require('./signaturePattern.js');
const signaturePatternVirtualOffices = require('./signaturePatternVirtualOffices');
const tooltips = require('./tooltip');
const taxAndRents = require('./taxAndRents');
const taxAndRentsPaymentWithDraft = require('./taxAndRentsPaymentWithDraft');
const termsAndCondsInfo = require('./termsAndCondsInfo');
const termsAndCondsLegalWindow = require('./termsAndCondsLegalWindow');
const transfer = require('./transfer');
const reimburseInvestment = require('./reimburseInvestment');
const updateClientContactTime = require('./contactCenter/updateClientContactTime/updateClientContactTime')
const userNotifications = require('./userNotifications');
const beneficiaries = require('./beneficiaries');
const addBeneficiary = require('./addBeneficiary');
const voiceReader = require('./voiceReader');
const contracts = require('./contracts');
const ibanModal = require('./ibanModal');
const investmentFunds = require('./broker/investmentFunds');
const investmentFundsWithContribution = require('./broker/investmentFundsWithContribution');
const contributionFunds = require('./broker/contributionFunds');
const externalTransferFund = require('./externalTransferFund');
const externalTransferValues = require('./externalTransferValues');
const userInvestmentsContracts = require('./userInvestmentsContracts');
const categoryUpdate = require('./categoryUpdate');
const brokerTransferExternalFunds = require('./broker/brokerTransferExternalFunds');
const brokerAllIndexes = require('./brokerAllIndexes');
const brokerRequestFinancialOperationChange = require('./broker/brokerRequestFinancialOperationChange');
const okDiscounts = require('./okDiscounts');
const brokerStocksSaleOrder = require('./broker/brokerStocksSaleOrder');
const brokerStocksPurchaseOrder = require('./broker/brokerStocksPurchaseOrder');
const brokerCashCharge = require('./broker/brokerCashCharge');
const promotions = require('./promotions');
const brokerSubscription = require('./broker/brokerSubscription');
const brokerDerivativesMovements = require('./broker/brokerDerivativesMovements');
const brokerCreateTransferOfDerivatives = require('./broker/brokerCreateTransferOfDerivatives');
const brokerHireDerivativesAccount = require('./broker/brokerHireDerivativesAccount');
const brokerPensionPlanContribution = require('./broker/brokerPensionPlanContribution');
const userData = require('./contactCenter/userData');
const codeOtp = require('./contactCenter/codeOtp');
const recoveryKeys = require('./contactCenter/recoveryKeys');
const brokerPensionPlanBeneficiaries = require('./broker/brokerPensionPlanBeneficiaries');
const brokerFundLegalDocument = require('./broker/brokerFundLegalDocument');
const brokerPlanLegalDocument = require('./broker/brokerPlanLegalDocument');
const authorizeBizum = require('./contactCenter/authorizeBizum');
const sendN1Password = require('./sendN1Password');
const sendN2Password = require('./sendN2Password');
const sendPinByMail = require('./sendPinByMail');
const preContractualMail = require('./contactCenter/preContractualMailInfo/preContractualMailInfo');
const precontractualDocumentMailer = require('./precontractualDocumentsMailer');
const cancelPostPayment = require('./cancelPostPayment');
const cancelBizum = require('./contactCenter/bizum/cancelBizum');
const customerFinder = require ('./contactCenter/customerFinder');
const gapAccess = require('./contactCenter/gapAccess/gapAccess');
const raiseToN2 = require('./contactCenter/raiseToN2/raiseToN2');
const passwordPositions = require ('./contactCenter/passwordPositions');
const cardRequestAdditional = require ('./contactCenter/cardRequestAdditional');
const cardPinReset = require('./contactCenter/cardPinReset');
const fundLegalConfirmation = require('./contactCenter/fundLegalConfirmation');
const pensionPlanLegalConfirmation = require('./contactCenter/pensionPlanLegalConfirmation');
const registerLoginError = require('./contactCenter/registerLoginError');
const depositChangeAccount = require('./contactCenter/depositChangeAccount');
const brokerPensionPlanPeriodicalContribution = require('./broker/brokerPensionPlanPeriodicalContribution');
const accountPaymentPurchaseDeferralReducer = require('./accountPaymentPurchaseDeferral');
const accountDetailsCertificates = require('./accountDetailsCertificates');
const devices = require('./devices');
const devicesBlockTrusted = require('./devicesBlockTrusted');
const creditWarranties = require('./contactCenter/creditWarranties');
const accountPrecautionaryWarnings = require('./contactCenter/accountPrecautionaryWarnings');
const gdprConsents = require('gdpr-consents/reducers/gdprConsents');
const translations = require('./translations');
const precontractualsMergePdfs = require('./mergePDFDocuments');
const revolvingDocumentsPdfs = require('./revolvingDocuments');
const revolvingPersistentData = require('./revolvingPersistentData');
const modifyCommerceAndATMLimits = require('./contactCenter/modifyCommerceAndATMLimits/modifyCommerceAndATMLimits');
const modifyMainPhone = require('./contactCenter/modifyMainPhone/modifyMainPhone');
const hiringProductInformation = require('./hiringProductInformation');
const disasterRecoveryAgentTokenRenewal = require('./contactCenter/disasterRecoveryAgentTokenRenewal/disasterRecoveryAgentTokenRenewal');
const brokerLegalDocumentation = require('./broker/brokerLegalDocumentation');
const printer = require('./contactCenter/printer');
const shippingAddress = require('./shippingAddress');
const topbar = require('./topbar');
const collectives = require('./collectives');
const footer = require('./footer');
const registerDocuments = require('./registerDocuments');
const depositsRenovationCancel = require('./depositsRenovationCancel')

const routing = require('./routing');

import { okWizard } from './okWizard';
import { okWizardSolidarity } from './okWizardSolidarity';

const {
    RAAdditionalContributionReducer,
    RAAlertsReducer,
    RAFeatureFlagReducer,
    RACancelPeriodicContributionsReducer,
    RACancelPlanReducer,
    RAAccountDetailsReducer,
    RATimeHorizonCheckReducer,
    RACheckStatusReducer,
    RACustomPlanByObjectiveReducer,
    RAExpectedProfitabilityReducer,
    RAGlobalPositionReducer,
    RAHireManagementPlanReducer,
    RALastMovementReducer,
    RAManagementPlanReducer,
    RAPortfolioDetailsReducer,
    RAPortfolioDistributionReducer,
    RAPortfoliosNameHistoryReducer,
    RAProjectionChartOjectiveTrackingReducer,
    RAEvolutionChartOjectiveTrackingReducer,
    RAPortfolioEvolutionReducer,
    RARefundRequestReducer,
    RASaveSimulationReducer,
    RASetGoalReducer,
    RASimulationReducer,
    RASuitabilityTestReducer,
    RAWizardReducer,
    RAObjectiveDetailsReducer,
    RAReimbursementReducer,
    RAAssociateObjectiveReducer,
    RADissociateObjectiveReducer
} = require('wealth-robo');

// @Investments features reducers
const mifid = require('mifid/reducers');
const brokerCustomWallets = require('customWallets/reducers');

const webAppReducers = {
    accessKeyChange,
    accountAddDocumentation,
    accountCancelChequebook,
    accountCancelCheques,
    accountChequebookAdditionalInformation,
    accountDebitAnnulation,
    accountDebits,
    accountDebitsEdit,
    accountDirectDebits,
    accountDetailsCertificates,
    accountIntervenerRemoval,
    accountNewChequebook,
    accountPaymentPurchaseDeferralReducer,
    accounts,
    accountsCheques,
    accountPrecautionaryWarnings,
    accountReceiptDownload,
    accountsRetentions,
    accountsSettlements,
    accountsTransactions,
    accountTransactionsDownload,
    amazonGiftCards,
    accountWarningMessage,
    accountsBuyAmazonGiftCard,
    authorizations,
    authorizationActivation,
    authorizationModifyAccount,
    addBeneficiary,
    additionalDocumentation,
    aggregatorAddEntity,
    aggregatorAddEntityPSD2,
    aggregatorConsentsPSD2,
    aggregatorChangeConsents,
    aggregatorData,
    addImpositionDeposit,
    advancedInterstitialPromotions,
    agentAuthentication,
    applyFor,
    authorizeBizum,
    bankEntity,
    bannerUpdate,
    beneficiaries,
    benefits123,
    biocatch,
    bizumConfirmation,
    brokerAdvancedSearch,
    brokerAlerts,
    brokerAlertsConditions,
    brokerAlertsConfig,
    brokerAllIndexes,
    brokerAttentionPlanStartingModal,
    brokerCashCharge,
    brokerCheckingAccounts,
    brokerClientWarningModal,
    brokerContractActivities,
    brokerContractRejectedActivities,
    brokerContractValues,
    brokerContractState,
    brokerCorporatesOperations,
    brokerCreateTransferOfDerivatives,
    ...brokerCustomWallets,
    brokerDerivativesMovements,
    brokerError,
    brokerETFBuy,
    brokerETFsProduct,
    brokerETFsSell,
    brokerExchangeIndexes,
    brokerFundamentalAnalysisSearch,
    brokerFundLegalDocument,
    brokerFundOrderCancel,
    brokerHireDerivativesAccount,
    brokerHirePensionPlan,
    brokerIAPredictionDetail,
    brokerIAPredictionMarkets,
    brokerIAPredictionSearch,
    brokerIndexes,
    brokerInformativePopUp,
    brokerInternalTransferFunds,
    brokerInterveners,
	brokerInvestmentFunds,
    brokerInvestmentFundView,
    brokerIsUsPerson,
    //brokerPensionPlan,
    //brokerPensionPlanView,
    brokerInvestmentSpecialists,
    brokerLandingLoader,
    brokerLegalDocumentation,
    brokerJointContracts,
    brokerKnowTheseStocks,
    brokerMarketConsensus,
    brokerMarketsCalendar,
    brokerMarketsPulses,
    brokerMarketsTrend,
    brokerMostPopularOfMonth,
    brokerMyInvestments,
    brokerMyInvestmentWidget,
    brokerMarkets,
    brokerNews,
    brokerOpenTrader,
    brokerPensionPlanCancelPendingOrder,
    brokerOrderCancel,
    brokerOrdersAndMovements,
    brokerPensionPlanBeneficiaries,
    brokerPensionPlanContribution,
    brokerPensionPlanPeriodicalContribution,
    brokerPensionPlans,
    brokerPeriodicalFundsContributions,
    brokerPlanLegalDocument,
    brokerProductFile,
    brokerRequestFinancialOperationChange,
    brokerRightsBuy,
    brokerRightsSell,
    brokerRightsCashExchange,
    brokerSearch,
    brokerSimpleSearch,
    brokerSearchWidget,
    brokerProductAdvancedSearch,
    brokerProductFiltersSearch,
    brokerSearchTagging,
    brokerStocksAdvancedBuy,
    brokerStocksAdvancedSell,
    brokerStocksBuy,
    brokerStocksContract,
    brokerStocksContractForm,
    brokerStocksPurchaseOrder,
    brokerStocksSaleOrder,
    brokerStocksSell,
    brokerSubscription,
    brokerSpinOffModal,
    brokerTickerExchangeIndexes,
    brokerTodayNews,
    brokerTodaysMainStocks,
    brokerTransferPensionPlansInternal,
    brokerTransferPensionPlansExternal,
    brokerTransferExternalFunds,
    brokerTransferExternalValues,
    brokerTransferValues,
    brokerValueMovements,
    brokerWallets,
    brokerWatchlist,
    brokerWarrantsAdvancedBuy,
    brokerWarrantsAdvancedSell,
    brokerWarrantsBuy,
    brokerWarrantsFile,
    brokerWarrantsSell,
    cancelBizum,
    cancelCardPayLater,
    cancelCreditCardRenewal,
    cancelPostPayment,
    cardActivation,
    cardActivationR42,
    cardBenefits,
    cardBlock,
    cardChangePaymentMethod,
    cardCharge,
    cardControlOn,
    cardData,
    cardDepositMoney,
    cardDownloadPdf,
    cardPinChange,
    cardMobileRecharge,
    cardModifyLimit,
    cardModifyDebitLimits,
    cardMovementInstallmentChart,
    cardControl,
    cardPayLater,
    cardRewards,
    cardsCanceled,
    cardPaymentPurchaseDeferral,
    cardPaymentPurchaseDeferralLavanda,
    cardPinReset,
    cardReplace,
    cardRequestAdditional,
    cardTransferMoneyToCheckingAccount,
    cardTravelOn,
    cards,
    cardsCancelPayLater,
    cardsControlHistory,
    cardCancelDeferPurchase,
    cardPeriodicLoads,
    cardsPaymentCycle,
    cardSecurePayment,
    cardsStatementDetails,
    cardsStatementMovements,
    cardsStatements,
    cardsTransactions,
    cardsTransactionsDownload,
    cardTransferMoney,
    categoryUpdate,
    changeReceiptAccount,
    cm,
    cmLegacy,
    cmLegalDocuments,
    commercialAds,
    collectives,
    collectivesCardContent,
    collectivesCommissions,
    collectivesNotification,
    collectivesProductList,
    collectiveLanding,
    consentsProfile,
    contractAddress,
    contracts,
    contractualPolling,
    creditCardPayLaterInfo,
    creditPregrantedLimitVariation,
    creditsCancelPregranted,
    creditsInvestment,
    creditsPregranted,
    creditWarranties,
    creditModifyAccount,
    codeOtp,
    customerFinder,
    debitReturn,
    depositChangeAccount,
    deposits,
    depositsRenovationCancel,
    devices,
    devicesBlockTrusted,
    depositsDetails,
    disasterRecoveryAgentTokenRenewal,
    documents,
    ecommerceKeyModify,
    ecommerceKeyRequest,
    emitedTransfers,
    expensesHistogram,
    expensesByCategoriesListCategories,
    expensesByCategoriesMonthly,
    expensesByCategoriesSummaryBox,
    expensesByCategoriesTabSelector,
    expensesByCategoriesMovements,
    expensesByCategoriesMovementsCategory,
    externalTransferFund,
    externalTransferValues,
    fatcaDocumentation,
    filters,
    fiocDocumentation,
    fiocFormStatus,
    fundLegalConfirmation,
    impositionEarlyCancellation,
    impositionInterestRenewal,
    flagBasedFeatures,
    footer,
    gapAccess,
    gdprConsents,
    globalPosition,
    globalVariables,
    glossary,
    hireCardAtHomeAbroad,
    hireCardECard,
    hireCardMinicard,
    hireCardOpenCredit,
    hireCardNeruda,
    hireCreditCard,
    hireCardDiamondPack,
    hireCardPremiumPack,
    hireCardMetallic,
    hireCardMaestro,
    hireCardOpenDebit,
    hireCardRevolving,
    hireCardViaT,
    hireCardVirtualCredit,
    hireCardImageSelector,
    hireCardNameSelector,
    hireCardPinSet,
    hireContactData,
    hireCreditLimit,
    hireInformation,
    hireOpenYoungInterveners,
    hireCardOpenYoung,
    hirePackOpenYoung,
    hirePaymentMethod,
    hireTravelPlus,
    hireCardVirtualOnlyDebit,
    hireWelcomeAccount,
    hiringProductBanners,
    hiringProductInformation,
    ibanModal,
    interstitialGeneric,
    interstitialLegalAccept,
    investmentFunds,
    investmentFundsWithContribution,
    contributionFunds,
    landings,
    latestCardHistory,
    liquidations,
    loginAsClient,
    logout,
    brokerNoStockAccountModal,
    ...mifid,
    monthlyExpensesIndicator,
    modifyCommerceAndATMLimits,
    modifyMainPhone,
    morningStarInvestmentData,
    mortgageRequestActivities,
    mortgageHoldersDataValidation,
    mortgageRequestAddEmail,
    mortgageRequestAppraisalRequest,
    mortgageRequestAssets,
    mortgageRequestCoHolder,
    mortgageRequestCoHolderEdit,
    mortgageRequestConditions,
    mortgageRequestConditionsAccept,
    mortgageRequestConditionsType,
    mortgageRequestDataParticipants,
    mortgageRequestDataParticipantsState,
    mortgageRequestDataProprietor,
    mortgageRequestDataProprietorDelete,
    mortgageRequestDocumentationCenter,
    mortgageRequestEstates,
    mortgageRequestEstatesData,
    mortgageRequestExpensesProvision,
    mortgageRequestHolder,
    mortgageRequestHolderConfirmDocuments,
    mortgageRequestHolderConfirmEdit,
    mortgageRequestHolderEconomic,
    mortgageRequestHolderEconomicEdit,
    mortgageRequestHolderEdit,
    mortgageRequestInmuebles,
    mortgageRequestLocationData,
    mortgageRequestManagerInfo,
    mortgageRequestMortgageData,
    mortgageRequestMortgageDenial,
    mortgageRequestMortgageExtraData,
    mortgageRequestMovingExpenses,
    mortgageRequestTaxesInformation,
    mortgageRequestCollectiveTag,
    mortgageRequestOperativeGeneralData,
    mortgageRequestOperativeGeneralDataAmortization,
    mortgageRequestPanelTitle,
    mortgageRequestPayment,
    mortgageRequestPreApproval,
    mortgageRequestProgressBar,
    mortgageRequestPropertyValue,
    mortgageRequestPropertyValueDefined,
    mortgageRequestSimpleNote,
    mortgageRequestSimpleNoteRequest,
    mortgageRequestSimulationLinkClient,
    mortgageRequestSmsCode,
    mortgageRequestState,
    mortgageRequestSummaryDocumentation,
    mortgageRequestTargetDate,
    mortgageRequestUnpaidFees,
    mortgageRequestViewProcess,
    mortgageRequestWorkData,
    mortgageRequestManagerInsuranceRelated,
    movements,
    myProfileShippingAddress,
    myProfileKeysSignature,
    myProfileKeysTpps,
    myProfileSecurityKitChange,
    myProfileUploadDocumentation,
    myWalletCharts,
    ngoRetrieveList,
    offers,
    officePrintedDocument,
    okApiDocuments,
    okChat: chat,
    okDiscounts,
    ...okInsuranceReducers,
    ...okLoansReducers,
    okLogin: login,
    okNotifications: notifications,
    okWizard,
    okWizardSolidarity,
    onboardDocument,
    openYoungBudget,
    openYoungCardChores,
    openYoungCardChoreStatusUpdateInfo,
    openYoungCardCreateChore,
    openYoungCardExpenses,
    openYoungDashboard,
    openYoungDashboardChildDetails,
    openYoungDocumentation,
    openYoungProfileDetails,
    openYoungProfiles,
    openYoungCardMoneyRequest,
    openYoungCardSavings,
    openYoungCardModifyChore,
    openYoungCardPaymentChore,
    openYoungCardRejectChore,
    openYoungHistory,
    openYoungPersonalData,
    openYoungTaxData,
    openYoungTutorType,
    pass,
    personeticsDeepLinks,
    personeticsInboxInsights,
    personeticsInsightDetails,
    personeticsSubscription,
    personeticsTeaser,
    personeticsVirtualOffices,
    preContractualInfo,
    preContractualPolling,
    precontractualsMergePdfs,
    passwordPositions,
    pensionPlanLegalConfirmation,
    preContractualMail,
    precontractualDocumentMailer,
    printer,
    productAds,
    profile,
    profileEditor,
    promotions,
    raAdditionalContribution: RAAdditionalContributionReducer,
    raCancelPeriodicContributions: RACancelPeriodicContributionsReducer,
    raCancelPlan: RACancelPlanReducer,
    raAccountDetails: RAAccountDetailsReducer,
    raAlertsReducer: RAAlertsReducer,
    raFeatureFlag : RAFeatureFlagReducer,
    raCheckStatus: RACheckStatusReducer,
    raCustomPlanByObjective: RACustomPlanByObjectiveReducer,
    raExpectedProfitability: RAExpectedProfitabilityReducer,
    raGlobalPosition: RAGlobalPositionReducer,
    raHireManagementPlan: RAHireManagementPlanReducer,
    raLastMovement: RALastMovementReducer,
    raManagementPlan: RAManagementPlanReducer,
    raPortfolioDistribution: RAPortfolioDistributionReducer,
    raPortfoliosNameHistory: RAPortfoliosNameHistoryReducer,
    raProjectionChartOjectiveTrackingReducer: RAProjectionChartOjectiveTrackingReducer,
    raEvolutionChartOjectiveTrackingReducer: RAEvolutionChartOjectiveTrackingReducer,
    raObjectiveDetailsReducer: RAObjectiveDetailsReducer,
    raPortfolioEvolution: RAPortfolioEvolutionReducer,
    raPortfolioDetails: RAPortfolioDetailsReducer,
    raEvolutionChartOjectiveTracking: RAEvolutionChartOjectiveTrackingReducer,
    raRefundRequest: RARefundRequestReducer,
    raSaveSimulation: RASaveSimulationReducer,
    raSetGoal: RASetGoalReducer,
    raSimulation: RASimulationReducer,
    raSuitabilityTest: RASuitabilityTestReducer,
    raTimeHorizonCheck: RATimeHorizonCheckReducer,
    raWizard: RAWizardReducer,
    raReimbursement: RAReimbursementReducer,
    raAssociateObjective: RAAssociateObjectiveReducer,
    raDissociateObjective: RADissociateObjectiveReducer,
    raiseToN2,
    recoveryKeys,
    receipts,
    receivedTransfers,
    referrals,
    reimburseInvestment,
    revolvingDocumentsPdfs,
    revolvingPersistentData,
    rightsProfile,
    registerLoginError,
    routing,
    sadde,
    scheduledTransfers,
    screen,
    sendExtractsViaMail,
    sendReferrals,
    sharedAccounts,
    signaturePattern,
    signaturePatternVirtualOffices,
    sendN1Password,
    sendN2Password,
    sendPinByMail,
    sendSignatureKey,
    taxAndRents,
    taxAndRentsPaymentWithDraft,
    termsAndCondsInfo,
    termsAndCondsLegalWindow,
    tooltips,
    topbar,
    transfer,
    translations,
    tutorial,
    updateClientContactTime,
    uploadDocumentsOpenYoung,
    userInvestmentsContracts,
    userNotifications,
    userData,
    voiceReader,
    shippingAddress,
    registerDocuments
}; //Please keep these arguments alphabetically ordered

const finalReducers = Object.assign(webAppReducers, okInsuranceReducers, okLoansReducers);
const appReducer = combineReducers(finalReducers);
const doNotResetReducers = [
    'disasterRecoveryAgentTokenRenewal',
    'translations',
    'routing',
    'screen',
]; //screen reducer should only be affected by a resize action

const rootReducer = (state, action) => {
    if (action.type === actionTypes.LOGOUT_SUCCESS) {
        /*
            Fallback each reducer to its initial state except of doNotResetReducers list
        */
        const filterState = {};
        doNotResetReducers.forEach(name => {
            filterState[name] = state[name];
        });
        state = filterState;
    }

    return appReducer(state, action);
};

module.exports = {
    rootReducer,
    webAppReducers
};
