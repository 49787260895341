module.exports = {
    title: 'contractsView-contractsView_precontractualConditionsTitle',
    order: 2,
    footer_text: '',
    position: 'middle',
    disableNext: 'depositExpired_pollingNextButtonDisabled',
    on_next_click: 'depositExpired_loadTermsAndConditionsLegalWindow',
	components: [
        {
            id_component: 'step2-dic-viewer',
        }
	]
};