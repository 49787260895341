// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');
// @ constants
const actionTypes = require('constants/actionTypes');
const { FIRST_STEP, SECOND_STEP } = require('constants/index');
// @ reducers
const contributionFundsStep1 = require('./contributionFunds/contributionFundsStep1');
const contributionFundsStep2 = require('./contributionFunds/contributionFundsStep2');
// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');

function setInitialState(funds = []) {
    return Immutable.Map().merge({
        steps: [
            contributionFundsStep1(undefined, { type: null }),
            contributionFundsStep2(undefined, { type: null })
        ],
        list: Immutable.List(funds),
        contributionFunds: Immutable.fromJS({}),
        errorReceived: '',
        isFetching: false,
        isFetchingPre: false,
        isFetchedPre: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        notFound: false,
        holding: {
            numberOfHoldings: 0,
            currency: '',
            amount: 0
        },
        fetchingDataExt: false,
        fetchDataExt: [],
        fetchDataExtSuccess: false,
        fetchDataExtError: false,
        enableEconomicReport: true,
    });
}

function updateStep1(steps, action) {
    return steps.update(FIRST_STEP, step => contributionFundsStep1(step, action));
}

function updateStep2(steps, action) {
    return steps.update(SECOND_STEP, step => contributionFundsStep2(step, action));
}

function pad(num, size) {
    return (Math.pow(10, size) + ~~num).toString().substring(1);
}

function isAdditionalSubscription(immState) {
    return immState.getIn(['steps', FIRST_STEP, 'isSubscription']);
}

function getMinimumAmount(immState) {
    if (isAdditionalSubscription(immState)) {
        const minimumAmountForAdditionalSubscription = immState.getIn([
            'steps',
            FIRST_STEP,
            'contributionFunds',
            'customMinimumPurchaseAmount',
            'additionalSubscriptionAmount'
        ]);
        return minimumAmountForAdditionalSubscription;
    }

    const minimumAmountForInitialSubscription = immState.getIn([
        'steps',
        FIRST_STEP,
        'contributionFunds',
        'customMinimumPurchaseAmount',
        'amount'
    ]);
    return minimumAmountForInitialSubscription;
}

function getMinimumAmountCurrency(immState) {
    return immState.getIn([
        'steps',
        FIRST_STEP,
        'contributionFunds',
        'customMinimumPurchaseAmount',
        'currency'
    ]);
}

function updateMinimumAmounts(immState) {
    const minimumAmount = getMinimumAmount(immState);
    const minimumAmountCurrency = getMinimumAmountCurrency(immState);

    return immState.mergeDeepIn(['steps', FIRST_STEP], {
        customMinimumPurchaseAmount: {
            amount: minimumAmount,
            currency: minimumAmountCurrency
        }
    });
}

function getHiredFundByContractAndProduct(hiredFunds, contract, product) {
    const cleanContract = trim(contract);
    const cleanProduct = trim(product);

    return find(hiredFunds, (hiredFund) => {
        const fundContract = trim(get(hiredFund, 'contractNumber'));
        const fundProduct = trim(get(hiredFund, 'product'));

        return fundContract === cleanContract && fundProduct === cleanProduct;
    });
}

function getControlDigitFromAdditionalSubscription(immState, hiredFunds) {
    const subscribedFundContract = immState.getIn(['contractFund', 'contractNumber']);
    const subscribedFundProduct = immState.getIn(['contractFund', 'product']);
    const subscribedFund = getHiredFundByContractAndProduct(
        hiredFunds,
        subscribedFundContract,
        subscribedFundProduct
    );

    return trim(get(subscribedFund, 'controlDigit'));
}

function successFundAdditionalSubscription(immState, hiredFunds) {
    const subscribedFundControlDigit = getControlDigitFromAdditionalSubscription(
        immState,
        hiredFunds
    );

    return immState.mergeDeep({
        contractFund: {
            controlDigit: subscribedFundControlDigit
        },
        submitSuccessful: true,
        inProgress: false,
        isFetching: false
    });
}

const documents = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    let newState;
    switch (action.type) {
        case actionTypes.BROKER_CONTRIBUTION_FUNDS_SHOW_LOADING:
            return state.merge({
                isFetching: action.payload
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_CONTRACT_NUMBER:
            return state.mergeIn(['steps', 0], {
                originFundPurchased: {
                    contractNumber: action.payload.contractNumber,
                    product: action.payload.product,
                }
            });
        case actionTypes.FETCH_BROKER_CONTRIBUTION_FUNDS_REQUEST:
            return state.merge({
                isFetching: true
            });
        case actionTypes.FETCH_BROKER_CONTRIBUTION_FUNDS_SUCCESS:
            const contributionFund = action.payload.contributionFunds.rows[0];
            const securityDetails = action.payload.securityDetails[0];
            const type = contributionFund.CustomInstitutionSecurityId.substring(0, 3);
            const subtype = contributionFund.CustomInstitutionSecurityId.substring(4, 7);
            const reference = pad(contributionFund.CustomInstitutionSecurityId.substring(8, 9), 7);
            const trailingPerformanceLength = securityDetails.TrailingPerformance.length;
            const trailingPerformanceArray = securityDetails.TrailingPerformance[trailingPerformanceLength - 1].Return;
            const variationPercentObject = trailingPerformanceArray.find(value => value.TimePeriod === "D1");
            const variationPercent = !!variationPercentObject ? variationPercentObject.Value : 0;
            const netAssetObject = securityDetails.TrailingPerformance[0].Return.find(value => value.TimePeriod === "ClosePrice");
            const netAsset = !!netAssetObject ? netAssetObject.Value : 0;
            const variationPoints =
                parseFloat(variationPercent) *
                parseFloat(netAsset) / 100;
            const netAssetValueAmount = Number(netAsset) || 0;

            const currency = securityDetails.Currency.Id;

            newState = state.mergeDeepIn(['steps', 0], {
                customMinimumPurchaseAmount: {
                    currency: currency
                },
                contributionFunds: {
                    manager: trim(get(contributionFund, 'AdministratorCompanyName')),
                    customMinimumPurchaseAmountUnit: contributionFund.CustomMinimumPurchaseAmountUnit,
                    customCategoryId3: contributionFund.CustomCategoryId3,
                    customInstitutionSecurityId: contributionFund.CustomInstitutionSecurityId,
                    name: contributionFund.Name,
                    isin: contributionFund.ISIN,
                    secId: contributionFund.SecId,
                    rentability: contributionFund.ReturnM0 || 0,
                    netAssetValue: {
                        dayEndDate: securityDetails.TrailingPerformance[0].Date,
                        currency: securityDetails.Currency.Id,
                        amount: netAssetValueAmount
                    },
                    variationPoints: variationPoints,
                    variationPercent: variationPercent,
                    type,
                    subtype,
                    reference
                },
                error: '',
                notFound: false,
                isFetching: false,
                fetchingDocumentsValidate: false,
                fetchingEconomicReportValidation: false,
            });

            return updateMinimumAmounts(
                newState.set(
                    'contributionFunds',
                    newState.getIn(['steps', FIRST_STEP, 'contributionFunds'])
                )
            );
        case actionTypes.FETCH_BROKER_CONTRIBUTION_FUNDS_FAILURE:
            newState = state.merge({
                error: action.payload.error,
                notFound: false,
                isFetching: false
            });
            return newState.mergeIn(['steps', 1], { valid: false });
        case actionTypes.FETCH_BROKER_CONTRIBUTION_FUNDS_NOT_FOUND:
            return state.merge({
                error: action.payload.error,
                notFound: true,
                isFetching: false
            });
        case actionTypes.FETCH_BROKER_GET_ACCOUNTS_CONTRIBUTION_SUCCESS:
            const minimumPurchaseAmount = {
                amount:  action.payload.data.minimumPurchase.amount || 0,
                currency: action.payload.data.minimumPurchase.currency,
                additionalSubscriptionAmount: action.payload.data.minimumPurchase.additionalSubscriptionAmount || 0
            }
            const ibanList = action.payload.data.listaCuentasCorrientes.dato.map(account => {
                const iban = {
                    country: trim(account.contratoUnificado.pais),
                    controlDigit: trim(account.contratoUnificado.digitodecontrol),
                    codbban: trim(account.contratoUnificado.codbban)
                };
                return generateAccountId(iban);
            });
            newState = state.mergeIn(['steps', FIRST_STEP], {
                fundAccounts: ibanList
            }).mergeIn(['steps', FIRST_STEP, 'contributionFunds', 'customMinimumPurchaseAmount'], minimumPurchaseAmount);
            return updateMinimumAmounts(
                newState.merge({
                    isFetching: false
                })
            );

        case actionTypes.BROKER_WIZARD_CONTRIBUTION_RESET:
            return setInitialState(state.get('list'));
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_CHANGE_WILL_CANCEL:
            return state.merge({
                willLeave: true
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = partialSteps.get(0).get('valid') ? 2 : 1;
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = 2;
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_CHANGE_SUCCESS:
            return successFundAdditionalSubscription(
                state,
                get(action, 'payload.hiredFunds')
            );
            case actionTypes.BROKER_WIZARD_CONTRIBUTION_CHANGE_READCHECKED:
                partialSteps = updateStep2(state.get('steps'), action);
                return state.merge({
                    steps: partialSteps
                });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_DATEPICKER_ERROR:
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_DATE_VALUE:
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_PERIODICITY:
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_AMOUNT:
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_PREVIOUS_DATA:
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_SET_ACCOUNT_NUMBER:
            partialSteps = updateStep1(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_CONTRIBUTION_CLEAR_FETCHED_PRE: {
            return state.merge({
                isFetchedPre: false
            });
        }
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_REQUEST:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], true)
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_SUCCESS:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.INVESTMENT_FUND_DOCUMENT_STORAGE_ERROR:
            return state.setIn(['steps', SECOND_STEP, 'documentStorage'], false)
        case actionTypes.BROKER_EXTANT_COST_FETCH_FETCHING:
            return state.merge({
                fetchingDataExt: true
            });
        case actionTypes.BROKER_EXTANT_COST_FETCH_SUCCESS:
            return state.merge({
                fetchDataExt: action.payload,
                fetchingDataExt: false
            });
        case actionTypes.BROKER_EXTANT_COST_FETCH_FAILURE:
            return state.merge({
                fetchDataExt: [],
                fetchDataExtError: action.payload,
                fetchingDataExt: false
            })
        case actionTypes.BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_SUCCESS:
            return state.merge({
                fetchingDocumentsValidate: false,
                enableEconomicReport: action.payload.enableEconomicReport
            })
        case actionTypes.BROKER_CONTRIBUTION_INVESTMENT_FUND_VALIDATE_DOCUMENTS_FAILURE:
            return state.merge({
                fetchingDocumentsValidate: false
            })
        default:
            return state;
    }
};

module.exports = documents;
