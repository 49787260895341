// @ vendors
const React = require('react');
//@commons
const { FormattedText, formatText } = require('core/i18n').i18n;

//@helpers
const formValidationHelper = require('../utilities/formValidationHelper');
const { formatAmount } = require('utilities/currencyHelper');
const { findElementbyId } = require('routes/contractsView/utilities/contractStateHelper');
const { getDepositsPrintedDocument } = require('../utilities/contactCenter/depositsPrintedDocumentHelper');
const { isPrintOfficeMode } = require('utilities/contactCenter/printerHelper');
import { getFullName } from 'utilities/personalDataHelper';
import { getRenewalDepositCategory, getDepositProduct } from 'utilities/taggingDeposits';
import { recordLink } from 'utilities/tagging';

//@constants
const { 
    EURO_SYMBOL,
    API_CENTER,
    API_ENTERPRISE_NUMBER,
} = require('constants/index');
const {
    BUTTON_CLICK,
    CANCEL_ACTION_EVENT,
    CONTRACT_BUTTON,
    LINK_CLICK_ACTION_EVENT,
    NEXT_ACTION_EVENT,
    PREVIOUS_ACTION_EVENT,
    DEFERRAL_DEPOSIT_INPUT_TOOLTIP_ACTION_EVENT,
    DEFERRAL_DEPOSIT_INPUT_TOOLTIP_TAG_EVENT
  } = require('constants/tagging');
function depositrenewal_shouldInputSubLabelBuild() {
    return false;
}

function pollingNextButtonDisabled(contractWizard) {
    return !contractWizard.get('conditionDocumentConfirmation') && !__CONTACT_CENTER__ || (__CONTACT_CENTER__ && !contractWizard.getIn(['confirmationCheckboxes', 'areValid']));
}

function nextButtonDisabled(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    const depositAmount = cardDetails && findElementbyId(cardDetails, 'monto_traspasar');
    return !!(cardDetails && !findElementbyId(cardDetails, 'cta_seleccionada', 'description') ||
     contractWizard.get('intervenersIsFetching') || contractWizard.get('dataIsFetching') || !depositAmount ||
     contractWizard.getIn(['depositHireData', 'error']) || depositAmount > contractWizard.getIn(['impositionAmount', 'amount']));
}

function nextButtonDisabledStep1(contractWizard) {
    return contractWizard.get('renewalDepositAccountBlocked');
}

function getCustomPrintDocument(contractWizard, immProfile, immAccounts) {
    return getDepositsPrintedDocument(contractWizard, immProfile, immAccounts);
}

function validateStep1(card_details) {
    return {
        card_details: card_details,
        isValidStep: true
    };
}

function validateStep2(cardDetails, state) {
    const selectedAccount = findElementbyId(cardDetails, 'cta_seleccionada');
    let isValidAccount = selectedAccount !== '' && selectedAccount !== null;
    cardDetails = formValidationHelper.updateValue(
        cardDetails,
        'cta_seleccionada',
        !isValidAccount
    );

    if (isValidAccount) {
        let interveners = state.get('interveners');
        let intervenerTypeIsValid = false;
        if (interveners) {
            intervenerTypeIsValid = interveners
                .map(intervener => intervener.get('type') !== '')
                .toArray();
        }

        let validFields = [isValidAccount, ...intervenerTypeIsValid];

        isValidAccount = validFields.every(formValidationHelper.isValidElement);
        cardDetails = formValidationHelper.updateValue(
            cardDetails,
            'cta_seleccionada',
            !isValidAccount
        );
    }
    const isInputAmountNotZero = findElementbyId(cardDetails, 'monto_traspasar') !== 0;

    return {
        card_details: cardDetails,
        isValidStep: isValidAccount && isInputAmountNotZero
    };
}


function validateStep3(card_details, conditionDocumentConfirmation) {
    let validation;
    if (!__CONTACT_CENTER__) {
        validation = {
            card_details: card_details,
            isValidStep: conditionDocumentConfirmation,
        };
    } else {
        validation = {
            card_details: card_details,
            isValidStep: true,
        };
    }
    return validation;
}

function validateStep4(cardDetails, immConfirmationCheckboxes) {
    // We create a new checkboxList with the errorState values updated for each checkbox
    const updatedCheckboxList = immConfirmationCheckboxes
        .get('list')
        .map(item => item.set('errorState', item.get('isRequired') && !item.get('value')));

    // We return the updated confirmationCheckboxes object with the updated checkboxes and
    // isValidStep that says if this step of the form is considered valid or not to continue.
    return {
        card_details: cardDetails,
        isValidStep: immConfirmationCheckboxes.get('areValid'),
        confirmationCheckboxes: immConfirmationCheckboxes.set('list', updatedCheckboxList)
    };
}

function validateStep(step, state, isValid) {
    //If return true, the step is valid and go to the next. If is false , stay in the same step.
    const immCardDetails = state.get('card_details');
    const immConfirmationCheckboxes = state.get('confirmationCheckboxes');
    switch (step) {
        case 1:
            return validateStep1(immCardDetails, state.get('depositHireData'));

        case 2:
            return validateStep2(immCardDetails, state, isValid);

        case 3:
            return validateStep3(immCardDetails, state.get('conditionDocumentConfirmation'));
            
        case 4:
            return validateStep4(immCardDetails, immConfirmationCheckboxes);
            
    }
}

function depositrenewal_loadAmountInputAmounts(contractWizard, component) {
    if (!contractWizard.get('dataIsFetching')) {
        const depositHireData = contractWizard.get('depositHireData');
        component.importMax = contractWizard.getIn(['impositionAmount', 'amount']); // TODO: Take this value(Max) from the imposition amount and not from deposit amount
        component.importMin = depositHireData.get('importeMin');
        component.defaultValue = depositHireData.get('importeMin');
        component.value = component.value || depositHireData.get('importeMin');
        const chosenDepositMax = contractWizard.getIn(['impositionAmount', 'amount']);
        component.infoMessage = (
            <FormattedText 
                value={component.infoMessageText} 
                injectedStrings={[`${formatAmount(chosenDepositMax)} ${EURO_SYMBOL}`]} 
            />
        );
    }
    return component;
}

function dropdwonAccountShouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'installment', 'value')
    );
}

function shouldBuild(contractWizard) {
    const cardDetails = contractWizard.get('card_details');
    return (
        cardDetails &&
        findElementbyId(cardDetails, 'cta_seleccionada', 'description') &&
        !contractWizard.get('intervenersIsFetching') &&
        !contractWizard.get('dataIsFetching') &&
        !contractWizard.getIn(['depositHireData', 'error'])
    );
}

function signParams(contractWizard, immAccounts) {
    const immCardDetails = contractWizard.get('card_details');
    const selectedAccountId = immCardDetails
        ? findElementbyId(immCardDetails, 'cta_seleccionada')
        : null;
    let selectedAccount;

    if (immAccounts && selectedAccountId) {
        const immAccount = immAccounts.getIn(['byId', selectedAccountId]);
        selectedAccount =
            API_ENTERPRISE_NUMBER +
            API_CENTER +
            immAccount.get('product') +
            immAccount.get('contractNumber');
    }

    return {
        originAccount: selectedAccount
    };
}

function onChangeAmount(amount) {
    let ret = [];
    ret.push({
        target: 'monto_traspasar',
        value: amount
    });
    return ret;
}

function setSuccessStepPrefix() {
    let successStepPrefix = {};

    return successStepPrefix;
}


function setSuccessStepAdditionalInfo() {
    return null;
}

function getDepositElement(contractWizard, element) {
    const deposit = contractWizard.getIn(['mainDeposits', 'data']);
    return deposit ? deposit.get(element) : '';
}

function getEventCategory(contractWizard, step = false) {
    const visibleStep = contractWizard.get('visibleStep');
    const standard =  getDepositElement(contractWizard, 'standard');
    const subType = getDepositElement(contractWizard, 'subtype');
    const type = getDepositElement(contractWizard, 'type');
    return getRenewalDepositCategory(type, subType, standard, step ? visibleStep : undefined);
}


function step2DisclaimerCustomTaggingCategory(contractWizard) {
    return getEventCategory(contractWizard, true);
}

function customTagging(contractWizard) {
    const eventCategory = getEventCategory(contractWizard, true);
    const standard =  getDepositElement(contractWizard, 'standard');
    const subType = getDepositElement(contractWizard, 'subtype');
    const type = getDepositElement(contractWizard, 'type');
    return {
        events: {
            cancel: { eventCategory: eventCategory, eventName: LINK_CLICK_ACTION_EVENT, eventTag: CANCEL_ACTION_EVENT },
            finish: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: CONTRACT_BUTTON },
            next: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: NEXT_ACTION_EVENT },
            previous: { eventCategory: eventCategory, eventName: BUTTON_CLICK, eventTag: PREVIOUS_ACTION_EVENT },
        },
        steps: {
            nombrePagina: "depositos/vencimiento",
            detalleProceso: getEventCategory(contractWizard),
            nombreProducto: getDepositProduct(type, subType, standard),
            productName: getDepositProduct(type, subType, standard),
        },
        eventCategory: eventCategory,
    }
}

function handleInputAmountTooltipHover(contractWizard) {
    const eventCategory = getEventCategory(contractWizard, true);
    recordLink(eventCategory, DEFERRAL_DEPOSIT_INPUT_TOOLTIP_ACTION_EVENT, DEFERRAL_DEPOSIT_INPUT_TOOLTIP_TAG_EVENT);
}

function inputCategoryEvent(contractWizard) {
    return getEventCategory(contractWizard, true);
}

function customCategoryEvent(contractWizard) {
    return getEventCategory(contractWizard, true);
}

const getAccountNumber = (immAccounts, id) => {
    const account = immAccounts.getIn(['byId',id]);
    return account.get('iban');
}

function officePrintedDocumentParamsFn(contractWizard, immProfile, immAccounts, immDeposits) {
    const associatedAccount = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'cta_seleccionada');
    const associatedAccountId = associatedAccount && associatedAccount.get('value');
    const associatedAccountNumber = associatedAccountId && getAccountNumber(immAccounts, associatedAccountId);
    const productName = contractWizard.getIn(['deposit_product', 'context']) + "-" + contractWizard.getIn(['deposit_product', 'name']);
    const tin = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'tin').get('value');
    const tae = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'tae').get('value');
    const amount = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'monto_traspasar').get('value');
    const installmentInjectedStrings = contractWizard.get('card_details') && contractWizard.get('card_details').find(item => item.get('id') === 'installment').get('injectedStringValues');
    const installmentInjectedStringsArray =  installmentInjectedStrings && installmentInjectedStrings.toJS();
    const depositId = contractWizard.get('renewalOrExpiredDepositId');
    const deposit = immDeposits.getIn(['byId', depositId]);
    const expirationDate = deposit.getIn(['impositionList', 'byId']).first().get('expirationDate');
    const interveners = contractWizard.get('interveners');
    const currentIntervener = interveners.find(item => item.get('currentIntervener'));
    const interventionType = currentIntervener && currentIntervener.get('typeNumber');
    const filteredInterveners = interveners.filter(intervener => !intervener.get('currentIntervener')).slice(0, 4);
    const intervenersData = filteredInterveners.map(intervener => (
        {
            name: intervener.get('name'),
            identification: intervener.get('documentNumber'),
            interveneType: intervener.get('type')
        }
    ));
    const clientName = getFullName(immProfile, false);
    const clientDocument = immProfile.getIn(['documentoIdentificacion','codigodocumpersonacorp']);
    return {
        precontractualType: "SCHEDULE_DEPOSIT_RENEWAL_OFFICE",
        metadata: {
            nameSurname: clientName,
            documentNumber: clientDocument,
            checkInterventionType: interventionType,
            iban: associatedAccountNumber,
            interveners: intervenersData,
            productType: formatText(productName),
            tin: tin,
            tae: tae,
            amount: amount,
            term: formatText('contractsView-contractsViewDepositRenewalPrintInstallment', [installmentInjectedStringsArray]),
            documentDate: expirationDate
        }
    };
}

function hireParamsFn(contractWizard, immAccount, immDeposits) {
    const cardDetails = contractWizard.get('card_details');
    const depositProduct = contractWizard.get('deposit_product');
    const chosenDeposit = contractWizard.getIn(['mainDeposits', 'data']).toJS();
    const depositId = contractWizard.get('renewalOrExpiredDepositId');
    const deposit = immDeposits.getIn(['byId', depositId]);
    const importe = findElementbyId(cardDetails, 'monto_traspasar');
    const interveners = contractWizard.get('interveners');
    const intervenersData = interveners.map((intervener) => ({
        intervenerClientNumber: intervener.getIn(['identification', 'code']),
        intervenerInterventionType: intervener.get('typeNumber'),
    }));
    const impositions = ['0000001'].map(imposition => {
        const impositionData = deposit.getIn(['impositionList', 'byId', imposition]);
        const mappedData = {
            originalImpositionNumber: imposition,
            renewalAmount: importe,
            originalImpositionExpirationDate: impositionData.get('expirationDate')
        }
        return mappedData;
    });
    let originChannel;
    if (!__CONTACT_CENTER__) {
        originChannel = "WP";
    } else if (isPrintOfficeMode()) {
        originChannel = "OFI";
    } else {
        originChannel = "CC";
    }
    const holderNumber = interveners.find(intervener => intervener.get('typeNumber') === "01").getIn(['identification', 'code']);
    const requestingNumber = interveners.find(intervener => intervener.get('currentIntervener')).getIn(['identification', 'code']);
    return {
        accountTitularClient: holderNumber,
        accountContract: immAccount.get('contractNumber'),
        accountProduct: immAccount.get('product'),
        currency: deposit.get('currency'),
        depositCountry: deposit.get('country'),
        depositIdentify: contractWizard.get('fromRenewalOrTransferToken'),
        depositRenewalInterveners: intervenersData,
        requestingClient: requestingNumber,
        renewalDepositStandard: depositProduct.get('standard'),
        renewalDepositSubtype: depositProduct.get('subtype'),
        renewalDepositType: depositProduct.get('type'),
        renewalDepositTermTimeUnit: 'M',
        renewalDepositTermValue: chosenDeposit.depositConfig.months,
        renewalTae: chosenDeposit.depositConfig.tae,
        renewalTin: chosenDeposit.depositConfig.tin,
        originalImpositions: impositions,
        originChannel: originChannel,
        originalDepositStandard: deposit.get('depositStandard'),
        originalDepositSubtype: deposit.get('depositSubType'),
        originalDepositType: deposit.get('depositType'),
        originalDepositContract: deposit.getIn(['contract', 'contractNumber']),
    };
}

function loadTermsAndConditionsLegalWindow(setTermsAndCondsLegalWindow) {
    setTermsAndCondsLegalWindow();
}

module.exports = {
    depositrenewal_customTagging: customTagging,
    depositrenewal_customCategoryEvent: customCategoryEvent,
    depositrenewal_dropdwonAccountShouldBuild: dropdwonAccountShouldBuild,
    depositrenewal_handleInputAmountTooltipHover: handleInputAmountTooltipHover,
    depositrenewal_inputCategoryEvent: inputCategoryEvent,
    depositrenewal_shouldInputSubLabelBuild,
    depositrenewal_validateStep: validateStep,
    depositrenewal_getCustomPrintDocument: getCustomPrintDocument,
    depositrenewal_nextButtonDisabled: nextButtonDisabled,
    depositrenewal_nextButtonDisabledStep1: nextButtonDisabledStep1,
    depositrenewal_pollingNextButtonDisabled: pollingNextButtonDisabled,
    depositrenewal_loadAmountInputAmounts,
    depositrenewal_shouldBuild: shouldBuild,
    depositrenewal_step2DisclaimerCustomTaggingCategory: step2DisclaimerCustomTaggingCategory,
    depositrenewal_loan_SignParams: signParams,
    depositrenewal_onChangeAmount: onChangeAmount,
    depositrenewal_setSuccessStepAdditionalInfo: setSuccessStepAdditionalInfo,
    depositrenewal_setSuccessStepPrefix: setSuccessStepPrefix,
    depositrenewal_hireParamsFn: hireParamsFn,
    depositrenewal_loadTermsAndConditionsLegalWindow: loadTermsAndConditionsLegalWindow,
    depositrenewal_officePrintedDocumentParamsFn: officePrintedDocumentParamsFn,
};