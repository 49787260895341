// @ vendors
const Immutable = require('immutable');
const trim = require('lodash/string/trim');
const get = require('lodash/object/get');
const find = require('lodash/collection/find');
const moment = require('moment');
// @ constants
const actionTypes = require('constants/actionTypes');
// @ reducers
const investmentFundsStep1 = require('./investmentFunds/investmentFundsStep1');
const investmentFundsStep2 = require('./investmentFunds/investmentFundsStep2');
const investmentFundsPeriodicalContributionStep = require('./investmentFunds/investmentFundsPeriodicalContributionStep');
// @ utilities
const { generateAccountId } = require('utilities/APIParsingHelper');
// @core
const { getStore } = require('core/storeHelper');

function setInitialState(funds = []) {
    return Immutable.Map().merge({
        steps: [
            investmentFundsStep1(undefined, { type: null }),
            investmentFundsPeriodicalContributionStep(undefined, { type: null }),
            investmentFundsStep2(undefined, { type: null }),

        ],
        list: Immutable.List(funds),
        investmentFunds: Immutable.fromJS({}),
        errorReceived: '',
        isFetching: false,
        isFetchingPre: false,
        isFetchedPre: false,
        inProgress: false,
        willLeave: false,
        visibleStep: 1,
        submitSuccessful: false,
        notFound: false,
        holding: {
            numberOfHoldings: 0,
            currency: '',
            amount: 0
        },
        fetchingDataExt: false,
        fetchDataExt: [],
        fetchDataExtSuccess: false,
        fetchDataExtError: false,
        enableEconomicReport: true,
        skipContribution: false,
    });
}

function updateStep1(steps, action) {
    return steps.update(0, step => investmentFundsStep1(step, action));
}

function updateStep2(steps, action) {
    return steps.update(1, step => investmentFundsPeriodicalContributionStep(step, action));
}

function updateStep3(steps, action) {
    return steps.update(2, step => investmentFundsStep2(step, action));
}

const documents = (state = setInitialState(), action) => {
    let visibleStep;
    let partialSteps;
    switch (action.type) {
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SKIP_CONTRIBUTION_STEP:
            visibleStep = 3;
            return state.merge({
                skipContribution: action.payload,
                visibleStep
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_DATE:
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_PERIODICITY:
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_AMOUNT:
            partialSteps = updateStep2(state.get('steps'), action);
            return state.merge({
                steps: partialSteps
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_SET_VISIBLE_STEP:
            return state.merge({
                visibleStep: action.payload.step
            });
        case actionTypes.BROKER_WIZARD_HIRE_INVESTMENT_CONTRIBUTION_CHANGE_VALIDATE_STEP:
            visibleStep = 0;
            switch (state.get('visibleStep')) {
                case 1:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 2
                    break;
                case 2:
                    partialSteps = updateStep2(state.get('steps'), action);
                    visibleStep = 3;
                    break;
                case 3:
                    partialSteps = updateStep3(state.get('steps'), action);
                    visibleStep = 3;
                    break;
                default:
                    partialSteps = updateStep1(state.get('steps'), action);
                    visibleStep = 1;
            }
            return state.merge({
                steps: partialSteps,
                visibleStep,
                skipContribution: false
            });
        default:
            return state;
    }
};

module.exports = documents;
